/* eslint-disable react/prop-types */
import React, { memo } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import Router from 'next/router';

const FourthLevelMobile = ({ items, closeMenu }) => {
  Router.onRouteChangeStart = () => {
    closeMenu();
  };
  return (
    <div className="category_navigation__fourth">
      <div className="fourth_menu">
        <div className="wrapper fourth_menu_header">
          <Link href={items.primary.link}>
            <div className='second_menu_wrapper'>
              <div className='second_menu_image'>
                <img 
                  src={items.primary.image.url}
                />
              </div>
              <div className='second_menu_titles'>
                <div className='second_menu_title'>{items.primary.title}</div>
                <div className='second_menu_subtitle'>{items.primary.subtitle}</div>
              </div>
            </div>
          </Link>
          {/* {items?.primary?.subtitle && (
            <div className="fourth_menu_header_title">
              {items?.primary?.subtitle}
            </div>
          )} */}
        </div>
      </div>
      <style jsx>
        {`
          @font-face {
            font-family: 'Roboto-Bold';
            src: url('/fonts/Roboto-Bold.ttf') format('truetype');
            font-weight: bold;
            font-style: normal;
          }
          .category_navigation__fourth {
            background-color: #101010;
          }
          .second_menu_wrapper {
            margin: 0;
            padding: 0;
            width: 100%;
            display: flex;
            align-items: center;
            cursor: pointer;
          }
          .second_menu_image {
            margin-left: 15px;
            width: 100px;
            height: 80px;
          }
          .second_menu_image img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
          .second_menu_titles {
            margin-left: 30px;
          }
          .second_menu_title {
            font-family: 'Roboto-Bold';
            font-size: 16px;
            line-height: 19px;
          }
          .second_menu_subtitle {
            font-family: 'Roboto-Bold';
            font-size: 10px;
            line-height: 12px;
            color: #989898;
            margin-top: 6px;
          }
          .link-button {
            color: #fd0300;
            font-size: 13px;
            line-height: 20px;
            padding-right: 7px;
            cursor: pointer;
          }
          .link-group {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
          .fourth_menu {
            width: 100%;
            padding: 24px 0;
            border-bottom: 1px solid rgba(228, 228, 228, 0.2);
          }
          .fourth_menu_header {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          .fourth_menu_item_body {
            display: flex;
            gap: 24px;
            overflow-x: scroll;
            -ms-overflow-style: none; /* for Internet Explorer, Edge */
            scrollbar-width: none; /* for Firefox */
          }
          .fourth_menu_item_body::-webkit-scrollbar {
            display: none; /* for Chrome, Safari, and Opera */
          }
        `}
      </style>
    </div>
  );
};

export default memo(FourthLevelMobile);
