/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unused-prop-types */
import React, { memo } from 'react';
import styles from './styles';
import ProductList from '../ProductList';

const ProductLists = ({
    thirdMenuItems,
    handleClickNav,
    parent,
}) => {

  return (
    <>
        <div className='third_menu'>
            <div 
                className='third_menu_container'
            >
                {thirdMenuItems.map((item) => (
                    <ProductList
                        thirdMenuItem={item}
                        handleClickNav={handleClickNav}
                        parent={parent}
                    />
                ))}
            </div>
        </div>

        <style jsx>{styles}</style>
    </>
  );
};

export default memo(ProductLists);
