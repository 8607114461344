/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unused-prop-types */
import React, { memo, useState } from 'react';
import styles from './styles';
import Link from 'next/link';

const ProductLinks = ({
    thirdMenuItems,
    handleClickNav,
}) => {
    const [isHovered, setIsHovered] = useState(null);
  return (
    <>
        <div className='third_menu'>
            <div 
                className='third_menu_container'
            >
                {thirdMenuItems[0].items.map((item, index) => (
                    <Link href={item?.link}>
                        <a href={item?.link}>
                            <div 
                                className='third_menu_item'
                                onClick={() => handleClickNav()}
                                onMouseOver={() => {
                                    setIsHovered(index);
                                }}
                                onMouseLeave={() => {
                                    setIsHovered(null);
                                }}
                            >
                                <div className='third_menu_image'>
                                    <img
                                        src={item.model_image?.url}
                                    />
                                </div>
                                <div className={`third_menu_title ${isHovered === index ? 'third_menu_title_hovered' : ''}`}>
                                    {item.title}
                                </div>
                                <div className='third_menu_subtitle'>
                                    {item.subtitle}
                                </div>
                            </div>
                        </a>
                    </Link>
                ))}
            </div>
        </div>

      <style jsx>{styles}</style>
    </>
  );
};

export default memo(ProductLinks);
