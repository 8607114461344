import React from "react";

function CarouselProgressPagination({ totalDots, currentDot }) {
  const progressWidth = () => {
    if (totalDots === currentDot) {
      return 100;
    }
    const res = Math.ceil(100 / totalDots - 1) * currentDot;
    return res;
  };

  return (
    <>
      <div className="container">
        <div className={`carousel_pagination`}>
          <div className="progress"></div>
        </div>
      </div>
      <style jsx>{`
        .container {
          display: flex;
          justify-content: center;
          margin-top: 56px;
        }
        .carousel_pagination {
          height: 4px;
          background: #242424;
          width: 192px;
          border-radius: 2px;
          overflow: hidden;
          position: relative;
        }
        .progress {
          position: absolute;
          top: 0;
          bottom: 0;
          background: #950810;
          width: ${progressWidth() || 0}%;
          transition: all 0.5s ease-in-out;
        }
      `}</style>
    </>
  );
}
export default CarouselProgressPagination;
