import React from "react";

function AwardsList({ block }) {
  const awards = block?.items;
  return (
    <>
      <div className="container">
        <div className="awards_list">
          {awards?.map((item, idx) => {
            const image = item.award_image;
            return <img draggable="false" key={idx} src={image.url} alt={image.alt} />;
          })}
        </div>
      </div>
      <style jsx>{`
        .container {
          padding: 76px 48px;
          margin: 0 auto;
          max-width: 1440px;
        }
        .awards_list {
          display: flex;
          gap: 61px;
          justify-content: center;
          flex-wrap: wrap;
        }
        @media (width<=640px) {
          .container {
            padding: 40px 16px;
          }
        }
      `}</style>
    </>
  );
}

export default AwardsList;
