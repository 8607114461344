export const kSocials = [
  {
    href: 'https://www.facebook.com/aftershockpcau',
    iconSrc: 'https://cdn.shopify.com/s/files/1/0522/3320/7988/files/facebook-16px.png?v=1713886046',
    alt: 'facebook icon',
  },
  {
    href: 'https://www.instagram.com/aftershockpcau/',
    iconSrc: 'https://cdn.shopify.com/s/files/1/0522/3320/7988/files/instagram-16px.png?v=1713886046',
    alt: 'instagram icon',
  },
  {
    href: 'https://twitter.com/AftershockpcAU',
    iconSrc: 'https://cdn.shopify.com/s/files/1/0522/3320/7988/files/twitter-16px.png?v=1713886046',
    alt: 'twiter icon',
  },
  {
    href: 'https://www.youtube.com/user/aftershocknotebooks',
    iconSrc: 'https://cdn.shopify.com/s/files/1/0522/3320/7988/files/youtube-16px.png?v=1713885854',
    alt: 'youtube icon',
  },
  {
    href: 'https://www.tiktok.com/@aftershockpcau',
    iconSrc: 'https://cdn.shopify.com/s/files/1/0522/3320/7988/files/tik-removebg-preview.png?v=1716797506',
    alt: 'tik-tok icon',
  },
]

export const kRegions = [
  {
    code: 'AU',
    country: 'Australia',
    iconSrc: 'https://cdn.shopify.com/s/files/1/0522/3320/7988/files/flag-australia.png?v=1713807308',
    link: 'https://www.aftershockpc.com.au/',
  },
  {
    code: 'SG',
    country: 'Singapore',
    iconSrc: 'https://cdn.shopify.com/s/files/1/0522/3320/7988/files/flag-singapore.png?v=1713807333',
    link: 'https://www.aftershockpc.com/',
  }

]