import { ApolloProvider } from '@apollo/client';
import Layout from 'containers/Layout';
import client from 'lib/shopify-storefront/client';
import Router, { useRouter } from 'next/router';
import NProgress from 'nprogress';
import StorefrontProvider from 'providers/storefront';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import '../styles/globals.css';
import '../styles/uploadcare.css';
import Head from 'next/head';

NProgress.configure({ showSpinner: false });

Router.onRouteChangeStart = () => {
	NProgress.start();
};

Router.onRouteChangeComplete = () => {
	NProgress.done();
};

Router.onRouteChangeError = () => {
	NProgress.done();
};

// Note: Password Protect is temporary. Will be removed before going live.
const MyApp = (props) => {
	const { Component, pageProps } = props;
	const { global, preview } = pageProps || {};

	const router = useRouter();
	const canonicalUrl = `https://www.aftershockpc.com.au${router.asPath === '/' ? '' : router.asPath}`.split('?')[0];

	useEffect(() => {
		Router.pathname !== '/pc-models/[uid]' &&
			Router.pathname !== '/products/[...handle]' &&
			document &&
			document.body.classList.remove(
				'customize-page-product',
				'product-page-chat',
				'product-page-chat',
			);
	});

	return (
		<ApolloProvider client={client}>
			<StorefrontProvider globalProps={global}>
				<Layout global={global} preview={preview}>
					<Head>
						<link rel="canonical" href={canonicalUrl} />
					</Head>
					<Component {...pageProps} global={global} />
				</Layout>
			</StorefrontProvider>
		</ApolloProvider>
	);
};

export default MyApp;
