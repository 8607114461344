/* global window */
import { StorefrontContext } from 'providers/storefront';
import React, { useContext, useRef, useEffect, useState } from 'react';
import Router from 'next/router';
import styles from './styles';
import CartContent from './CartContent';
import CartEmpty from './CartEmpty';
import Image from 'next/image';

const Cart = () => {
  const { isCartOpen, setIsCartOpen, checkout } = useContext(StorefrontContext);
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  let feeItemsNum = useRef(0);

  useEffect(() => {
    let lineItems = [...document.getElementsByClassName('Line-item')];
    let feeProduct = lineItems.find((item) =>
      item.innerText.includes('Payment Method Fee'),
    );
    // let feeProductCount = document.getElementsByClassName(`${feeProduct.className}`)
    // console.log(lineItems);
    if (feeProduct) {
      // must uncomment below in live
      // feeProduct.style.display ='none'
      // maybe add this later to correctly show total item count in cart
      // let feeQuantity = feeProduct.getElementsByClassName('Line-item__quantity')[0].innerText
      // feeItemsNum.current = feeQuantity
    }
  });

  Router.onRouteChangeStart = () => {
    setIsCartOpen(false);
  };

  const handleCartClose = () => {
    setIsCartOpen(false);
  };

  const lineItems = checkout?.lineItems?.edges || [];

  const itemsTotal =
    lineItems.map((item) => item.node.quantity).reduce((x, y) => x + y, 0) -
    feeItemsNum.current;

  const useOutsideClick = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          handleCartClose();
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };

  useEffect(() => {
    var element = document.getElementById('cart-container');
    var checkout = document.getElementById('sticky-footer');
    if (isCartOpen && checkout != null) {
      if (
        element.scrollHeight > element.clientHeight ||
        element.scrollWidth > element.clientWidth
      ) {
        checkout.setAttribute(
          'style',
          'position: fixed; width: calc(710px / 2); bottom: 0',
        );
      } else {
        checkout.setAttribute('style', 'position: inherit; width: 100%');
      }
    }
  });

  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef);

  const scrolling = (e) => {
    var sh = e.target.scrollHeight;
    var st = e.target.scrollTop;
    var ht = e.target.offsetHeight;
    var checkout = document.getElementById('sticky-footer');
    var btn = document.getElementById('checkout-btn');
    if (checkout != null) {
      if (ht == 0) {
        return true;
      }
      if (Math.round(st) - 1 == sh - ht || Math.round(st) == sh - ht) {
        checkout.setAttribute(
          'style',
          'position: fixed; width: calc(710px / 2); bottom: 15px;',
        );
        btn.setAttribute('style', 'border-bottom-left-radius: 3px !important');
        return true;
      } else {
        checkout.setAttribute(
          'style',
          'position: fixed; width: calc(710px / 2); bottom: 0;',
        );
        btn.setAttribute('style', 'border-bottom-left-radius: 0px !important');
        return false;
      }
    }
  };

  return (
    <>
      <div
        className={`Cart ${isCartOpen ? 'Cart--open' : ''}`}
        ref={wrapperRef}
        onScroll={(e) => scrolling(e)}
        id="cart-container"
      >
        <header
          className="Cart__header"
          style={{ height: '10%', zIndex: 1, backgroundColor: '#131313' }}
        >
          <div className="cart-header-container">
            <h2 className="cart_title">
              MY CART
              {lineItems.length > 0 && (
                <span className="line_items_quantity">
                  ({itemsTotal} &nbsp;items)
                </span>
              )}
            </h2>
            <div 
              className="Cart__close" 
              onClick={handleCartClose}
              onMouseEnter={handleMouseEnter}      
              onMouseLeave={handleMouseLeave}>
                <Image 
                  src={isHovered ? 
                    "https://cdn.shopify.com/s/files/1/0522/3320/7988/files/icon-close-hover.png?v=1713886520" 
                    : "https://cdn.shopify.com/s/files/1/0522/3320/7988/files/icon-close.png?v=1714038756"}
                  width={20}
                  height={20}
                />
            </div>
          </div>
        </header>
        {lineItems.length > 0 && <CartContent />}

        {lineItems.length === 0 && <CartEmpty />}
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

export default Cart;
