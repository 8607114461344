import React, { useContext, useState } from 'react';
import SidePanelButtonLink from './SidePanelButtonLink';
import SidePanelCheckboxInput from './SidePanelCheckboxInput';
import SidePanelFormButton from './SidePanelFormButton';
import SidePanelTextField from './SidePanelTextField';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import FormSubmitErrors from 'components/Form/FormSubmitErrors';
import SidePanelLoginForm from './SidePanelLoginForm';
import { StorefrontContext } from 'providers/storefront';

function SidePanelCreateProfileForm({
  onHandleLogin,
  onCreateProfile: onSuccessfulCreateProfile,
}) {
  const { setIsLoggedIn } = useContext(StorefrontContext);

  const [isSubscribed, setIsSubscribed] = useState(false);
  const [activationUrl, setActivationUrl] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isRegistered, setIsRegistered] = useState(false);

  const SignupSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
    firstName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    lastName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    password: Yup.string()
      .min(8, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    confirmPassword: Yup.string()
      .required('Required')
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  });

  const onCreateProfile = async (values) => {
    const { email, firstName, lastName, password } = values;

    try {
      const response = await fetch('/api/shopify/customer/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, firstName, lastName, password }),
      });

      const data = await response.json();

      if (response.ok) {
        if (data.activationUrl) {
          setActivationUrl(data.activationUrl);
        } else {
          // onSuccessfulCreateProfile();
          setIsRegistered(true);
          alert('You are registered!')
        }
      } else {
        setIsRegistered(false);
        setErrorMessage(data.message);
      }
    } catch (error) {
      console.error('Error creating profile:', error);
      setErrorMessage('Something went wrong. Please try again.');
    }
  };

  return (
    <>
      <div>
        {isRegistered ? (
          <>
            <SidePanelLoginForm
              setIsLoggedIn={setIsLoggedIn}
              onHandleLogin={() => onSuccessfulCreateProfile()}
            />
          </>
        ) : (
          <Formik
            initialValues={{
              email: '',
              firstName: '',
              lastName: '',
              password: '',
              confirmPassword: '',
            }}
            validationSchema={SignupSchema}
            onSubmit={(values) => {
              const { firstName, lastName, email, password } = values;
              onCreateProfile({ firstName, lastName, email, password });
            }}
          >
            {({ errors, touched }) => (
              <Form>
                <p className="create_profile_text">
                  Just a few more details and you’re all set up
                </p>
                {errorMessage && (
                  <FormSubmitErrors errors={[{ message: errorMessage }]} />
                )}
                {activationUrl && (
                  <div className="activation-wrapper">
                    <p>
                      User exists but is not activated. Please click the link
                      below to activate your account:
                    </p>
                    <a
                      href={activationUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Activate Account
                    </a>
                  </div>
                )}
                <div className="inputs">
                  <Field
                    name="email"
                    component={SidePanelTextField}
                    placeholder="Email"
                  />
                  <Field
                    name="firstName"
                    component={SidePanelTextField}
                    placeholder="First Name"
                  />
                  <Field
                    name="lastName"
                    component={SidePanelTextField}
                    placeholder="Last Name"
                  />
                  <Field
                    name="password"
                    component={SidePanelTextField}
                    placeholder="Password"
                    type="password"
                  />
                  <Field
                    name="confirmPassword"
                    component={SidePanelTextField}
                    placeholder="Confirm Password"
                    type="password"
                  />
                </div>
                <div className="checkbox_container">
                  <SidePanelCheckboxInput
                    label={'Keep me up to date with news and offers'}
                    value={isSubscribed}
                    onChange={(val) => setIsSubscribed(val)}
                  />
                </div>
                <div className="button_container">
                  <SidePanelFormButton text="Create Profile" type="submit" />
                </div>
                <SidePanelButtonLink
                  text={'Already have an account? Login here'}
                  onClick={onHandleLogin}
                />
              </Form>
            )}
          </Formik>
        )}
      </div>

      <style jsx>{`
        .checkbox_container {
          margin-top: 26px;
        }
        .inputs {
          margin-top: 15px;
          display: grid;
          gap: 16px;
        }
        .create_profile_text {
          padding: 0;
          margin: 0;
          font-family: 'Roboto-Medium';
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
        }
        .button_container {
          margin-top: 26px;
        }
        .activation-wrapper {
          display: flex;
          flex-direction: column;
          justity-content: center;
          align-items: center;
          max-width: 100%;
          margin-bottom: 10px;
          border: 1px solid rgb(255, 93, 93);
          background-color: rgba(255, 93, 93, 0.16);
          margin-top: 10px;
          border-radius: 4px;
        }
        .activation-wrapper > p {
          font-size: 0.9em;
          padding: 0px 16px;
        }
        .activation-wrapper > a {
          width: 320px;
          height: 48px;
          font-family: 'Roboto-Medium';
          font-size: 13px;
          font-weight: 600;
          line-height: 24px;
          background: #950810;
          border-radius: 4px;
          border: none;
          color: #e4e4e4;
          text-transform: uppercase;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      `}</style>
    </>
  );
}

export default SidePanelCreateProfileForm;
