import css from 'styled-jsx/css';

export default css`
  .main-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .tech-yard-text-section {
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    padding: 10px 25px 10px 25px;
  }
  .tech-yard-section {
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
  }
  .tech-yard-title {
    margin-bottom: 0px;
    font-size: 14px;
    font-weight: bold;
  }
  .tech-yard-description {
    margin-top: 0px;
    font-size: 14px;
    color: rgb(150, 150, 150);
  }
  .tech-yard-visit {
    font-size: 12px;
    color: rgb(255, 0, 0);
    font-weight: 600;
  }
  .component-title {
    font-size: 14px;
    font-weight: bold;
  }
  .view-all {
    margin: 0px;
    color: #ff0000;
    font-size: 12px;
    font-weight: 600;
  }
  .component-section {
    width: 100%;
    margin-top: 25px;
  }
  .component-header-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 25px;
    padding-right: 25px;
  }
  .component-box-section {
    padding-left: 25px;
    width: 100%;
    min-height: 250px;
  }
  .component-box {
    width: 160px;
    height: 58px;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: row;
    float: left;
    cursor: pointer;
  }
  .component-box-title {
    font-size: 12px;
    margin: 0;
    padding-left: 5px;
  }
`;