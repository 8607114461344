import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import CarouselProgressPagination from './CarouselProgressPagination';
import Image from 'next/image';

function ProductsByCategoryCarousel({ block }) {
  const [typeIndex, setTypeIndex] = useState(0);
  const [filteredProducts, setFilteredProducts] = useState(null);
  const [currentDot, setCurrentDot] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [totalSlidesShowing, setTotalSlidesShowing] = useState(1);
  const slidesShowNumber = 5;
  const title = block?.primary?.slice_label;
  const items = block?.items;
  const distinct = (value, index, self) => {
    return self.indexOf(value) === index;
  };
  const getAllCategories = () => {
    const categories = items?.map((item) => item.category);
    const distinctCategories = categories?.filter(distinct);
    return distinctCategories;
  };
  const populateCarousel = (productItems) => {
    let postArray = [];
    if (productItems?.length <= slidesShowNumber) {
      for (let i = 0; i <= slidesShowNumber; i++) {
        postArray.push(...productItems);
      }
      postArray = postArray.slice(0, slidesShowNumber * 2);
    } else {
      postArray = productItems;
    }
    return postArray;
  };
  const getFilteredByCategory = (category) => {
    const filtered = items?.filter((product) => product.category === category);
    const completedCarouselItems =
      totalSlidesShowing === 1 ? filtered : populateCarousel(filtered);
    setFilteredProducts(completedCarouselItems);
  };
  const onBeforeChange = (currentSlide, nextSlide) => {
    const width = window.innerWidth;
    if (width > 428) {
      const lastPageSlide = pageCount * totalSlidesShowing - totalSlidesShowing;
      if (nextSlide === 0) {
        setCurrentDot(1);
        return;
      }
      if (nextSlide === lastPageSlide) {
        setCurrentDot(pageCount);
        return;
      }
      if (nextSlide === 5) {
        setCurrentDot(2);
        return;
      }
    } else {
      setCurrentDot(nextSlide + 1);
    }
  };

  const categories = getAllCategories();
  useEffect(() => {
    if (categories) {
      getFilteredByCategory(categories[0]);
    }
  }, [totalSlidesShowing]);

  useEffect(() => {
    const totalDots = Math.ceil(filteredProducts?.length / slidesShowNumber);
    const slidesShowing = document.querySelectorAll(
      '#system_carousel  .slick-active',
    )?.length;
    setPageCount(slidesShowing === 1 ? filteredProducts?.length : totalDots);
    setTotalSlidesShowing(slidesShowing);
  }, [categories]);

  const [slideIndex, setSlideIndex] = useState(0);

  const settings = {
    beforeChange: (current, next) => setSlideIndex(next),
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: slidesShowNumber,
    slidesToScroll: slidesShowNumber,
    variableWidth: true,
    nextArrow: (
      <CustomCarouselArrowNext
        src="https://cdn.shopify.com/s/files/1/0522/3320/7988/files/arrow-right-next.png?v=1713800470"
        alt="arrow-right"
      />
    ),
    prevArrow: (
      <CustomCarouselArrowPrev
        src="https://cdn.shopify.com/s/files/1/0522/3320/7988/files/arrow-left-prev.png?v=1713800296"
        alt="arrow-left"
      />
    ),
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: slidesShowNumber,
          slidesToScroll: slidesShowNumber,
          infinite: 3,
          dots: true,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: 3,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: 3,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          className: 'center',
          arrows: false,
        },
      },
    ],
  };

  const getMaxValue = (data) => {
    let max = 0;
    if (data != null) {
      return (max =
        (data.length / 5) * 5 - (5 - ((data.length / 5) * 5 - data.length)));
    } else {
      return max;
    }
  };

  return (
    <>
      <div className="container">
        <div className="title">{title}</div>
        <div className="options">
          {categories?.map((item, idx) => (
            <SystemTypeButton
              key={idx}
              text={item}
              isActive={typeIndex === idx}
              onClick={() => {
                setTypeIndex(idx);
                getFilteredByCategory(item);
              }}
            />
          ))}
        </div>
        <div id="system_carousel" className="carousel">
          <Slider {...settings}>
            {filteredProducts?.map((item, idx) => {
              const result = {
                title: item.card_title,
                description: item.card_description,
                imageURL: item.thumbnail.url,
                slug: item.slug,
              };
              return <CarouselItem item={result} key={idx} />;
            })}
          </Slider>
          <div className="range-field-container">
            <input
              className="range-field"
              defaultValue={slideIndex}
              type="range"
              min={0}
              max={getMaxValue(filteredProducts)}
            />
          </div>
          {/* <CarouselProgressPagination
            currentDot={currentDot}
            totalDots={pageCount}
          /> */}
        </div>
      </div>
      <style jsx>{`
        .container {
          max-width: 1440px;
          margin: 0 auto;
          padding: 40px 0;
        }
        .carousel {
          position: relative;
          margin: 0 auto;
          padding: 56px 48px;
          max-width: 1440px;
          width:100%:
        }
        .options {
          display: flex;
          justify-content: center;
          gap: 24px;
          margin-top: 24px;
        }
        .title {
          font-family: "big_noodle_titling";
          font-size: 39px;
          font-weight: 400;
          line-height: 48px;
          letter-spacing: 0em;
          text-align: center;
          padding: 0 16px;
        }
				@media (max-width: 640px) {
          .carousel {
            padding: 56px 16px;
          }
          .title{
            font-size:26px;
          }
        }
        .range-field-container {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 3%;
        }
      `}</style>
    </>
  );
}

function CarouselItem({ item }) {
  return (
    <>
      <div
        className="carousel_item"
        onClick={() => (window.location.href = item.slug)}
      >
		<div className="img">
		<Image draggable={false}  src={item?.imageURL} alt={item?.title} width="192px" height="192px" loading="lazy" />
		</div>
        <a href={item.slug}>
          <div className="title">{item.title}</div>
        </a>
        <div className="description">{item.description}</div>
      </div>
      <style jsx>{`
        .img {
          width: 192px;
          height: 192px;
          // background: url('${item.imageURL}');
          // background-size: cover;
          // background-position: center;
          // background-repeat: no-repeat;
          border-radius: 4px;
        }
        .title {
          font-family: 'Roboto-Medium';
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: center;
          color: #e4e4e4;
          margin-top: 16px;
          text-transform: uppercase;
        }
        .description {
          font-family: 'Roboto-Medium';
          font-size: 13px;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: 0em;
          text-align: center;
          color: #949494;
          margin-top: 8px;
        }
        .carousel_item {
          cursor: pointer;
        }
        @media (width<=480px) {
          .carousel_item {
            padding: 0 16px;
          }
        }
      `}</style>
    </>
  );
}

function SystemTypeButton({ text, isActive, onClick }) {
  return (
    <>
      <button className="system_type_button" onClick={onClick}>
        {text}
      </button>
      <style jsx>{`
        .system_type_button {
          color: #e4e4e4;
          font-family: 'Roboto-Medium';
          font-size: 13px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
          border: none;
          border-radius: 2px;
          background: ${isActive ? '#950810' : 'none'};
          padding: 0px 8px;
        }
      `}</style>
    </>
  );
}

function CustomCarouselArrowNext({ src, alt, onClick }) {
  return (
    <>
      <button className="button" onClick={onClick}>
        <img draggable="false" src={src} alt={alt} />
      </button>
      <style jsx>{`
        .button {
          position: absolute;
          top: 80px;
          right: 0;
          background: none;
          border: none;
          padding: 0;
          margin: 0;
          line-height: 0;
          z-index: 5;
        }
      `}</style>
    </>
  );
}
function CustomCarouselArrowPrev({ src, alt, onClick }) {
  return (
    <>
      <button className="button" onClick={onClick}>
        <img draggable="false" src={src} alt={alt} />
      </button>
      <style jsx>{`
        .button {
          position: absolute;
          top: 80px;
          left: 0;
          background: none;
          border: none;
          padding: 0;
          margin: 0;
          line-height: 0;
          z-index: 5;
        }
      `}</style>
    </>
  );
}

export default ProductsByCategoryCarousel;
