import { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { StorefrontContext } from 'providers/storefront';
import { checkoutById, CHECKOUT_QUERY } from 'helpers/graphql/checkout';
import { removeCheckoutId, setCheckoutId } from 'helpers/cookies';

const CartCounter = ({ checkoutId }) => {
  const [totalQuantity, setTotalQuantity] = useState(0);
  const { setCheckout } = useContext(StorefrontContext);
  const { data: checkoutData } = useQuery(CHECKOUT_QUERY, {
    variables: checkoutById(checkoutId),
  });

  useEffect(() => {
    if (
      (checkoutData && checkoutData?.node && checkoutData?.node?.completedAt) ||
      (checkoutData && !checkoutData?.node) ||
      !checkoutData
    ) {
      removeCheckoutId();
      setCheckout({});
      setTotalQuantity(0);
    } else {
      setTotalQuantity(
        checkoutData?.node.lineItems.edges
          .map((item) => item.node.quantity)
          .reduce((x, y) => x + y, 0),
      );
      setCheckout(checkoutData.node);
      setCheckoutId(checkoutData.node.id);
    }
  }, [checkoutData]);
  if (!totalQuantity) return <></>;
  return (
    <>
      <div className="cart_badge">{totalQuantity}</div>
      <style jsx>
        {`
          .cart_badge {
            font-size: 12px;
            line-height: 8px;
            position: absolute;
            top: -25%;
            right: -25%;
            width: 16px;
            height: 16px;
            background: #950810;
            color: #e4e4e4;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
          }
        `}
      </style>
    </>
  );
};

CartCounter.propTypes = {
  checkoutId: PropTypes.string.isRequired,
};

export default CartCounter;
