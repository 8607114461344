import Image from 'next/image';
import PropTypes from 'prop-types';
import cn from 'classnames';
import breakPoints from 'helpers/Styles';

const PcModelDelayAlert = ({ checkout, type }) => (
  <>
    <div
      className={cn('alert-box', {
        checkout,
        component: !checkout,
        delayed: type === 'delayed',
        removed: type === 'removed',
      })}
    >
      <div className={cn({ 'delay-icon': checkout })}>
        <Image draggable={false} 
          src={
            type === 'delayed'
              ? 'https://cdn.shopify.com/s/files/1/0522/3320/7988/files/icon-exclamation-blue.svg?v=1713944876'
              : 'https://cdn.shopify.com/s/files/1/0522/3320/7988/files/icon-exclamation-yellow.svg?v=1713945250'
          }
          alt="Delayed"
          height={checkout ? 40 : 24}
          width={checkout ? 40 : 24}
        />
      </div>
      {type === 'delayed' && checkout && (
        <div>
          There is a delay on incoming stock on one or more items in your
          build/s. By checking out you acknowledge and accept a delay in the
          fulfilment of your order.
        </div>
      )}
      {type === 'delayed' && !checkout && (
        <div>
          There is a delay on incoming stock on your selection for this
          component. Checking out with this item will cause a delay in the
          fulfilment of your order.
        </div>
      )}
      {type === 'removed' && checkout && (
        <div>
          One or more items from your build has gone out of stock and has thus
          been removed from your PC, requiring you to pick another item. Please
          amend before checking out.
        </div>
      )}
      {type === 'removed' && !checkout && (
        <div>
          Your previously chosen component is no longer available. Please select
          another component before continuing.
        </div>
      )}
    </div>
    <style jsx>
      {`
        .alert-box {
          display: flex;
          gap: 12px;
          color: #e4e4e4;
          font-family: 'Roboto-Medium';
          letter-spacing: 0;
          border-radius: 2px;
          padding: 8px 12px;
        }
        .delayed {
          border: 1px solid #0091ff;
          background-color: rgba(0, 145, 255, 0.1);
        }
        .removed {
          border: 1px solid #f7b500;
          background-color: rgba(247, 181, 0, 0.1);
        }
        .component {
          font-size: 16px;
          line-height: 24px;
        }
        .checkout {
          font-size: 13px;
          line-height: 20px;
        }
        .delay-icon {
          margin-top: 4px;
        }
        @media (max-width: ${breakPoints.small}) {
          .alert-box {
            padding: 5px 12px;
          }
          .checkout {
            font-size: 10px;
          }
        }
      `}
    </style>
  </>
);

PcModelDelayAlert.propTypes = {
  checkout: PropTypes.bool,
  type: PropTypes.oneOf(['delayed', 'removed']),
};

PcModelDelayAlert.defaultProps = {
  checkout: false,
  type: 'delayed',
};

export default PcModelDelayAlert;
