/* eslint-disable react/forbid-prop-types */
import Image from 'next/image';
import Link from 'next/link';
import PropTypes from 'prop-types';

const Logo = ({ width, height, src, styles }) => (
  <Link legacyBehavior href="/">
    <a href="/" style={styles}>
      <Image draggable={false} 
        src={src}
        alt="Aftershock logo"
        width={width}
        height={height}
        layout="fixed"
      />
    </a>
  </Link>
);

Logo.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  src: PropTypes.string,
  styles: PropTypes.object,
};

Logo.defaultProps = {
  width: 130,
  height: 30,
  src: 'https://cdn.shopify.com/s/files/1/0522/3320/7988/files/header_logo.svg?v=1713800797',
  styles: {},
};

export default Logo;
