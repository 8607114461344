import breakPoints from 'helpers/Styles';
import Link from 'next/link';
import { RichText } from 'prismic-reactjs';
import Image from 'next/image';

const TwoColumns = ({ block }) => {
  if (!block) return '';

  const {
    image,
    image_position,
    button_link,
    button_text,
    column_content,
    column_link_title,
    background_colour,
    column_link_title_colour,
    column_content_colour,
    button_text_colour,
    email_us_button,
  } = block.primary;
  const imageUrl = image ? image.url : null;
  const columnTitle = column_link_title ? column_link_title : null;
  const columnTitleColour = column_link_title_colour
    ? column_link_title_colour
    : '#ffffff';
  const backgroundColour = background_colour ? background_colour : '#000000';
  const columnContent = column_content ? column_content : null;
  const columnContentColour = column_content_colour
    ? column_content_colour
    : '#ffffff';
  const buttonTexts = button_text ? button_text : null;
  const buttonTextColour = button_text_colour ? button_text_colour : '#ffffff';
  const buttonLink = button_link ? button_link : '';
  return (
    <>
      <div className="two_columns wrapper">
        <div
          className={`two_column_container ${
            image_position === 'left' ? '' : 'content_reverse'
          }`}
        >
          <div className="image_column">
            {imageUrl && (
              <Image
                draggable={false}
                src={imageUrl}
                alt="Picture of block"
                width={705}
                height={542}
              />
            )}
          </div>
          <div className="information_column">
            <div className="information_content">
              <h1 className="column_title">{columnTitle || ''}</h1>
              <div className="column_content custom_colour">
                {columnContent && RichText.render(columnContent)}
              </div>

              {buttonLink ? (
                <Link legacyBehavior href={buttonLink}>
                  <a href={buttonLink} className="button_container">
                    <button type="button" className="two_column_button">
                      {buttonTexts}
                    </button>
                    <div className="arrow_right">
                      <img
                        draggable="false"
                        src="https://cdn.shopify.com/s/files/1/0522/3320/7988/files/icon_arrow.png?v=1713803419"
                        alt="arrow_right"
                      />
                    </div>
                  </a>
                </Link>
              ) : null}

              {email_us_button != null && (
                <a className="email-btn" href={`mailto:${email_us_button}`}>
                  Email Us
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
      <style jsx>
        {`
          .two_columns {
            padding-top: 40px;
            padding-bottom: 40px;
            background: ${backgroundColour};
          }
          .two_column_container {
            display: flex;
            justify-content: center;
          }
          .content_reverse {
            flex-direction: row-reverse;
          }
          .image_column {
            flex: 1;
          }
          .image_column img {
            width: 100%;
          }
          .information_column {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 30px;
          }
          .information_column h1 {
            font-size: 35px;
            width: 430px;
            font-family: 'Eurostile-Bold';
            color: ${columnTitleColour};
            text-transform: uppercase;
            width: 100%;
          }
          .information_column p {
            width: 427px;
            font-family: 'Roboto-Medium';
            font-size: 16px;
            letter-spacing: 0;
            line-height: 24px;
            margin-bottom: 40px;
          }
          .column_content.custom_colour {
            color: ${columnContentColour};
            font-family: 'Roboto-Normal';
          }

          .button_container {
            display: flex;
          }
          .button_container:hover > .two_column_button {
            background: #800006;
          }
          .button_container:hover > .arrow_right {
            background: #141414;
          }
          .two_column_button {
            height: 48px;
            min-width: 128px;
            color: ${buttonTextColour};
            background: #950810;
            font-family: 'Roboto-Medium';
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 24px;
            border-radius: 3px 0 0 3px;
            border: none;
            outline: 0;
            padding: 12px 24px;
            text-transform: uppercase;
          }
          .arrow_right {
            width: 48px;
            height: 48px;
            background: #242424;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            border-radius: 0px 3px 03px 0px;
          }
          .arrow_right img {
            width: 25px;
          }

          @media (max-width: ${breakPoints.medium}) {
            .two_column_container {
              display: block;
            }
            .button_container {
              display: flex;
              width: auto;
              margin: 0 auto;
              justify-content: center;
            }
            .information_content {
              width: 100%;
            }
            .information_content h1,
            .information_content p {
              width: 100%;
            }
            .information_column h1 {
              text-align: center;
            }
            .information_content p {
              margin-bottom: 50px !important;
            }
            .image_column {
              margin: 0 auto;
              text-align: center;
            }
          }
          @media (max-width: ${breakPoints.small}) {
            .two_columns {
              padding: 20px;
              padding-bottom: 40px;
            }
          }
          @media (max-width: ${breakPoints.mobileM}) {
            .information_column {
              padding: 0;
            }
          }
          .email-btn {
            background-color: #950810;
            font-size: 15px;
            color: white;
            width: 137px;
            height: 37px;
            border-radius: 3px;
            border: none;
            margin-top: 5%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: bold;
          }
        `}
      </style>
    </>
  );
};

export default TwoColumns;
