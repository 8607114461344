import SidePanel from 'components/SidePanel';
import SidePanelCreateProfileForm from 'components/SidePanel/SidePanelCreateProfileForm';
import SidePanelForgotPasswordForm from 'components/SidePanel/SidePanelForgotPasswordForm';
import SidePanelLoginForm from 'components/SidePanel/SidePanelLoginForm';
import SidePanelYourProfile from 'components/SidePanel/SidePanelYourProfile';
import React, { useContext, useEffect, useState } from 'react';
import breakPoints from 'helpers/Styles';
import { StorefrontContext } from 'providers/storefront';
import {
  setCustomerToken as setCustomerTokenCookie,
  signOut,
} from 'helpers/cookies';
import { useRouter } from 'next/router';
import SidePanelHelpAndSupport from 'components/SidePanel/SidePanelHelpAndSupport';
import ChatPingingIconButton from './ChatPingingIconButton';

function DrawerButtons({ isLoggedIn, setIsLoggedIn }) {
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const { setProfileTabIndex, isChatOpen, setIsChatOpen } =
    useContext(StorefrontContext);
  const [currentIndex, setCurrentIndex] = useState(isLoggedIn ? 3 : 0);

  const router = useRouter();

  const onHandleLogout = () => {
    signOut();
    setIsLoggedIn(false);
    setCurrentIndex(0);
    //bad and temporary solution
    window.location.reload();
  };

  const gotoOrders = () => {
    setProfileTabIndex(2);
    router.push('/me');
    setIsProfileModalOpen(false);
  };

  const gotoProfile = () => {
    setProfileTabIndex(0);
    router.push('/me');
    setIsProfileModalOpen(false);
  };

  const openChat = () => {
    window.FrontChat('show');
  };

  const states = [
    {
      title: 'log in',
      content: (
        <SidePanelLoginForm
          setIsLoggedIn={setIsLoggedIn}
          onHandleForgotPassword={() => setCurrentIndex(1)}
          onHandleCreateProfile={() => setCurrentIndex(2)}
          onHandleLogin={() => setCurrentIndex(3)}
        />
      ),
    },
    {
      title: 'Forgot password',
      content: (
        <SidePanelForgotPasswordForm
          onHandleGoBack={() => setCurrentIndex(0)}
        />
      ),
    },
    {
      title: 'Create Profile',
      content: (
        <SidePanelCreateProfileForm
          onCreateProfile={() => setCurrentIndex(3)}
          onHandleLogin={() => setCurrentIndex(0)}
        />
      ),
    },
    {
      title: 'Your Profile',
      content: (
        <SidePanelYourProfile
          onHandleLogout={() => onHandleLogout()}
          gotoOrders={gotoOrders}
          gotoProfile={gotoProfile}
          openChat={openChat}
        />
      ),
    },
    {
      title: 'help',
      content: (
        <SidePanelHelpAndSupport
          openChat={openChat}
          onCloseSidePanel={() => setIsProfileModalOpen(false)}
        />
      ),
    },
  ];
  return (
    <div className="drawer-buttons-container">
      <ChatPingingIconButton
        onClick={() => {
          setCurrentIndex(4);
          setIsProfileModalOpen((prev) => !prev);
        }}
      />
      <button
        className="icon_button"
        onClick={() => {
          setCurrentIndex(0);
          setIsProfileModalOpen((prev) => !prev);
        }}
      >
        <img
          draggable="false"
          src={
            isLoggedIn
              ? 'https://cdn.shopify.com/s/files/1/0522/3320/7988/files/icon-profile-logged-in.svg?v=1713945805'
              : 'https://cdn.shopify.com/s/files/1/0522/3320/7988/files/icon-profile.svg?v=1713799500'
          }
          alt="cart icon"
          width={24}
          height={24}
        />
      </button>
      {isLoggedIn ? (
        <SidePanel
          title={states[3].title}
          isOpen={isProfileModalOpen}
          onClose={() => setIsProfileModalOpen(false)}
          isHasFeaturedImage={false}
        >
          {states[3].content}
        </SidePanel>
      ) : (
        <SidePanel
          title={states[currentIndex].title}
          isOpen={isProfileModalOpen}
          onClose={() => setIsProfileModalOpen(false)}
          isHasFeaturedImage={currentIndex === 4}
        >
          {states[currentIndex].content}
        </SidePanel>
      )}

      <style jsx>
        {`
          .chat_icon {
            position: relative;
            border: none;
            background: none;
            opacity: 0.75;
          }
          .chat_icon:hover {
            opacity: 1;
          }
          .ping {
            animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
          }
          @keyframes ping {
            75%,
            100% {
              transform: scale(2);
              opacity: 0;
            }
          }

          .chat_badge {
            background: #7ed321;
            height: 8px;
            width: 8px;
            border-radius: 50%;
            position: absolute;
            top: 0px;
            right: 2px;
          }

          .drawer-buttons-container {
            display: flex;
            gap: 12px;
          }
          @media (min-width: 420px) {
            .drawer-buttons-container {
              display: flex;
              gap: 30px;
            }
          }
          @media (max-width: 1280px) {
            .drawer-buttons-container {
              gap: 12px;
            }
          }
          .icon_button {
            background: transparent;
            border: none;
            cursor: pointer;
            opacity: 0.75;
            position: relative;
          }
          .icon_button:hover {
            opacity: 1;
          }
          @media (max-width: ${breakPoints.medium}) {
            .icon_button {
              display: none;
            }
          }
        `}
      </style>
    </div>
  );
}

export default DrawerButtons;
