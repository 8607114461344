import css from 'styled-jsx/css';

export default css`
  @font-face {
    font-family: 'Eurostile';
    src: url('/fonts/EuroStyleNormal.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Roboto';
    src: url('/fonts/Roboto-Bold.ttf') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  .third_menu_section {
    display: flex;
    flex-direction: column;
    flex: 0 0 25%;
    padding-bottom: 40px;
  }
  .third_menu_section_title {
    font-family: 'Roboto';
    pading: 0;
    margin: 0;
    color: #e4e4e4;
    font-size: 18px;
    line-height: 20px;
    font-weight: bold;
  }
  .third_menu_subsection {
    display: flex;
    align-items: flex-start;
    margin-top: 16px;
  }
  .third_menu_items {
    display: flex;
    flex-direction: column;
    margin-left: 17px;
  }
  .third_menu_image {
    width: 120px;
    haight: 120px;
  }
  .third_menu_image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .third_menu_titles {
    cursor: pointer;
  }
  .third_menu_title {
    font-family: 'Roboto';
    font-size: 14px;
    line-height: 14px;
    color: #989898;
    transition: transform 0.3s ease;
  }
  .third_menu_titles:hover .third_menu_title {
    color: #fd030a;
    transform: translateX(6px);
  }
  .third_menu_subtitle {
    font-family: 'Eurostile';
    font-weight: 550;
    margin-top: 7px;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.5px;
    color: #676767;
  }
  .see-more-link {
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: bold;
    color: #950810;
    cursor: pointer;
  }
`;
