/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import pluck from 'utils/pluck';
import slugify from 'slugify';
import Secondary from '../Secondary';

const AccountPrimary = ({ ksps, quiz, data, opacityRef }) => {
  const [mainMenuHoverIndex, setMainMenuHoverIndex] = useState(null);
  const [activeLinkIndex, setActiveLinkIndex] = useState(null);
  const [openNav, setOpenNav] = useState(false);
  const [top, setTop] = useState(80);

  useEffect(() => {
    window.addEventListener('scroll', (e) => {
      if (window.scrollY === 0) {
        setTop(80);
        return;
      }
      if (window.scrollY <= 32 && window.scrollY > 0) {
        setTop(80 - window.scrollY);
      } else {
        setTop(48);
      }
    });
    return () => {
      window.removeEventListener('scroll', (e) => {});
    };
  }, []);

  const handleBodyOpacity = () => {
    opacityRef.current.style.opacity = '0.25';
    opacityRef.current.style.backgroundColor = '#000000';
  };
  const handleBodyOpacityLeave = () => {
    opacityRef.current.style.opacity = '1';
    opacityRef.current.style.backgroundColor = '#000';
  };
  return (
    <>
      <div className="primary_menu">
        <div className="primary_container">
          {data.map((item, index) => {
            const primary = item.filter((n) => n.slice_type === '1st_level');
            const handleActiveMenu = (event = undefined) => {
              setMainMenuHoverIndex(index + 1);
              if (
                !(
                  event.target.parentElement.parentElement.classList.contains(
                    'accessories',
                  ) ||
                  event.target.parentElement.classList.contains(
                    'accessories',
                  ) ||
                  event.target.classList.contains('accessories') ||
                  event.target.parentElement.parentElement.classList.contains(
                    'ready-to-ship',
                  ) ||
                  event.target.parentElement.classList.contains(
                    'ready-to-ship',
                  ) ||
                  event.target.classList.contains('ready-to-ship')
                )
              )
                handleBodyOpacity();
            };
            const handleOut = () => {
              setMainMenuHoverIndex(null);
            };
            const handleMouseOver = () => {
              setActiveLinkIndex(activeLinkIndex);
              setOpenNav(true);
            };
            const handleClick = () => {
              setActiveLinkIndex(index);
              setOpenNav(!openNav);
            };
            const handleSecondaryClick = () => {
              setActiveLinkIndex(index);
            };
            if (!(primary.length > 0)) return null;
            const url = pluck(primary, '0.primary.link');
            const title = pluck(primary, '0.primary.title');
            const items = item.filter((n) => n.slice_type === '2nd_level');
            const key = `primary-${index}`;
            const menuType = pluck(primary, '0.primary.menu_type');
            const columns = pluck(primary, '0.items');
            const parent = pluck(primary, '0.primary');
            const classNames = `top-level  ${slugify(
              title,
            ).toLocaleLowerCase()} ${index === 1 ? 'first-tab' : ''}`;
            const rtsItems = item.filter((n) => n.slice_type === '3rd_level');
            return (
              <div
                tabIndex={10 + index}
                className={classNames}
                key={key}
                onMouseOver={(event) => handleActiveMenu(event)}
                onMouseOut={handleOut}
                onClick={handleActiveMenu}
                aria-hidden="false"
                onMouseLeave={handleBodyOpacityLeave}
              >
                {url && (
                  <Link legacyBehavior href={url}>
                    <a href={url}>
                      <div
                        onMouseOver={handleMouseOver}
                        onClick={handleClick}
                        aria-hidden="false"
                        className={`top-level-title  ${
                          mainMenuHoverIndex === index + 1
                            ? 'top-level-2'
                            : null
                        } ${
                          activeLinkIndex === index
                            ? 'top-level-activated'
                            : null
                        }`}
                      >
                        {title}
                      </div>
                    </a>
                  </Link>
                )}

                {menuType === 'mega-menu' && (
                  <div>
                    <Secondary
                      onClick={handleSecondaryClick}
                      openNav={openNav}
                      items={items.length > 0 ? items : rtsItems}
                      kspsItems={ksps}
                      quizItems={quiz}
                      columns={columns}
                      parent={parent}
                      mainMenuHoverIndex={mainMenuHoverIndex - 1}
                      mainMenuCurrentIndex={index}
                    />
                  </div>
                )}
                <img draggable="false"
                  className="icon-down"
                  src="/icons/icon-chevron-down-dark.svg"
                  alt="chevron_down"
                />
              </div>
            );
          })}
        </div>
      </div>
      <style jsx>{`
        .icon-down {
          margin-left: 14px;
        }
        .primary_menu {
          position: fixed;
          top: ${top}px;
          left: 0;
          right: 0;
          background: white;
          margin: 0;
          padding: 0;
          font-size: 13px;
          line-height: 24px;
          z-index: 3;
        }
        .primary_container {
          padding: 0px 48px;
          align-items: center;
          display: flex;
          max-width: 1440px;
          margin: 0px auto;
        }
        .top-level {
          font-family: big_noodle_titling;
          font-size: 20px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
          color: #242424;
          padding: 12px 32px;
          display: flex;
          align-items: center;
          border-left: 1px #e4e4e4 solid;
        }
        .first-tab {
          padding-left: 0 !important;
          border-left: none;
        }
        .top-level-title {
        }
        .top-level a {
        }
        li:first-child {
        }

        .top-level:hover {
        }
        .top-level-2 {
        }
        .top-level-activated {
        }
        .menu_icon_light,
        .menu_icon {
          margin-left: 8px;
        }
        .menu_icon_light {
        }
        .top-level:hover a > .menu_icon_light {
        }
        .top-level:hover a > .menu_icon {
        }
        .icon_show {
        }
        .icon_hidden {
        }
      `}</style>
    </>
  );
};

AccountPrimary.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array,
};

AccountPrimary.defaultProps = {
  data: [],
};

export default memo(AccountPrimary);
