/* eslint-disable react/prop-types */
import React, { useState, memo, useContext } from 'react';
import pluck from 'utils/pluck';
import Router from 'next/router';
import Link from 'next/link';
import { StorefrontContext } from 'providers/storefront';
import Secondary from '../SecondaryMobile';
import SidePanel from '../../../../../components/SidePanel';
import SidePanelCreateProfileForm from '../../../../../components/SidePanel/SidePanelCreateProfileForm';
import SidePanelForgotPasswordForm from '../../../../../components/SidePanel/SidePanelForgotPasswordForm';
import SidePanelLoginForm from '../../../../../components/SidePanel/SidePanelLoginForm';
import SidePanelYourProfile from '../../../../../components/SidePanel/SidePanelYourProfile';
import SidePanelHelpAndSupport from '../../../../../components/SidePanel/SidePanelHelpAndSupport';
import {
  setCustomerToken as setCustomerTokenCookie,
  signOut,
} from 'helpers/cookies';
import { useRouter } from 'next/router';
import breakPoints from 'helpers/Styles';

const PrimaryMobile = ({ ksps, data, componentItems, closeMenu }) => {
  const { isAnnouncementBarOpen, isLoggedIn, setIsLoggedIn, setIsMenuOpen } = useContext(StorefrontContext);
  const [secondaryHeader, setSecondaryHeader] = useState(null);
  const [showSecondary, setShowSecondary] = useState(null);
  const [secondaryTabs, setSecondaryTabs] = useState([]);
  const [secondaryItems, setSecondaryItems] = useState([]);
  const [parentLink, setParenLink] = useState(null);
  const goBack = () => {
    setSecondaryItems([]);
    setSecondaryTabs([]);
    setShowSecondary(false);
    setParenLink(null);
  };
  Router.onRouteChangeStart = () => {
    closeMenu();
  };

  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const { setProfileTabIndex, isChatOpen, setIsChatOpen } =
    useContext(StorefrontContext);
  const [currentIndex, setCurrentIndex] = useState(isLoggedIn ? 3 : 0);

  const router = useRouter();

  const onHandleLogout = () => {
    signOut();
    setIsLoggedIn(false);
    setCurrentIndex(0);
    //bad and temporary solution
    window.location.reload();
  };

  const gotoOrders = () => {
    setProfileTabIndex(2);
    router.push('/me');
    setIsProfileModalOpen(false);
    setIsMenuOpen(false);
  };

  const gotoProfile = () => {
    setProfileTabIndex(0);
    router.push('/me');
    setIsProfileModalOpen(false);
    setIsMenuOpen(false);
  };

  const openChat = () => {
    window.FrontChat('show');
  };

  const states = [
    {
      title: 'log in',
      content: (
        <SidePanelLoginForm
          setIsLoggedIn={setIsLoggedIn}
          onHandleForgotPassword={() => setCurrentIndex(1)}
          onHandleCreateProfile={() => setCurrentIndex(2)}
          onHandleLogin={() => setCurrentIndex(3)}
        />
      ),
    },
    {
      title: 'Forgot password',
      content: (
        <SidePanelForgotPasswordForm
          onHandleGoBack={() => setCurrentIndex(0)}
        />
      ),
    },
    {
      title: 'Create Profile',
      content: (
        <SidePanelCreateProfileForm
          onCreateProfile={() => setCurrentIndex(3)}
          onHandleLogin={() => setCurrentIndex(0)}
        />
      ),
    },
    {
      title: 'Your Profile',
      content: (
        <SidePanelYourProfile
          onHandleLogout={() => onHandleLogout()}
          gotoOrders={gotoOrders}
          gotoProfile={gotoProfile}
          openChat={openChat}
        />
      ),
    },
    {
      title: null,
      content: (
        <SidePanelHelpAndSupport
          openChat={openChat}
          onCloseSidePanel={() => setIsProfileModalOpen(false)}
        />
      ),
    },
  ];

  const handleQuizClick = () => {
    closeMenu();
    router.push('/survey');
  };
  const handleQoutationClick = () => {
    closeMenu();
    router.push("/pages/corporate");
  };


  return (
    <>
      <div className="primary_menu_container">
        {data && (
          <>
            <ul className="primary_menu">
              {data.map((item, index) => {
                const primary = item.filter((n) => n.slice_type === '1st_level');
                const secondaryMenu = item.filter((n) => n.slice_type === '2nd_level');
                if (!(primary.length > 0)) return null;
                const title = pluck(primary, '0.primary.title');
                const key = `item-${index}`;
                return (
                  <React.Fragment key={key}>
                    <li
                      className="active-menu"
                      aria-hidden="false"
                      onClick={() => {
                        if (showSecondary === key) {
                          setShowSecondary(null);
                        } else {
                          setShowSecondary(key);
                        }
                      }}
                    >
                      {title}
                      <div className={`img-container ${showSecondary === key ? 'rotated' : ''}`}>
                        <img draggable="false"
                          src="https://cdn.shopify.com/s/files/1/0522/3320/7988/files/chevron-right.png?v=1713865512"
                          alt="first_level menu"
                          layout="fixed"
                        />
                      </div>
                    </li>
                    <div className="submenu-container">
                      <div className={`submenu ${showSecondary === key ? 'open' : ''}`}>
                        <Secondary
                          items={secondaryMenu}
                          secondaryHeader={secondaryHeader}
                          tabs={secondaryTabs}
                          closeMenu={closeMenu}
                          goBack={goBack}
                          ksps={ksps}
                          parentLink={parentLink}
                          componentItems={componentItems}
                        />
                      </div>
                    </div>
                    {/* {!showSecondary && (
                      <div>
                        {menuType === 'simple-link' && primaryLink && (
                          <Link type='button' href={primaryLink} passHref onClick={onClose}>
                            <li className="active-menu" aria-hidden="false">
                              {title}
                              <div>
                                <img draggable="false"
                                  src="/images/chevron-right.png"
                                  alt="first_level menu"
                                  layout="fixed"
                                />
                              </div>
                            </li>
                          </Link>
                        )}
                      </div>
                    )} */}
                  </React.Fragment>
                );
              })}
                <li
                  className="active-menu"
                  aria-hidden="false"
                  onClick={() => {
                    setCurrentIndex(0);
                    setIsProfileModalOpen((prev) => !prev);
                  }}
                >
                  {'ACCOUNT'}
                  <div>
                    <img draggable="false"
                      src="/images/chevron-right.png"
                      alt="first_level menu"
                      layout="fixed"
                    />
                  </div>
                </li>
            </ul>
            {showSecondary !== 'item-3' ? (
              <div className="wrapper quiz">	
                <div className="quiz-body">
                  <div className='quiz-topic'>
                    RIG FINDER
                  </div>
                  <div className="quiz-title">
                    Unsure of which PC is the right one for you? Take our Aftershock Quiz to<br/>
                    determine which PC is most Suited to your needs
                  </div>
                  <button type="button" onClick={handleQuizClick} className="quizz-button">
                    <div className="quiz-button">TAKE THE QUIZ</div>
                  </button>
                </div>
              </div>
            ) : (
              <div className="wrapper quiz">	
                <div className="quiz-body">
                  <div className='quiz-topic'>
                  GET A QUOTATION
                  </div>
                  <div className="quiz-title">
                  Ready to elevate your workstation experience? Click now to request a personalized<br/>
                  quote for your custom workstation build!
                  </div>
                  <button type="button" onClick={handleQoutationClick} className="quizz-button">
                    <div className="quiz-button">GET A QUOTATION</div>
                  </button>
                </div>
              </div>
            )}
          </>
        )}

        {isLoggedIn ? (
          <SidePanel
            title={states[3].title}
            isOpen={isProfileModalOpen}
            onClose={() => setIsProfileModalOpen(false)}
            isHasFeaturedImage={false}
          >
            {states[3].content}
          </SidePanel>
        ) : (
          <SidePanel
            title={states[currentIndex].title}
            isOpen={isProfileModalOpen}
            onClose={() => setIsProfileModalOpen(false)}
            isHasFeaturedImage={currentIndex === 4}
          >
            {states[currentIndex].content}
          </SidePanel>
        )}
      </div>

      <style jsx>
        {`
          @font-face {
            font-family: 'Eurostile_Heavy';
            src: url('/fonts/EurostileBold.woff') format('woff');
            font-weight: normal;
            font-style: heavy;
          }
          @font-face {
            font-family: 'Eurostile_Bold';
            src: url('/fonts/EurostileBold.woff') format('woff');
            font-weight: normal;
            font-style: normal;
          }
          .primary_menu_header_text {
            color: #949494;
            font-size: 13px;
            line-height: 20px;
            font-weight: 700;
            font-family: 'Roboto-Medium';
          }
          .primary_menu_header {
            display:flex;
            justify-content: center;
            align-items: center;
            height:56px;
            width: 100%;
            background: #141414;
            border-top: 1px solid rgba(228,228,228,0.2) ;
            border-bottom: 1px solid rgba(228,228,228,0.2) ;
          }
          .primary_menu_container {
            margin-top:${isAnnouncementBarOpen ? '80px' : '48px'};
            height: auto;
            background: #000000;
            transition: all 0.6s linear;
            animation: slide-left 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
          }

          @keyframes slide-left {
            0% {
              opacity: 0;
              transform: translateX(100%);
            }
            100% {
              opacity: 1;
              transform: translateX(0);
            }
          }

          .primary_menu {
            background: #000000;
            margin: 0;
            width: 100%;
            margin: 0 auto;
            padding: 0;
            border-top: 1px solid rgba(228,228,228,0.2);
          }
          .img-container {
            display: inline-block;
            transition: transform 0.3s ease;
          }
          .rotated {
            transform: rotate(90deg);
          }
          .submenu-container {
            overflow: hidden;
          }
          .submenu {
            transition: max-height 0.5s ease;
            max-height: 0;
          }
          .submenu.open {
            max-height: 600px;
          }
          .primary_menu li {
            height: 56px;
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding: 16px 0;
            border-bottom: 1px solid rgba(228,228,228,0.2) ;
            color: #E4E4E4;
            text-transform: uppercase;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 24px;
            padding-left: 16px;
            padding-right: 10px;
            cursor:pointer;
          }
          .category_navigation__title{
            display: block !important:
            margin-left: 10px;
            color: red;
          }
          .primary_menu li a {
            color: #141414;
          }
          .quiz {
            display: flex;
            justify-content: center;
            flex-direction: column;
            margin-top: 24px;
            max-width: 343px;
            margin-bottom: 64px;
            animation: fade-in 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
          }
          .quiz-body {
            position: relative;
            padding: 0 0 30px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 15px 15px 15px 15px;
            background-image: ${showSecondary === 'item-3' 
              ? 'url(https://cdn.shopify.com/s/files/1/0522/3320/7988/files/Rectangle_425.png?v=1714561200)' 
              : 'url(https://cdn.shopify.com/s/files/1/0522/3320/7988/files/SERVICE_CENTRE_1.png?v=1714484364)'};
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
          }
          ${showSecondary === 'item-3' ? `
          .quiz-body::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.3);
            z-index: 1;
          }
          ` : ''}
          .quiz-topic {
            font-family: 'Eurostile_Heavy';
            font-size: 15;
            line-height: 18px;
            text-align: center;
            margin-top: 50px;
            margin-bottom: 10px;
            z-index: 2;
          }
          .quiz-title {
            margin: 0;
            font-family: 'Eurostile_Bold';
            font-size: 8px;
            color: #e4e4e4;
            text-align: center;
            margin-bottom: 10px;
            z-index: 2;
          }
          .quizz-button {
            font-family: DINPro, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
            background-color: transparent;
            border: none;
            color: inherit;
            cursor: pointer;
            padding: 0;
            margin: 0;
            z-index: 2;
          }
          .quiz-button {
            margin-top: 8px;
            cursor: pointer;
            font-size: 13px;
            line-height: 24px;
            padding: 4px 16px;
            background: #950810;
            border-radius: 4px;
            z-index: 2;
          }
        `}
      </style>
    </>
  );
};

export default memo(PrimaryMobile);
