import React, { useContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { useMutation } from '@apollo/client';
import {
  useCheckoutEffect,
  checkoutLineItemsUpdate,
  checkoutLineItemsRemove,
} from 'helpers/graphql/checkout';
import { StorefrontContext } from 'providers/storefront';
import { checkCustomProductInventory } from 'helpers/check-custom-products-inventory';
import { initiateCheckoutEvent } from 'lib/events/fb-pixel/initiate-checkout';
import PcModelDelayAlert from 'containers/PcModel/PcModelDelayAlert';
import cn from 'classnames';
import LineItemsGroup from '../LineItem/LineItemsGroup';
import CartLoader from '../CartLoader';
import { fIsValidJSON } from 'utils/functions';
import breakPoints from 'helpers/Styles';
import { useRouter } from 'next/router';
import { fixDecimalPlaces } from 'helpers/smallFixes';

const CartContent = () => {
  const router = useRouter();
  const { checkout, setCheckout } = useContext(StorefrontContext);
  const [inventoryChecked, setInventoryChecked] = useState(false);
  const [delayedItems, setDelayedItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [
    lineItemUpdateMutation,
    {
      data: lineItemUpdateData,
      loading: lineItemUpdateLoading,
      error: lineItemUpdateError,
    },
  ] = useMutation(checkoutLineItemsUpdate);

  const [
    lineItemRemoveMutation,
    {
      data: lineItemRemoveData,
      loading: lineItemRemoveLoading,
      error: lineItemRemoveError,
    },
  ] = useMutation(checkoutLineItemsRemove);

  useCheckoutEffect(lineItemUpdateData, 'checkoutLineItemsUpdate', setCheckout);
  useCheckoutEffect(lineItemRemoveData, 'checkoutLineItemsRemove', setCheckout);
  const updateLineItem = async (lineItemId, quantity, type) => {
    if (type == 'rts') {
      let productId = '';
      await checkout.lineItems.edges.filter((itm) => {
        if (itm.node.id == lineItemId) {
          const variantId = itm.node.variant.id;
          const value = JSON.parse(itm.node.customAttributes[0].value);

          if (variantId == value.product_id) {
            productId = value.product_id;
          }
        }
      });
      if (productId != '') {
        let LineItemIds = [];
        checkout.lineItems.edges.map((itm) => {
          const lineId = itm.node.id;
          let value = '';
          if (itm.node.customAttributes[0].key != 'link') {
            value = JSON.parse(itm.node.customAttributes[0].value);
          }
          const variantId = itm.node.variant.id;
          const qty = parseInt(itm.node.quantity) - 1;

          if (value.product_id == productId) {
            LineItemIds.push({ id: lineId, quantity: parseInt(quantity, 10) });
          }
        });
        if (Cookies.get('checkoutIdNew')) {
          const variables = {
            checkoutId: Cookies.get('checkoutIdNew'),
            lineItems: LineItemIds,
          };
          lineItemUpdateMutation({ variables });
        } else {
          const variables = {
            checkoutId: checkout.id,
            lineItems: LineItemIds,
          };
          lineItemUpdateMutation({ variables });
        }
      } else {
        if (Cookies.get('checkoutIdNew')) {
          const variables = {
            checkoutId: Cookies.get('checkoutIdNew'),
            lineItems: [{ id: lineItemId, quantity: parseInt(quantity, 10) }],
          };
          lineItemUpdateMutation({ variables });
        } else {
          const variables = {
            checkoutId: checkout.id,
            lineItems: [{ id: lineItemId, quantity: parseInt(quantity, 10) }],
          };
          lineItemUpdateMutation({ variables });
        }
      }
    } else {
      if (Cookies.get('checkoutIdNew')) {
        const variables = {
          checkoutId: Cookies.get('checkoutIdNew'),
          lineItems: [{ id: lineItemId, quantity: parseInt(quantity, 10) }],
        };

        lineItemUpdateMutation({ variables });
      } else {
        const variables = {
          checkoutId: checkout.id,
          lineItems: [{ id: lineItemId, quantity: parseInt(quantity, 10) }],
        };
        lineItemUpdateMutation({ variables });
      }
    }
  };

  const removeAllLineItems = () => {
    let LineItemIds = [];
    checkout.lineItems.edges.map((itm) => {
      LineItemIds.push(itm.node.id);
    });

    if (Cookies.get('checkoutIdNew')) {
      const variables = {
        checkoutId: Cookies.get('checkoutIdNew'),
        lineItemIds: LineItemIds,
      };
      lineItemRemoveMutation({ variables });
    } else {
      const variables = {
        checkoutId: checkout.id,
        lineItemIds: LineItemIds,
      };
      lineItemRemoveMutation({ variables });
    }
  };

  const removeLineItem = async (lineItemId, type) => {
    if (type == 'rts') {
      let productId = '';
      await checkout.lineItems.edges.filter((itm) => {
        if (itm.node.id == lineItemId) {
          const variantId = itm.node.variant.id;
          const value = JSON.parse(itm.node.customAttributes[0].value);

          if (variantId == value.product_id) {
            productId = value.product_id;
          }
        }
      });
      if (productId != '') {
        let LineItemIds = [];
        checkout.lineItems.edges?.map((itm) => {
          const lineId = itm.node.id;
          // const value = JSON.parse(itm.node.customAttributes[0].value);
          // console.log({ this: itm.node.customAttributes[0].value });
          //checking valid json
          const jsonValue = itm.node.customAttributes[0].value;
          const value = fIsValidJSON(jsonValue) && JSON.parse(jsonValue);
          const variantId = itm.node.variant.id;

          if (value.product_id == productId) {
            LineItemIds.push(lineId);
          }
        });

        if (Cookies.get('checkoutIdNew')) {
          const variables = {
            checkoutId: Cookies.get('checkoutIdNew'),
            lineItemIds: LineItemIds,
          };
          lineItemRemoveMutation({ variables });
        } else {
          const variables = {
            checkoutId: checkout.id,
            lineItemIds: LineItemIds,
          };
          lineItemRemoveMutation({ variables });
        }
      } else {
        if (Cookies.get('checkoutIdNew')) {
          const variables = {
            checkoutId: Cookies.get('checkoutIdNew'),
            lineItemIds: [lineItemId],
          };
          lineItemRemoveMutation({ variables });
        } else {
          const variables = {
            checkoutId: checkout.id,
            lineItemIds: [lineItemId],
          };
          lineItemRemoveMutation({ variables });
        }
      }
    } else {
      if (Cookies.get('checkoutIdNew')) {
        const variables = {
          checkoutId: Cookies.get('checkoutIdNew'),
          lineItemIds: [lineItemId],
        };
        lineItemRemoveMutation({ variables });
      } else {
        const variables = {
          checkoutId: checkout.id,
          lineItemIds: [lineItemId],
        };
        lineItemRemoveMutation({ variables });
      }
    }
  };

  const readyToShipProducts = checkout?.lineItems?.edges.filter(
    (item) =>
      item?.node?.variant?.product?.productType
        .replace(/ /g, '')
        .toLowerCase() === 'readytoship' &&
      !item?.node?.variant?.product?.tags?.includes('custom'),
  );

  const accessoriesProducts = checkout?.lineItems?.edges.filter(
    (item) =>
      item?.node?.variant?.product?.productType === 'Accessories' ||
      item?.node?.variant?.product?.productType === 'Ultra Labs',
  );

  const customProducts = checkout?.lineItems?.edges.filter((item) =>
    item?.node?.variant?.product?.tags.includes('custom'),
  );

  const clearanceProducts = checkout?.lineItems?.edges.filter(
    (item) => item?.node?.variant?.product?.tags.includes('clearance'),
  );

  const otherProducts = checkout?.lineItems?.edges.filter(
    (item) => item?.node?.variant?.product?.productType === '',
  );

  const minArray = checkout?.lineItems?.edges.map((item) => {
    if (item?.node?.variant?.compareAtPrice) {
      return (
        item.node.quantity *
        (item.node.variant.compareAtPrice - item.node.variant.price)
      );
    }
    return 0;
  });

  const totalSaved = minArray.reduce((x, y) => x + y, 0);

  useEffect(() => {
    console.log('cart checkout is', checkout);

    if (checkout.lineItems) {
      let feeItem = checkout.lineItems.edges.find(
        (edge) => edge.node.title == 'Payment Method Fee',
      );
      if (feeItem) {
        removeLineItem(feeItem.node.id, 'fee');
      }
      console.log('feeItem', feeItem);
    }
  });

  const createDraftOrderFromCart = async (lineItems) => {

    const fixedOriginalPrice = lineItems.map((item) =>{
      return ({...item,originalUnitPrice:item.originalUnitPrice.amount})
    })

    const draftOrderResult = await fetch(
      '/api/shopify/orders/create-draft-order',
      {
        method: 'POST',
        body: JSON.stringify({ lineItems:fixedOriginalPrice }),
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
      .then((response) => {
        console.log({ response });
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
    console.log({ draftOrderResult });
  };
  // .replace(
  //   'gid://shopify/ProductVariant/',
  //   '',
  // )

  console.log({ orderSystem: Cookies.get('ordersystem') || 'normalorder' });
  const openCheckout = async () => {
    const lineItems = checkout.lineItems.edges.map((item) => {
      const res = item.node;
      const variantId = res.variant.id;
      const components =
        res.customAttributes.map((att) => ({
          key: att.key,
          value: att.value,
        })) || [];

      return {
        variantId: variantId,
        quantity: res.quantity,
        title: res.title,
        originalUnitPrice: res.variant.price,
        customAttributes: components,
      };
    });

    if (router.query.ordersystem === 'normalorder') {
      Cookies.remove('ordersystem');
    } else if (router.query.ordersystem === 'draftorder') {
      Cookies.set('ordersystem', 'draftorder', '7');
      console.log('setting cookie');
      createDraftOrderFromCart(lineItems);
      return;
    }
    if (Cookies.get('ordersystem') === 'draftorder') {
      console.log('using cookie draftorder');
      createDraftOrderFromCart(lineItems);
      return;
    }

    setLoading(true);
    if (!customProducts.length || inventoryChecked) {
      setLoading(false);
      initiateCheckoutEvent(checkout);
      pushDataLayers();
      window.location.href = checkout.webUrl;
    } else {
      const inventory = await Promise.all(
        customProducts.map((customProduct) =>
          checkCustomProductInventory(customProduct),
        ),
      );
      let delayedItem = false;
      inventory?.forEach((item, index) => {
        item?.forEach((i) => {
          if (i.policy === 'DENY' && i.available < 1) {
            delayedItem = true;
            setDelayedItems((prev) => [...prev, customProducts[index].node.id]);
          }
        });
      });

      if (delayedItem) {
        setLoading(false);
        setInventoryChecked(true);
      } else {
        setLoading(false);
        initiateCheckoutEvent(checkout);
        pushDataLayers();

        window.location.href = checkout.webUrl;
      }
    }
  };

  function pushDataLayers() {
    let totalValue = 0;
    const checkoutProducts = checkout.lineItems.edges.map((item) => {
      totalValue += parseInt(item.node.variant.price);
      return {
        content_type: 'product',
        quantity: item.node.quantity,
        // description: currentProduct?.description,
        content_id: (item.node.variant?.id).replace(
          'gid://shopify/ProductVariant/',
          '',
        ),
        content_name: item.node?.title,
        currency: 'AUD',
        price: item.node.variant.price,
        content_category: 'computer',
      };
    });

    const dataLayerResult = {
      event: 'initiate_checkout',
      contents: checkoutProducts,
      currency: 'AUD',
      value: totalValue,
    };

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(dataLayerResult);
  }

  if (lineItemUpdateLoading || lineItemRemoveLoading) {
    return <CartLoader />;
  }
  return (
    <div
      style={{
        height: '90%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingBottom: '15px',
      }}
    >
      <span className="clear-all" onClick={removeAllLineItems}>
        Clear All
      </span>
      <ul className="Cart__line-items" id="scroll">
        <LineItemsGroup
          title="READY TO SHIP PCs"
          lineItems={readyToShipProducts}
          removeLineItemInCart={removeLineItem}
          updateLineItemInCart={updateLineItem}
          key={1}
        />

        <LineItemsGroup
          lineItems={customProducts}
          delayedItems={delayedItems}
          title="CUSTOM BUILDS"
          removeLineItemInCart={removeLineItem}
          updateLineItemInCart={updateLineItem}
          key={2}
        />

        <LineItemsGroup
          lineItems={accessoriesProducts}
          removeLineItemInCart={removeLineItem}
          updateLineItemInCart={updateLineItem}
          title="ACCESSORIES"
          key={3}
        />

        <LineItemsGroup
          lineItems={clearanceProducts}
          removeLineItemInCart={removeLineItem}
          updateLineItemInCart={updateLineItem}
          title="CLEARANCE"
          key={3}
        />

        <LineItemsGroup
          lineItems={otherProducts}
          removeLineItemInCart={removeLineItem}
          updateLineItemInCart={updateLineItem}
          title="OTHERS"
          key={5}
        />
      </ul>
      <footer className="Cart__footer">
        <div className="Cart-info clearfix">
          <div className="Cart-info__total Cart-info__small">Subtotal</div>
          <div className="Cart-info__pricing">
            <span className="pricing">
              {`$ `}
              {fixDecimalPlaces(checkout?.subtotalPrice.amount)}
            </span>
          </div>
        </div>

        <div className="Cart-total">
          <div className="Cart-info__total">Total</div>
          <div className="Cart-total-price">
            <span className="pricing">
              {`$ `}
              {fixDecimalPlaces(checkout?.totalPrice.amount)}
            </span>
          </div>
        </div>

        {totalSaved && totalSaved.toFixed(2) !== 0 ? (
          <div className="Cart-total-saved">
            <div className="Cart-info__saved">YOU SAVED</div>
            <div className="Cart-total-saved">
              <span>
                $
                {totalSaved
                  .toFixed(2)
                  .toLocaleString()
                  .replace(/(\d{1,2})(?=(\d{3})+\.)/g, '$1,')}
              </span>
            </div>
          </div>
        ) : null}

        {inventoryChecked && (
          <div className="delay-alert-container">
            <PcModelDelayAlert checkout />
          </div>
        )}

        <button
          type="button"
          className={cn('Cart__checkout button', { checkoutDisabled: loading })}
          onClick={openCheckout}
          disabled={loading}
          id="checkout-btn"
        >
          {loading ? 'LOADING..' : 'CHECKOUT'}
        </button>
      </footer>

      <style jsx>
        {`
          .Cart__line-items {
            flex: 1 0 auto;
            margin: 0;
            padding: 0;
            height: 60%;
            overflow: auto;
            padding-right: 40px;
          }
          .Cart__footer {
            flex: 0 0 auto;
            padding-right: 40px;
          }
          .Cart__checkout {
            margin-top: ${inventoryChecked ? '10px' : '20px'};
            display: block;
            width: 100% !important;
            border: 1px solid black;
            border-radius: 3px !important;
            background: #950810;
            color: #e4e4e4;
            font-family: 'Roboto-Medium';
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 24px;
          }
          .checkoutDisabled {
            background: #949494;
          }
          .Cart__checkout:hover {
            background: #800006;
          }
          .Cart-info {
            display: flex;
            justify-content: space-between;
            margin-bottom: ${inventoryChecked ? '10px' : '30px'};
            margin-top: ${inventoryChecked ? '10px' : '30px'};
            padding-bottom: ${inventoryChecked ? '10px' : '30px'};
            border-bottom: 1px solid #343434;
          }
          .Cart-total {
            display: flex;
            justify-content: space-between;
          }
          .Cart-info__total {
            text-transform: uppercase;
            color: #949494;
            font-family: 'Roboto-Medium';
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 24px;
          }
          .Cart-total-price {
            color: #e4e4e4;
            font-family: 'big_noodle_titling';
            font-size: 31px;
            letter-spacing: 0;
            line-height: 40px;
            text-align: right;
          }
          .Cart-info__pricing {
            color: #e4e4e4;
            font-family: 'Roboto-Medium';
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 24px;
            text-align: right;
          }
          .Cart-total-saved {
            display: flex;
            justify-content: space-between;
            margin-top: ${inventoryChecked ? '5px' : '10px'};
          }
          .Cart-total-saved span,
          .Cart-info__saved {
            color: #fd0300;
            font-family: 'Roboto-Medium';
            font-size: 16px;
            letter-spacing: 0;
            line-height: 24px;
            text-align: right;
          }
          .delay-alert-container {
            margin-top: 10px;
          }
          #scroll::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #f5f5f5;
          }
          #scroll::-webkit-scrollbar {
            width: 6px;
            background-color: #f5f5f5;
          }
          #scroll::-webkit-scrollbar-thumb {
            background-color: #950810;
          }
          @media screen and (max-width: 550px) {
            .Cart__footer {
              padding-right: 20px;
            }
          }
          @media (max-width: ${breakPoints.small}) {
            .delay-alert-container {
              margin-top: 2px;
            }
            .Cart-total-price {
              line-height: ${inventoryChecked ? '30px' : '40px'};
              font-size: 28px;
            }
            .Cart-total-saved {
              margin-top: ${inventoryChecked ? '5px' : '10px'};
            }
          }
          .clear-all {
            font-size: 15px;
            color: #df000d;
            margin-right: 11%;
            cursor: pointer;
            position: absolute;
            top: 42px;
            right: 0;
            z-index: 99;
          }
        `}
      </style>
    </div>
  );
};

export default CartContent;
