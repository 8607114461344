import React from "react";

function CarouselReviewPagination({ slideIndex, maxValue }) {

  return (
    <>
      <div className="range-field-container">
        <input
          className="range-field"
          defaultValue={slideIndex}
          type="range"
          min={0}
          max={maxValue}
        />
      </div>
      <style jsx>{`
        .range-field-container {
					display: flex;
					justify-content: center;
					align-items: center;
					margin-top: 3%;
				}
      `}</style>
    </>
  );
}
export default CarouselReviewPagination;
