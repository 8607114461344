import PrismicClient from 'helpers/Prismic';

// Note: add url mapping here
export function linkResolver(doc) {
  // Pretty URLs for known types

  if (doc.type === 'collection-page') {
    return `/collections/${doc.uid.replace('clp-', '').replace('-', '/')}`;
  }
  if (doc.type === 'aux_page') {
    return `/pages/${doc.uid.replace('aux-', '')}`;
  }
  if (doc.type === 'ready_to_ship_page') {
    return `/ready-to-ship/${doc.uid.replace('rts-', '')}`;
  }
  if (doc.type === 'workstations_pag') {
    return `/workstations/${doc.uid.replace('ws-', '')}`;
  }
  if (doc.type === 'gaming_landing_page') {
    return `/gaming/${doc.uid.replace('glp-', '')}`;
  }
  if (doc.type === 'series-landing-page') {
    return `/series/${doc.uid.replace('slp-', '')}`;
  }

  if (doc.type === 'pc_model') {
    return `/pc-models/${doc.uid}`;
  }
  // Fallback for other types, in case new custom types get created
  return `/`;
}

// eslint-disable-next-line consistent-return
const Preview = async (req, res) => {
  const { token: ref, documentId } = req.query;

  // Check the token parameter against the Prismic SDK
  const url = await PrismicClient.getPreviewResolver(ref, documentId).resolve(
    linkResolver,
    '/',
  );

  if (!url) {
    return res.status(401).json({ message: 'Invalid token' });
  }

  // Enable Preview Mode by setting the cookies
  res.setPreviewData({
    ref, // pass the ref to pages so that they can fetch the draft ref
  });

  // Redirect the user to the share endpoint from same origin. This is
  // necessary due to a Chrome bug:
  // https://bugs.chromium.org/p/chromium/issues/detail?id=696204
  res.write(
    `<!DOCTYPE html><html><head><meta http-equiv="Refresh" content="0; url=${url}" />
    <script>window.location.href = '${url}'</script>
    </head>`,
  );

  res.end();
};

export default Preview;
