import css from 'styled-jsx/css';

export default css`
    @font-face {
        font-family: 'Eurostile';
        src: url('/fonts/EuroStyleNormal.woff') format('woff');
        font-weight: normal;
        font-style: normal;
    }
    .third_menu_container {
        display: flex;
        justify-content: center;
        height: 290px;
    }
    .third_menu_accessories {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin-top: 21px;
        margin-bottom: 60px;
    }
    .third_menu_accessories_title {
        color: #e4e4e4;
        font-size: 18px;
        line-height: 24px;
        text-align: left;
        cursor: pointer;
        font-weight: 500;
    }
    .third_menu_banner {
        display: flex;
        align-items: center;
        margin-top: 25px;
        gap: 55px;
    }
    .third_menu_products {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 30px;
    }
    .third_menu_product {
        cursor: pointer;
        transition: color 0.3s;
    }
    .third_menu_product:hover .third_menu_title {
        color: #fd0300;
    }

    .third_menu_title {
        transition: color 0.3s;
    }
    .third_menu_title {
        font-family: 'Eurostile';
        color: #949494;
        font-size: 16px;
        letter-spacing: 0.5px;
        text-align: center;
    }
    .third_menu_seeMore {
        color: #fd0300;
        font-size: 13px;
        line-height: 20px;
        padding-right: 7px;
        padding-left: 10px;
    }
    .third_menu_link {
        cursor: pointer;
    }
`;
