import { useState } from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';
import breakPoints from 'helpers/Styles';
import Image from 'next/image';
import Copyright from './Copyright';
import NewsRegister from './NewsRegister';

function Footer({ footer }) {
  const nav = footer ? footer.body : [];
  const [clickIndex, setClickIndex] = useState(null);
  const [modal, setModal] = useState(null);

  const [hoveredIcons, setHoveredIcons] = useState({});

  const icons = [
    {
      id: 'facebook',
      image:
        'https://cdn.shopify.com/s/files/1/0522/3320/7988/files/facebook-24px.png?v=1713878240',
      imageHover:
        'https://cdn.shopify.com/s/files/1/0522/3320/7988/files/Facebook-hover_1.png?v=1713882865',
      href: 'https://www.facebook.com/aftershockpcau',
      rel: 'facebook noreferrer',
      ariaLabel: 'facebook',
      alt: 'Facebook icon',
    },
    {
      id: 'instagram',
      image:
        'https://cdn.shopify.com/s/files/1/0522/3320/7988/files/instagram-24px.png?v=1713883588',
      imageHover:
        'https://cdn.shopify.com/s/files/1/0522/3320/7988/files/Instagram-hover.png?v=1713883763',
      href: 'https://www.instagram.com/aftershockpcau/',
      rel: 'instagram noreferrer',
      ariaLabel: 'instagram',
      alt: 'Instagram icon',
    },
    {
      id: 'youtube',
      image:
        'https://cdn.shopify.com/s/files/1/0522/3320/7988/files/youtube-24px.png?v=1713884100',
      imageHover:
        'https://cdn.shopify.com/s/files/1/0522/3320/7988/files/Youtube-hover.png?v=1713884301',
      href: 'https://www.youtube.com/user/aftershocknotebooks',
      rel: 'youtube',
      ariaLabel: 'youtube',
      alt: 'Youtube icon',
    },
    {
      id: 'twitter',
      image:
        'https://cdn.shopify.com/s/files/1/0522/3320/7988/files/twitter-24px.png?v=1713884380',
      imageHover:
        'https://cdn.shopify.com/s/files/1/0522/3320/7988/files/Twitter-hover.png?v=1713884421',
      href: 'https://twitter.com/AftershockpcAU',
      rel: 'twitter',
      ariaLabel: 'twitter',
      alt: 'Twitter icon',
    },
    {
      id: 'tik-tok',
      image:
        'https://cdn.shopify.com/s/files/1/0522/3320/7988/files/tik-removebg-preview.png?v=1716797506',
      imageHover:
        'https://cdn.shopify.com/s/files/1/0522/3320/7988/files/image_removebg_preview__13_negate.png?v=1716796786',
      href: 'https://www.tiktok.com/@aftershockpcau',
      rel: 'tik-tok',
      ariaLabel: 'tik-tok',
      alt: 'Tik-tok icon',
    },
  ];

  const handleMouseEnter = (id) => {
    setHoveredIcons((prevState) => ({
      ...prevState,
      [id]: true,
    }));
  };

  const handleMouseLeave = (id) => {
    setHoveredIcons((prevState) => ({
      ...prevState,
      [id]: false,
    }));
  };

  return (
    <div className="hide_for_rts">
      <div className="footer" id="footer">
        <NewsRegister />
        <div className="footer_container">
          <div className="wrapper">
            <div className="prismic_footer_links">
              {nav.map((item, key) => {
                const navItem = item && item.items ? item.items : [];
                return (
                  <div key={key} className="links_column">
                    <ul>
                      <li className="footer-title">
                        {item.primary && item.primary.column_title
                          ? item.primary.column_title
                          : ''}
                      </li>

                      {navItem.map((item, key) => (
                        <li key={key} className="footer_link">
                          <Link legacyBehavior href={item.link}>
                            <a href={item.link}>{item.link_title}</a>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                );
              })}
            </div>

            <div className="contact_us_container">
              <ul>
                <li className="footer-title">CONTACT US</li>
                <li className="contact_phone_number">
                  Phone: &nbsp;&nbsp;&nbsp;{'(03) 9043 3893'}
                </li>
                <li className="address">
                  <a
                    href="https://goo.gl/maps/9nAuX94HYGuj3zu16"
                    target="_blank"
                  >
                    Address: &nbsp;&nbsp;&nbsp;400 Dynon Rd, West Melbourne VIC
                    3003
                  </a>
                </li>
              </ul>
              <div className="social_links_desktop">
                {icons.map((link) => (
                  <a
                    key={link.id}
                    href={link.href}
                    target="_blank"
                    rel={link.rel}
                    aria-label={link.ariaLabel}
                    onMouseEnter={() => handleMouseEnter(link.id)}
                    onMouseLeave={() => handleMouseLeave(link.id)}
                  >
                    <Image
                      src={hoveredIcons[link.id] ? link.imageHover : link.image}
                      alt={link.alt}
                      width={24}
                      height={24}
                    />
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="panel hidden_on_desktop">
          <div className="accordion_container">
            {nav.map((item, index) => {
              const navItem = item && item.items ? item.items : [];
              return (
                <div key={index} className="column">
                  <div
                    className="footer-title"
                    onClick={() => {
                      setClickIndex(index), setModal(!modal);
                    }}
                  >
                    <div>
                      {item.primary && item.primary.column_title
                        ? item.primary.column_title
                        : ''}
                    </div>
                    <div>
                      <img
                        draggable="false"
                        src={
                          clickIndex !== null &&
                          clickIndex === index &&
                          modal === true
                            ? 'https://cdn.shopify.com/s/files/1/0522/3320/7988/files/icon_up_light_2.png?v=1713951736'
                            : 'https://cdn.shopify.com/s/files/1/0522/3320/7988/files/icon_down_light_2.png?v=1713866088'
                        }
                        alt="first_level menu"
                      />
                    </div>
                  </div>

                  {clickIndex !== null && clickIndex === index && modal === true
                    ? navItem.map((item, key) => (
                        <li key={key} className="footer_link">
                          <Link legacyBehavior href={item.link}>
                            <a href={item.link} title={item.link_title}>
                              {item.link_title}
                            </a>
                          </Link>
                        </li>
                      ))
                    : null}
                </div>
              );
            })}

            <div key={4} className="column">
              <div
                className="footer-title"
                onClick={() => {
                  setClickIndex(4), setModal(!modal);
                }}
              >
                <div>Contact Us</div>
                <div>
                  <img
                    draggable="false"
                    src={
                      clickIndex !== null && clickIndex === 4 && modal === true
                        ? 'https://cdn.shopify.com/s/files/1/0522/3320/7988/files/icon_up_light_2.png?v=1713951736'
                        : 'https://cdn.shopify.com/s/files/1/0522/3320/7988/files/icon_down_light_2.png?v=1713866088'
                    }
                    alt="first_level menu"
                  />
                </div>
              </div>

              {clickIndex !== null && clickIndex === 4 && modal === true ? (
                <ul className="contact_container">
                  <li className="contact_phone_number">
                    Phone: &nbsp;&nbsp;&nbsp;+61 390 433 893
                  </li>
                  <li className="address">
                    Address: &nbsp;&nbsp;400 Dynon Rd, West Melbourne VIC 3003
                  </li>
                </ul>
              ) : null}
            </div>

            <div className="social_links">
              <div>
                <div>
                  <a
                    href="https://www.facebook.com/aftershockpcau"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="facebook"
                  >
                    <Image
                      draggable={false}
                      src="https://cdn.shopify.com/s/files/1/0522/3320/7988/files/facebook-24px.png?v=1713878240"
                      alt="facebook icon"
                      width={24}
                      height={24}
                    />
                  </a>
                </div>
                <div>
                  <a
                    href="https://www.instagram.com/aftershockpcau/"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="instagram"
                  >
                    <Image
                      draggable={false}
                      src="https://cdn.shopify.com/s/files/1/0522/3320/7988/files/instagram-24px.png?v=1713883588"
                      alt="instagram icon"
                      width={24}
                      height={24}
                    />
                  </a>
                </div>
                <div>
                  <a
                    href="https://www.youtube.com/user/aftershocknotebooks"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="youtube"
                  >
                    <Image
                      draggable={false}
                      src="https://cdn.shopify.com/s/files/1/0522/3320/7988/files/youtube-24px.png?v=1713884100"
                      alt="youtube icon"
                      width={24}
                      height={24}
                    />
                  </a>
                </div>
                <div>
                  <a
                    href="https://twitter.com/AftershockpcAU"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="twitter"
                  >
                    <Image
                      draggable={false}
                      src="https://cdn.shopify.com/s/files/1/0522/3320/7988/files/twitter-24px.png?v=1713884380"
                      alt="twitter icon"
                      width={24}
                      height={24}
                    />
                  </a>
                </div>
                <div>
                  <a
                    href="https://www.tiktok.com/@aftershockpcau"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="tik-tok"
                  >
                    <Image
                      draggable={false}
                      src="https://cdn.shopify.com/s/files/1/0522/3320/7988/files/tik-removebg-preview.png?v=1716797506"
                      alt="tik-tok icon"
                      width={24}
                      height={24}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Copyright />
      </div>
      <style jsx>
        {`
          .footer {
            width: 100%;
            color: white;
            background: black;
          }
          .footer_container {
            width: 100%;
            background: #242424;
            padding: 50px 0px;
          }
          .footer_container .wrapper {
            display: flex;
          }
          .prismic_footer_links {
            display: flex;
            flex-flow: row;
            flex: 2;
          }
          .prismic_footer_links ul {
            padding: 0;
          }
          .contact_container {
            margin: 0;
            padding: 0;
          }
          .links_column {
            width: 25%;
          }
          .accordion_container {
            width: 100%;
          }
          .column {
            width: 100%;
            line-height: 60px;
            padding-left: 20px;
            background: #242424;
            border-bottom: 1px solid black;
          }
          .contact_us_container {
            flex: 1;
          }
          .social_links_desktop {
            display: flex;
            padding-left: 40px;
            padding-top: 40px;
            gap: 3%;
          }

          .youtube {
            display: block;
            width: 24px;
            height: 24px;
            cursor: pointer;
          }
          .facebook {
            display: block;
            width: 24px;
            height: 24px;
            cursor: pointer;
          }
          .twitter {
            display: block;
            width: 24px;
            height: 24px;
            cursor: pointer;
          }
          .instagram {
            display: block;
            width: 24px;
            height: 24px;
            cursor: pointer;
          }
          li {
            color: #949494;
            font-family: 'Roboto-Medium';
            font-size: 16px;
            letter-spacing: 0;
            line-height: 40px;
            list-style: none;
          }
          .footer_link:hover {
            color: #e4e4e4 !important;
          }
          .contact_phone_number .address {
            color: white;
          }
          h3 {
            font-family: 'DINPro-Bold' !important;
          }
          .footer-title {
            font-family: 'Eurostile-Bold' !important;
            font-size: 20px;
            letter-spacing: 0;
            line-height: 32px;
            color: #e4e4e4;
            font-weight: 300;
            height: 60px;
            padding-top: 15px;
            display: flex;
            justify-content: space-between;
            padding-right: 20px;
            cursor: pointer;
          }
          .hidden_on_desktop {
            display: none;
            width: 100%;
            // padding-top: 50px;
            margin-bottom: 30px;
          }
          .hidden_on_desktop .items {
            padding-left: 0px;
          }
          .hidden_on_desktop .items li a {
            color: white;
            font-size: 18px;
          }
          .social_links {
            height: 72px;
            background: #242424;
            display: flex;
            justify-content: center;
            algin-items: center;
          }
          .social_links div {
            width: 50%;
            margin: 0 auto;
            justify-content: center;
            align-items: center;
            display: flex;
          }
          .social_links img {
            height: 35px;
            margin-right: 15px;
          }
          .address a:hover {
            color: #e4e4e4;
          }
          @media (max-width: 330px) {
            .accordion_container {
              width: 90%;
            }
          }

          @media (max-width: ${breakPoints.medium}) {
            .footer {
              display: flex;
              flex-direction: column;
              padding: 0;
            }
            .footer_container {
              display: none;
            }
            .hidden_on_desktop {
              width: 100%;
              display: flex !important;
              justify-content: center;
              // padding-top: 20px;
            }
            .contact_us_container {
              width: 100%;
              display: block;
              justify-content: center;
              min-height: 350px;
            }
          }
          @media (max-width: ${breakPoints.small}) {
            .subscribe_button {
              font-size: 13px;
            }
          }
        `}
      </style>
    </div>
  );
}

Footer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  footer: PropTypes.any,
};

Footer.defaultProps = {
  footer: null,
};

export default Footer;
