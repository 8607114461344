import React from 'react';
import breakPoints from 'helpers/Styles';
import Link from 'next/link';

const Copyright = () => (
  <div className="copyright">
    <div className="copyright_container">
      <div>
        <div className="privacy_terms_links">
          <Link legacyBehavior href="/pages/privacy">
            <a href="/pages/privacy">Privacy Policy</a>
          </Link>
          &nbsp;|&nbsp;
          <Link legacyBehavior href="/pages/terms">
            <a href="/pages/terms">Terms of Use</a>
          </Link>
          &nbsp;|&nbsp;
          <Link legacyBehavior href="/pages/warranty">
            <a href="/pages/warranty">Refund Policy</a>
          </Link>
        </div>
        <div className="copyright_text">
          <span>
            Copyright {new Date().getFullYear()} AFTERSHOCK PC Pty Ltd – All Rights Reserved
          </span>
        </div>
      </div>

      <style jsx>
        {`
          .copyright {
            display: flex;
            justify-content: center;
            width: 100%;
          }
          .copyright_container {
            min-height: 64px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            background: black;
            color: #ffffff;
            font-size: 14px;
            height: 128px;
            padding: 0 30px;
          }
          .privacy_terms_links,
          .copyright_text {
            display: flex;
            justify-content: center;
          }
          .copyright_text {
            color: #e4e4e4;
            font-family: 'Roboto-Medium';
            font-size: 13px;
            letter-spacing: 0;
            line-height: 20px;
          }
          .privacy_terms_links {
            margin-bottom: 20px;
            color: #949494;
          }
          .copyright_container a:hover {
            color: #e02222;
          }
          @media (max-width: ${breakPoints.small}) {
            .copyright_container,
            .copyright_text {
              font-size: 11px;
            }
            .privacy_terms_links {
              line-height: 30px;
            }
          }
        `}
      </style>
    </div>
  </div>
);

export default Copyright;
