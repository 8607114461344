import React from 'react';

function SidePanelFormButton({ text, onClick, type = 'button', disabled }) {
  return (
    <>
      <button
        className="button"
        onClick={onClick}
        type={type}
        disabled={disabled}
      >
        {text}
      </button>
      <style jsx>{`
        .button {
          width: 320px;
          height: 48px;
          font-family: 'Roboto-Medium';
          font-size: 13px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: center;
          background: #950810;
          border-radius: 4px;
          border: none;
          color: #e4e4e4;
        }

        @media (max-width: 640px) {
          .button {
            width: 100%;
          }
        }
      `}</style>
    </>
  );
}

export default SidePanelFormButton;