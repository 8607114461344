import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import Logo from 'components/Logo';
import Image from 'next/image';
import { StorefrontContext } from 'providers/storefront';
import { getCheckoutId } from 'helpers/cookies';
import Link from 'next/link';
import CartCounter from '../Header/Navigation/CartCounter';
import styles from './styles';
import Countdown from 'react-countdown';
import DrawerButtons from 'components/NavigationButtonIcons/DrawerButtons';
import BannerCountdownTimer from '../Header/BannerCountdownTimer';
import { ThreeDots } from 'react-loader-spinner';
import Prismic from 'helpers/Prismic';

const MobileHeader = ({
  header,
  isChecked,
  openMenu,
  closeMenu,
  customProducts,
  customLaptops,
  workstationProducts,
  rtsProducts,
}) => {
  const [checkoutId, setCheckoutId] = useState(getCheckoutId());
  const {
    setIsCartOpen,
    checkout,
    setIsAnnouncementBarOpen,
    isAnnouncementBarOpen,
    isMenuOpen,
    isLoggedIn,
    setIsLoggedIn,
  } = useContext(StorefrontContext);
  useEffect(() => {
    if (checkout?.id) setCheckoutId(checkout.id);
  }, [checkout]);
  const [openSearch, setOpenSearch] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [search, setSearch] = useState('');
  const { allPcRtsProducts, setAllPcRtsProducts, setAllProducts } =
    useContext(StorefrontContext);

  const arraysOfObjectsAreEqual = (arr1, arr2) => {
    return JSON.stringify(arr1) === JSON.stringify(arr2);
  };

  function filterUniqueByTitle(products) {
    const uniqueProductsMap = new Map();
    for (const product of products) {
      if (!uniqueProductsMap.has(product.title1)) {
        uniqueProductsMap.set(product.title1, product);
      }
    }
    return Array.from(uniqueProductsMap.values());
  }

  async function getPcModelsByNames(names) {
    try {
      const response = await fetch(
        `/api/search/getAllPcModelsByNames?names=${names.join(',')}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const results = await response.json();
      return results;
    } catch (error) {
      console.error('Error fetching data:', error);
      return null;
    }
  }

  function getAllElementsInSearch() {
    setLoadingSearch(true);
    const sixHoursInMillis = 6 * 60 * 60 * 1000;
    const lastUpdated = localStorage.getItem('lastUpdated');
    const allProductsStore = localStorage.getItem('allProductsStore');

    // GET ALL CUSTOM PRODUCTS
    if (lastUpdated && allProductsStore) {
      const currentTime = new Date().getTime();
      const timeDiff = currentTime - parseInt(lastUpdated);
      if (timeDiff < sixHoursInMillis) {
        setAllProducts(JSON.parse(localStorage.getItem('allProductsStore')));
        setAllPcRtsProducts(
          JSON.parse(localStorage.getItem('allProductsStore')),
        );
        setLoadingSearch(false);
        return;
      }
    }
    let finalCustomProducts = [];
    let slpPcModels = [];
    customProducts?.body?.map((data, i) => {
      data.items.map((item, i) => {
        finalCustomProducts = [...finalCustomProducts, { product: item }];
      });
    });
    customLaptops?.body?.map((data, i) => {
      data.items.map((item, i) => {
        item.tags = 'laptops';
        finalCustomProducts = [...finalCustomProducts, { product: item }];
      });
    });
    finalCustomProducts?.map((data, i) => {
      const slp = data.product.link.split('/');
      slpPcModels = [...slpPcModels, slp[2]];
    });

    const newSlpModels = slpPcModels.map((model) => {
      if (model.includes('-')) {
        return model.split('-')[0];
      }
      return model;
    });

    function filterDuplicates(arr) {
      return arr.filter((item, index) => arr.indexOf(item) === index);
    }

    const updatedSlpModels = filterDuplicates(newSlpModels);

    const handleFlatArray = (arr) => {
      let filteredItems = arr.map((bodyItem) => {
        return bodyItem.items;
      });
      let filtered = filteredItems.map((el) => {
        return el.filter(
          (itm) => itm.product !== null && typeof itm.product !== 'undefined',
        );
      });

      const flatProducts = filtered.flat().filter((el) => el.product !== null);

      return flatProducts;
    };
    updatedSlpModels.push('*-milk');
    const sortedSlpModels = updatedSlpModels.sort();
    const promises = [];
    const chunkSize = 3;

    for (let i = 0; i < sortedSlpModels.length; i += chunkSize) {
      const chunk = sortedSlpModels.slice(i, i + chunkSize);
      promises.push(getPcModelsByNames(chunk));
    }

    Promise.all(promises)
      .then((response) => {
        const allPcModels = response.flat();
        const filteredAllPcModels = allPcModels.filter(
          (pcModel) => pcModel.price < 90000,
        );

        let filteredPcModels = [];

        filteredAllPcModels.map((option) => {
          const graphicsCardOptions = option?.modelOptionGroup?.find(
            (options) =>
              options.optionGroup.option_group_title === 'Graphics Card',
          );

          const cpuOptions = option?.modelOptionGroup?.find(
            (options) => options.optionGroup.option_group_title === 'CPU',
          );

          filteredPcModels = [
            ...filteredPcModels,
            {
              slug: option.slug,
              model_title: option.model_title,
              seo_description: option.seo_description,
              seo_title: option.seo_title,
              thumb_image: option.thumb_image,
              modelOptionGroup: [graphicsCardOptions],
              cpuGroup: [cpuOptions],
              buildType: option.buildType.build_type_name,
            },
          ];
        });

        // GET ALL RTS PRODUCTS
        let filteredItems = rtsProducts?.body?.map((bodyItem) => {
          return bodyItem.items;
        });
        let filtered = filteredItems?.map((el) => {
          return el.filter((itm) => itm.product !== null);
        });
        const flatProducts = filtered?.flat();
        const finalProducts = flatProducts?.filter(
          (el) => el.product !== undefined,
        );

        const pageSearch = [
          {
            title1: 'Gaming PC',
            subtitle: '',
            seo_description: `Build your setup with Singapore's #1 Custom PC Brand. Pairing incredible craftsmanship with a comprehensive curation of premium components, our team of artisans perfect every detail to your exact needs! Enjoy our industry-leading 2-year warranty and lifetime customer support today.`,
            thumb_image:
              'https://images.prismic.io/aftershock-singapore/80be4873-9b16-41ee-aaf2-8641d3c5da42_GAMING+CUSTOM.png?auto=compress,format',
            link: '/gaming/desktops',
          },
        ];
        Prismic.getByUID('collections_accessories', `cls-accessories`)
          .then((res) => {
            const allWorkstationProducts = workstationProducts?.body?.flatMap(
              (itemGroup) => itemGroup.items,
            );
            const uniqueWorkstationProducts = filterUniqueByTitle(
              allWorkstationProducts,
            );
            const allAccessoriesProducts = handleFlatArray(res?.data?.body);
            const allProducts = [...finalProducts, ...filteredPcModels];
            allProducts = [...allProducts, ...uniqueWorkstationProducts];
            allProducts = [...allProducts, ...allAccessoriesProducts];
            allProducts = [...allProducts, ...pageSearch];

            const uniqueProducts = Array.from(
              new Set(allProducts.map((product) => JSON.stringify(product))),
            ).map((product) => JSON.parse(product));

            const arraysAreEqual = arraysOfObjectsAreEqual(
              JSON.parse(localStorage.getItem('allProductsStore')),
              uniqueProducts,
            );

            if (!arraysAreEqual) {
              setAllProducts(uniqueProducts);
              localStorage.setItem(
                'allProductsStore',
                JSON.stringify(uniqueProducts),
              );
              localStorage.setItem(
                'lastUpdated',
                new Date().getTime().toString(),
              );
              setAllPcRtsProducts(uniqueProducts);
              setLoadingSearch(false);
            }
          })
          .catch((error) => {
            console.error('Error:', error);
          });
      })
      .catch((error) => {
        console.error('Error:', error);
      });
    // }
    // });
  }

  const topBanner =
    header &&
    header?.body?.filter((item) => item?.slice_type === 'shipping_banner');
  const topBannerContents = topBanner && topBanner[0]?.primary?.banner_contents;
  const hideBannerTimerRunsOut =
    topBanner && topBanner[0]?.primary?.hide_when_timer_runs_out;
  const topBannerTimer =
    header &&
    header?.body?.filter((item) => item?.slice_type === 'shipping_banner');
  const topBannerTimerContents =
    topBannerTimer && topBannerTimer[0]?.primary?.countdown_end;
  const Completionist = () => <span></span>;
  const renderer = ({
    days = null,
    hours = null,
    minutes = null,
    seconds = null,
    completed = null,
  }) => {
    if (completed) {
      return <Completionist />;
    } else {
      return (
        <span>
          {' '}
          | Ends in {days}d {hours}h {minutes}m {seconds}s
        </span>
      );
    }
  };

  const handleCloseBanner = () => {
    setIsAnnouncementBarOpen(false);
  };
  const handleCartOpen = () => {
    setIsCartOpen(true);
  };

  const handleChatToggle = () => {
    window.FrontChat('show');
  };

  const checkGraphicsCard = (data, search) => {
    let check = false;
    data.filter((item) => {
      const graphicsCardOptions = item?.modelOptionGroup?.find(
        (options) => options.optionGroup.option_group_title === 'Graphics Card',
      );

      if (graphicsCardOptions) {
        graphicsCardOptions.options.find((option) => {
          option.component.component_title
            .toLowerCase()
            .includes(search.toLowerCase());
          if (
            (check = option.component.component_title
              .toLowerCase()
              .includes(search.toLowerCase()))
          ) {
            return true;
          }
        });
      }
    });
    return check;
  };

  const checkCpu = (data, search) => {
    let check = false;
    data.filter((item) => {
      const cpuOptions = item?.cpuGroup?.filter(
        (options) => options?.optionGroup?.option_group_title == 'CPU',
      );

      if (cpuOptions != undefined && cpuOptions.length > 0) {
        cpuOptions[0].options?.find((option) => {
          option.component.component_title
            .toLowerCase()
            .includes(search.toLowerCase());
          if (
            (check = option.component.component_title
              .toLowerCase()
              .includes(search.toLowerCase()))
          ) {
            return true;
          }
        });
      }
    });
    return check;
  };

  const checkTags = (data, search) => {
    const filteredData = data.filter((item) => {
      return item.product?.tags
        ?.toLowerCase()
        .replace(/[^a-zA-Z0-9]/g, '')
        .includes(search.replace(/[^a-zA-Z0-9]/g, ''));
    });
    if (filteredData.length != 0) {
      return true;
    }
    return false;
  };

  const handleSearch = (value) => {
    if (value.includes('notebook')) {
      setSearch('laptop');
    } else if (
      value
        .toLowerCase()
        .replace(/[^a-zA-Z0-9]/g, '')
        .includes('samedaydelivery')
    ) {
      setSearch('samedayshipping');
    } else if (
      value
        .toLowerCase()
        .replace(/[^a-zA-Z0-9]/g, '')
        .includes('sff')
    ) {
      setSearch('itx');
    } else {
      setSearch(value);
    }
  };

  return (
    <>
      <div className="hamburger_menu">
        {isAnnouncementBarOpen && (
          <div className="header_menu_banner wrapper">
            <div className="shipping-banner">
              {topBannerTimerContents ? (
                <div className="banner-with-timer">
                  <span>{topBannerContents}</span>
                  <BannerCountdownTimer
                    date={topBannerTimerContents}
                    handleCloseBanner={handleCloseBanner}
                    hideBannerTimerRunsOut={hideBannerTimerRunsOut}
                  />
                  {/* <Countdown
                    renderer={renderer}
                    date={new Date(topBannerTimerContents)}
                  /> */}
                </div>
              ) : (
                topBannerContents
              )}
              <div
                className="close_banner"
                aria-hidden="false"
                onClick={handleCloseBanner}
              >
                <Image
                  draggable={false}
                  src="https://cdn.shopify.com/s/files/1/0522/3320/7988/files/icon-close.png?v=1714038756"
                  alt="close icon"
                  width={16}
                  height={16}
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        )}
        <div
          className={`menu-header wrapper ${
            isMenuOpen ? `menu_open` : `menu_close`
          }`}
        >
          {openSearch && (
            <div className="mobile-wide-search-bar-container">
              <div className="search-aftershock-logo">
                <Image
                  draggable={false}
                  src={
                    'https://cdn.shopify.com/s/files/1/0522/3320/7988/files/header_logo.svg?v=1713800797'
                  }
                  alt="Aftershock logo"
                  width={130}
                  height={30}
                  layout="fixed"
                />
                <button
                  className="close-search"
                  onClick={() => setOpenSearch(false)}
                >
                  X
                </button>
              </div>
              <input
                className="wide-search-bar"
                placeholder="Search..."
                onFocus={() => getAllElementsInSearch()}
                onChange={(e) => handleSearch(e.target.value)}
              />
              <div className="search-result-con">
                {loadingSearch ? (
                  <div className="loading-search">
                    <p className="loading-text">Loading</p>
                    <ThreeDots
                      height="25"
                      width="25"
                      radius="9"
                      color="#950610"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={loadingSearch}
                    />
                  </div>
                ) : (
                  search != '' && (
                    <div className="search-result-con" id="scroll">
                      {loadingSearch ? (
                        <div className="loading-search">
                          <p className="loading-text">Loading</p>
                          <ThreeDots
                            height="25"
                            width="25"
                            radius="9"
                            color="#950610"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={loadingSearch}
                          />
                        </div>
                      ) : (
                        search != '' &&
                        (allPcRtsProducts.filter((item) =>
                          item.product?.title
                            ?.replace(/[^a-zA-Z0-9]/g, '')
                            .toLowerCase()
                            .includes(
                              search.replace(/[^a-zA-Z0-9]/g, '').toLowerCase(),
                            ),
                        ).length == 0 &&
                        allPcRtsProducts.filter((item) =>
                          item.model_title
                            ?.replace(/[^a-zA-Z0-9]/g, '')
                            .toLowerCase()
                            .includes(
                              search.replace(/[^a-zA-Z0-9]/g, '').toLowerCase(),
                            ),
                        ).length == 0 &&
                        allPcRtsProducts.filter((item) =>
                          item.title1
                            ?.replace(/[^a-zA-Z0-9]/g, '')
                            .toLowerCase()
                            .includes(
                              search.replace(/[^a-zA-Z0-9]/g, '').toLowerCase(),
                            ),
                        ).length == 0 &&
                        allPcRtsProducts.filter((item) =>
                          item.seo_title
                            ?.replace(/[^a-zA-Z0-9]/g, '')
                            .toLowerCase()
                            .includes(
                              search.replace(/[^a-zA-Z0-9]/g, '').toLowerCase(),
                            ),
                        ).length == 0 &&
                        allPcRtsProducts.filter((item) =>
                          item.subtitle
                            ?.replace(/[^a-zA-Z0-9]/g, '')
                            .toLowerCase()
                            .includes(
                              search.replace(/[^a-zA-Z0-9]/g, '').toLowerCase(),
                            ),
                        ).length == 0 &&
                        !checkGraphicsCard(allPcRtsProducts, search) &&
                        !checkCpu(allPcRtsProducts, search) &&
                        !checkTags(allPcRtsProducts, search) ? (
                          <div>
                            <p>No result found</p>
                          </div>
                        ) : (
                          allPcRtsProducts.map((item) => {
                            const checkPcModel = item?.modelOptionGroup?.filter(
                              (options) =>
                                options?.optionGroup?.option_group_title ==
                                'Graphics Card',
                            );
                            const checkPcModelCpu = item?.cpuGroup?.filter(
                              (options) =>
                                options?.optionGroup?.option_group_title ==
                                'CPU',
                            );

                            if (
                              item.product?.title
                                ?.replace(/[^a-zA-Z0-9]/g, '')
                                .toLowerCase()
                                .includes(
                                  search
                                    .replace(/[^a-zA-Z0-9]/g, '')
                                    .toLowerCase(),
                                ) ||
                              item.model_title
                                ?.replace(/[^a-zA-Z0-9]/g, '')
                                .toLowerCase()
                                .includes(
                                  search
                                    .replace(/[^a-zA-Z0-9]/g, '')
                                    .toLowerCase(),
                                ) ||
                              item.title1
                                ?.replace(/[^a-zA-Z0-9]/g, '')
                                .toLowerCase()
                                .includes(
                                  search
                                    .replace(/[^a-zA-Z0-9]/g, '')
                                    .toLowerCase(),
                                ) ||
                              item.seo_title
                                ?.replace(/[^a-zA-Z0-9]/g, '')
                                .toLowerCase()
                                .includes(
                                  search
                                    .replace(/[^a-zA-Z0-9]/g, '')
                                    .toLowerCase(),
                                ) ||
                              item.subtitle
                                ?.replace(/[^a-zA-Z0-9]/g, '')
                                .toLowerCase()
                                .includes(
                                  search
                                    .replace(/[^a-zA-Z0-9]/g, '')
                                    .toLowerCase(),
                                ) ||
                              item.product?.tags
                                ?.toLowerCase()
                                .replace(/[^a-zA-Z0-9]/g, '')
                                .includes(
                                  search.replace(/[^a-zA-Z0-9]/g, ''),
                                ) ||
                              item.buildType
                                ?.toLowerCase()
                                .includes(search.replace(/[^a-zA-Z0-9]/g, ''))
                            ) {
                              const productImage = item.product?.image?.src
                                ? item.product?.image?.src
                                : item.thumb_image
                                ? item.thumb_image
                                : item.thumbnail?.url;
                              const productTitle = item.product?.title
                                ? item.product?.title?.split('/')
                                : item.model_title
                                ? item.model_title
                                : item.title1;
                              const productDescription = item.product?.body_html
                                ? item.product?.body_html?.replace(
                                    /<[^>]*>/g,
                                    '',
                                  )
                                : item.seo_description;
                              return (
                                <a
                                  href={
                                    item.product?.handle
                                      ? `/products/${item.product?.handle}`
                                      : item.slug
                                      ? `/pc-models/${item.slug}`
                                      : item.link
                                  }
                                  className="search-section"
                                  onClick={() =>
                                    (window.location.href = item.product?.handle
                                      ? `/products/${item.product?.handle}`
                                      : `/pc-models/${item.slug}`)
                                  }
                                >
                                  <div className="search-image">
                                    <img
                                      draggable="false"
                                      width={'100%'}
                                      src={productImage}
                                    />
                                  </div>
                                  <div className="search-description">
                                    <h1 className="product-title">
                                      {item?.product?.title
                                        ? productTitle[0]
                                        : productTitle}
                                    </h1>
                                    <h1 className="product-subtitle">
                                      {item?.product?.title
                                        ? productTitle[1]
                                        : item?.seo_title
                                        ? item?.seo_title
                                        : item?.subtitle?.replace(
                                            /\bquotation\b/g,
                                            '',
                                          )}
                                    </h1>
                                    <div className="product-description">
                                      {productDescription}
                                    </div>
                                  </div>
                                </a>
                              );
                            } else if (
                              checkPcModel != undefined ||
                              checkPcModelCpu != undefined
                            ) {
                              const check = checkPcModel[0]?.options?.map(
                                (option) => {
                                  if (
                                    option.component.component_title
                                      .toLowerCase()
                                      .includes(search.toLowerCase())
                                  ) {
                                    return true;
                                  }
                                },
                              );
                              const hasTrueValue = check?.includes(true);

                              const checkCpu = checkPcModelCpu[0]?.options?.map(
                                (option) => {
                                  if (
                                    option.component.component_title
                                      .replace(/[^a-zA-Z0-9]/g, '')
                                      .toLowerCase()
                                      .includes(
                                        search
                                          .replace(/[^a-zA-Z0-9]/g, '')
                                          .toLowerCase(),
                                      )
                                  ) {
                                    return true;
                                  }
                                },
                              );
                              const hasTrueValueCpu = checkCpu?.includes(true);

                              if (hasTrueValue || hasTrueValueCpu) {
                                const productImage = item.thumb_image;
                                const productTitle = item.model_title;
                                const productDescription = item.seo_description;
                                return (
                                  <a
                                    href={`/pc-models/${item.slug}`}
                                    className="search-section"
                                    onClick={() =>
                                      (window.location.href = `/pc-models/${item.slug}`)
                                    }
                                  >
                                    <div className="search-image">
                                      <img
                                        draggable="false"
                                        width={'100%'}
                                        src={productImage}
                                      />
                                    </div>
                                    <div className="search-description">
                                      <h1 className="product-title">
                                        {productTitle}
                                      </h1>
                                      <h1 className="product-subtitle">
                                        {item.seo_title}
                                      </h1>
                                      <div className="product-description">
                                        {productDescription}
                                      </div>
                                    </div>
                                  </a>
                                );
                              }
                            }
                          })
                        ))
                      )}
                    </div>
                  )
                )}
              </div>
            </div>
          )}

          <div className="menu_icon">
            <div className="menu_icon">
              <Logo width={148} height={32} />
            </div>
          </div>

          <div className="menu_icon">
            <div className="right_icons">
              <button
                className="search-icon"
                onClick={() => {
                  handleSearch('');
                  setOpenSearch(!openSearch);
                }}
              >
                <Image
                  draggable={false}
                  src="https://cdn.shopify.com/s/files/1/0522/3320/7988/files/icon-search-gray.png?v=1713800848"
                  alt="icon-search"
                  width={15}
                  height={15}
                  loading="lazy"
                />
              </button>

              <DrawerButtons
                isLoggedIn={isLoggedIn}
                setIsLoggedIn={setIsLoggedIn}
              />

              <div
                type="button"
                className="icons icons_container"
                onClick={handleCartOpen}
                aria-hidden="false"
              >
                <Image
                  draggable={false}
                  src="https://cdn.shopify.com/s/files/1/0522/3320/7988/files/icon-cart.svg?v=1713797761"
                  alt="cart icon"
                  layout="fixed"
                  width={24}
                  height={24}
                />
                <span>
                  {checkoutId && <CartCounter checkoutId={checkoutId} />}
                </span>
              </div>

              <div id="iconMenu">
                <input
                  type="checkbox"
                  checked={isChecked}
                  id="toggle"
                  onClick={() => (isChecked ? closeMenu() : openMenu())}
                />
                <label for="toggle"></label>
              </div>

              {/* <div style={{"display":"none","justifyContent":"center","alignItems":"center"}}>
								{isMenuOpen ? (
									<Image draggable={false}
										src="https://cdn.shopify.com/s/files/1/0522/3320/7988/files/icon-close.png?v=1714038756"
										alt="close icon"
										width={24}
										height={24}
										onClick={toggleMenu}
										loading="lazy"
									/>
								) : (
									<Image draggable={false} 
										src="/icons/icon-hamburger.svg"
										alt="close icon"
										width={24}
										height={24}
										onClick={toggleMenu}
										loading="lazy"
									/>
								)}
							</div> */}
            </div>
          </div>
        </div>
      </div>

      <style jsx>{styles}</style>
    </>
  );
};

MobileHeader.propTypes = {
  toggleMenu: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  header: PropTypes.any,
};

MobileHeader.defaultProps = {
  toggleMenu: null,
  header: null,
};

export default MobileHeader;
