import breakPoints from 'helpers/Styles';
import Slider from 'react-slick';
import React, { useState, useEffect } from 'react';
import Link from 'next/link';

const RangeSelected = ({ rangeData }) => {
  const [slidesShowNumber, setSlidesShowNumber] = useState(5);

  useEffect(() => {
    if (rangeData?.length > 5) {
      setSlidesShowNumber(5);
    }
    if (rangeData?.length > 0 && rangeData?.length < 5) {
      setSlidesShowNumber(rangeData.length);
    }
  }, [rangeData]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: slidesShowNumber,
    slidesToScroll: slidesShowNumber,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: slidesShowNumber,
          slidesToScroll: slidesShowNumber,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          className: 'center',
        },
      },
    ],
  };

  return (
    <div className="ranges_carousel_slice wrapper">
      <Slider {...settings}>
        {rangeData.map((item, index) => (
          <div className="ranges_carousel" key={index}>
            <div className="ranges_carousel_container">
              <div className="range_information_block">
                <div>
                  <div className="range_image">
                    {item?.product_image?.url && (
                      <Link legacyBehavior href={item.product_link || '/'}>
                        <a href={item.product_link || '/'}>
                          <img draggable="false"
                            src={item.product_image.url}
                            alt="product image"
                          />
                        </a>
                      </Link>
                    )}
                  </div>
                  {item?.product_title && item?.product_link ? (
                    <Link legacyBehavior href={item.product_link}>
                      <a href={item.product_link} className="product_link">
                        {item.product_title}
                      </a>
                    </Link>
                  ) : (
                    <p className="product_link">{item.product_title}</p>
                  )}
                  {item?.product_description && (
                    <p className="description">{item.product_description}</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>

      <style jsx>
        {`
          .ranges_carousel_slice {
            overflow: hidden;
          }
          .ranges_carousel_container {
            display: flex;
            padding: 30px;
          }
          .range_information_block {
            text-align: center;
          }
          .ranges_carousel {
            width: 100%;
            display: flex !important;
            justify-content: center;
          }
          .range_information_block .description {
            color: #949494;
            font-family: 'Roboto-Medium';
            font-size: 12px;
            letter-spacing: 0;
            line-height: 12px;
            text-align: center;
          }
          .range_image {
            display: flex;
            justify-content: center;
            margin-bottom: 15px;
          }
          .range_image img {
            height: 160px;
            width: 160px;
            border-radius: 50%;
            border: 1px solid #949494;
          }
          .product_link:hover {
            color: #fd0300;
          }
        `}
      </style>
    </div>
  );
};
export default RangeSelected;
