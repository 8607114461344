import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import CarouselProgressPagination from './CarouselProgressPagination';
import Image from 'next/image';
import CarouselReviewPagination from './CarouselReviewPagination';
import ReviewsIoCarouselWidget from './ReviewsIoCarouselWidget';

function SmallReviewsCarousel({ block }) {
	const slidesShowNumber = 6;
	const [currentDot, setCurrentDot] = useState(1);
	const [pageCount, setPageCount] = useState(1);
	const [totalSlidesShowing, setTotalSlidesShowing] = useState(1);
	const [screenWidth, setScreenWidth] = useState(null);
	const populateCarousel = () => {
		let postArray = [];
		if (block?.items?.length <= slidesShowNumber) {
			for (let i = 0; i <= slidesShowNumber; i++) {
				postArray.push(...block?.items);
			}
			postArray = postArray.slice(0, slidesShowNumber * 2);
		} else {
			postArray = block?.items;
		}
		return postArray;
	};
	const onBeforeChange = (currentSlide, nextSlide) => {
		// console.log({
		//   screenWidth,
		//   nextSlide,
		//   pageCount,
		//   currentDot,
		//   totalSlidesShowing,
		// });
		if (screenWidth > 428) {
			const lastPageSlide =
				pageCount * (totalSlidesShowing - 1) - (totalSlidesShowing - 1);
			if (nextSlide === 0) {
				setCurrentDot(1);
				return;
			}
			if (nextSlide === lastPageSlide) {
				setCurrentDot(pageCount);
				return;
			}
			if (nextSlide === 6) {
				setCurrentDot(2);
				return;
			}
		} else {
			setCurrentDot(nextSlide + 1);
		}
	};

	const reviewsData = screenWidth > 428 ? populateCarousel() : block?.items;

	const [slideIndex, setSlideIndex] = useState(0);

	const settings = {
		beforeChange: (current, next) => {
			setSlideIndex(next);
		},
		dots: false,
		infinite: false,
		speed: 500,
		slidesToShow: slidesShowNumber,
		slidesToScroll: slidesShowNumber,
		variableWidth: true,
		nextArrow: (
			<CustomCarouselArrow
				src="https://cdn.shopify.com/s/files/1/0522/3320/7988/files/arrow-right-next.png?v=1713800470"
				alt="arrow-right"
			/>
		),
		prevArrow: (
			<CustomCarouselArrow
				src="https://cdn.shopify.com/s/files/1/0522/3320/7988/files/arrow-left-prev.png?v=1713800296"
				alt="arrow-left"
				rightPosition={40}
			/>
		),
		responsive: [
			{
				breakpoint: 1440,
				settings: {
					slidesToShow: slidesShowNumber,
					slidesToScroll: slidesShowNumber,
					infinite: 3,
					dots: true,
				},
			},
			{
				breakpoint: 1300,
				settings: {
					slidesToShow: 5,
					slidesToScroll: 5,
					infinite: 3,
					dots: true,
				},
			},
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 4,
					infinite: 3,
					dots: true,
				},
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
				},
			},
			{
				breakpoint: 640,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 428,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};

	useEffect(() => {
		const width = window.innerWidth;
		const totalDots = Math.ceil(reviewsData?.length / slidesShowNumber);
		const slidesShowing = document.querySelectorAll(
			'#large-reviews-carousel .slick-active',
		)?.length;
		setPageCount(screenWidth > 428 ? totalDots : reviewsData?.length);
		setTotalSlidesShowing(slidesShowing);
		setScreenWidth(width);
	}, [reviewsData, slideIndex]);

	const getMaxValue = (data) => {
		let max = 0;
		if (data != null) {
			max =
				(data.length / 5) * 5 - (5 - ((data.length / 5) * 5 - data.length)) + 5;
			return max - 6;
		} else {
			return max;
		}
	};	

	return (
		<>
			<div className="main">
				<div className="container">
					<div className="title">
						{block.primary.slice_label == null
							? '3 countries. Over 150,000 happy customers'
							: block.primary.slice_label}
					</div>
					<div id="small-reviews-carousel" className="carousel">

						<ReviewsIoCarouselWidget />

						{/* <Slider {...settings}>
							{reviewsData?.map((item, idx) => {
								const result = {
									username: item.username,
									rating: item.rating,
									title: item.review_title,
									message: item.review_content,
									rig: item.rig,
									imageURL: item.thumbnail?.url,
									firstName: item.first_name,
									instagramLink: item.instagram_link.url,
								};
								return <CarouselItem item={result} key={idx} />;
							})}
						</Slider>

						<CarouselReviewPagination
							slideIndex={slideIndex}
							maxValue={screenWidth > 700 ? getMaxValue(reviewsData) : block?.items.length - 1}
						/> */}	

						{/* <CarouselProgressPagination
              currentDot={currentDot}
              totalDots={screenWidth > 428 ? pageCount : block?.items.length}
            /> */}
					</div>
				</div>
			</div>
			<style jsx>{`
				.range-field-container {
					display: flex;
					justify-content: center;
					align-items: center;
					margin-top: 3%;
				}
				.carousel {
					margin-top: 32px;
				}
				.container {
					position: relative;
					max-width: 1440px;
					padding: 40px 48px;
					margin: 0 auto;
				}
				.title {
					font-family: 'big_noodle_titling';
					font-size: 39px;
					font-weight: 400;
					line-height: 48px;
					letter-spacing: 0em;
					text-align: left;
					color: #e4e4e4;
				}
				@media (max-width: 991px) {
					.container {
						padding: 32px 16px;
					}
					.title {
						font-size: 26px;
						font-weight: 400;
						line-height: 32px;
						letter-spacing: 0em;
						text-align: left;
						width: 251px;
					}
				}
			`}</style>
		</>
	);
}

function CarouselItem({ item }) {
	const renderStars = () => {
		const arrayElements = [];
		const ratingValue = item?.rating ? item.rating[0] : 0;
		for (var x = 0; x < ratingValue; x++) {
			arrayElements.push(<Star key={x} />);
		}
		return arrayElements;
	};

	return (
		<>
			<div className="card">
				<div className="image">
					<Image draggable={false} 
						src={item?.imageURL}
						alt={item?.title || 'Aftershock PC'}
						width="251px"
						height="251px"
						loading="lazy"
					/>
				</div>
				<div className="rate">
					<div className="stars_container">{renderStars()}</div>
					{item.username && <div className="username">@{item.username}</div>}
				</div>
				{item.title && (
					<div className="review_content">
						<div className="title">
							{item.instagramLink ? (
								<a href={`${item.instagramLink || '#'}`} target="_blank">
									{item.title}
								</a>
							) : (
								item.title
							)}
						</div>
						<div className="message">{item.message}</div>
					</div>
				)}
				{item.firstName && (
					<div className="usage">
						<div className="user">{item.firstName} uses the</div>
						<div className="rig_name">{item.rig}</div>
					</div>
				)}
			</div>
			<style jsx>{`
				.stars_container {
					display: flex;
				}
				.usage {
					margin-top: 8px;
				}
				.rig_name {
					font-family: 'Roboto-Medium';
					font-size: 13px;
					font-weight: 400;
					line-height: 20px;
					letter-spacing: 0em;
					text-align: left;
					color: #fd0300;
				}
				.user {
					font-family: 'Roboto-Medium';
					font-size: 13px;
					font-weight: 400;
					line-height: 20px;
					letter-spacing: 0em;
					text-align: left;
					color: #e4e4e4;
				}
				.image {
					// background: url('${item.imageURL}');
					// background-position: center;
					// background-size: cover;
					// background-repeat: no-repeat;
					width: 192px;
					height: 192px;
					border-radius: 4px;
				}
				.rate {
					margin-top: 8px;
					display: flex;
					justify-content: space-between;
					align-items: center;
				}
				.username {
					font-family: 'Roboto-Medium';
					font-size: 13px;
					font-weight: 400;
					line-height: 20px;
					letter-spacing: 0em;
					text-align: right;
					color: #e4e4e4;
				}
				.review_content {
					margin-top: 8px;
				}
				.title {
					font-family: 'Roboto-Medium';
					font-size: 13px;
					font-weight: 600;
					line-height: 20px;
					letter-spacing: 0em;
					text-align: left;
					color: #e4e4e4;
					flex: none;
					order: 3;
					flex-grow: 0;
				}
				.message {
					font-family: 'Roboto-Medium';
					font-size: 13px;
					font-weight: 400;
					line-height: 20px;
					letter-spacing: 0em;
					text-align: left;
					color: #949494;
					flex: none;
					order: 3;
					flex-grow: 0;
					margin-top: 8px;
				}
				@media (max-width: 640px) {
					.image {
						width: 251px;
						height: 251px;
					}
				}
			`}</style>
		</>
	);
}

function Star() {
	return (
		<>
			<img draggable="false"
				src="https://cdn.shopify.com/s/files/1/0522/3320/7988/files/star-filled.svg?v=1713800387"
				className="star_rating"
				alt="star-rating"
			/>
			<style jsx>{`
				.star_rating {
					width: 16px;
					height: 16px;
				}
			`}</style>
		</>
	);
}

function CustomCarouselArrow({ src, alt, onClick, rightPosition }) {
	return (
		<>
			<button className="button" onClick={onClick}>
				<img draggable="false" src={src} alt={alt} />
			</button>
			<style jsx>{`
				.button {
					position: absolute;
					top: -57px;
					right: ${rightPosition || 0}px;
					background: none;
					border: none;
					padding: 0;
					margin: 0;
					line-height: 0;
				}
				@media (width<=991px) {
					.button {
						top: -94px;
						display: none;
					}
				}
			`}</style>
		</>
	);
}

export default SmallReviewsCarousel;
