import css from 'styled-jsx/css';

export default css`
    .third_menu {
        display: flex;        
        justify-content: center;
        align-items: center;
    }
    .third_menu_container {
        width: 100%;
        padding: 30px 0 0 0;
        display: flex;
        flex-flow: row wrap;
        max-width: 1440px;
        margin: 0 auto;
    }
    @media (min-width: 991px) {
        .third_menu {
            padding-left: 48px;
            padding-right: 48px;
        }
    }
    @media (max-width: 992px) {
        .third_menu {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
    
    @media (min-width: 1920px) {
        .third_menu {
            padding-left: 0;
            padding-right: 0;
        }
    }
`;
