import breakPoints from 'helpers/Styles';
import { Carousel } from 'react-responsive-carousel';
import Image from 'next/image';

const ValuePropBlock = ({ block }) => {
  if (!block) return '';

  const threeColumnsMapping = block.items.map((item) => (
    <div key={item?.value_prop_title} className="item">
      <div className="row_container">
        {item?.value_prop_image && (
          <div className="row_image">
            <Image draggable={false} 
              src={item?.value_prop_image.url}
              alt="Picture of the value"
              width={208}
              height={208}
            />
          </div>
        )}

        <div className="row_image_mobile">
          {item?.value_prop_image.url && (
            <Image draggable={false} 
              src={item?.value_prop_image.url}
              alt="Picture of the value"
              width={168}
              height={168}
            />
          )}
        </div>
        <div>
          <h4>{item?.value_prop_title}</h4>
          <p>{item?.value_prop_contents}</p>
        </div>
      </div>
      <style jsx>
        {`
          .item {
            width: 32%;
            margin-top: 80px;
          }
          .row_container {
            width: 100%;
            height: 100%;
            padding: 32px 16px;
            background: #141414;
            box-sizing: border-box;
            color: white;
            margin: 0 auto;
          }
          .row_container h4 {
            color: #e4e4e4;
            font-family: 'Roboto-Medium';
            font-size: 20px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 24px;
            margin-bottom: 10px;
            text-align: center;
          }
          .row_container p {
            color: #949494;
            font-family: 'Roboto-Medium';
            font-size: 13px;
            letter-spacing: 0;
            line-height: 20px;
            padding: 0;
            margin: 0;
            margin-top: 8px;
            text-align: center;
          }
          .row_image {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-top: -180px;
          }
          .row_icon {
            width: 70px;
          }
          .row_image_mobile {
            display: none;
          }
          @media (max-width: ${breakPoints.medium}) {
            .three_columns_container {
              display: block;
            }
            .item {
              width: 100%;
            }
            .row_container {
              width: 95%;
              height: 120%;
              overflow: visible;
              margin: 0 auto;
              margin-bottom: 30px;
            }
            .row_image {
              top: -40% !important;
              z-index: 15;
            }
            .row_image_mobile {
              display: none;
            }
          }
          @media (max-width: 460px) {
            .item {
              width: 100%;
            }
            .row_image {
              display: none;
            }
            .row_image_mobile {
              width: 100%;
              display: flex;
              justify-content: center;
              margin-top: -140px;
            }
            .row_container {
              min-height: 250px !important;
            }
            .row_image {
              top: 0% !important;
              z-index: 5;
            }
          }
        `}
      </style>
    </div>
  ));

  return (
    <>
      <div className="three_columns_wrapper wrapper">{threeColumnsMapping}</div>
      <div className="three_columns_wrapper_mobile wrapper">
        <Carousel
          showStatus={false}
          showThumbs={false}
          useKeyboardArrows
          infiniteLoop
          showIndicators={false}
          centerMode
          centerSlidePercentage={75}
        >
          {threeColumnsMapping}
        </Carousel>
      </div>

      <style jsx>
        {`
          .three_columns_wrapper {
            display: flex;
            justify-content: space-between;
            width: 90%;
            margin: 0 auto;
          }
          .three_columns_wrapper_mobile {
            display: none;
          }
          @media (max-width: ${breakPoints.medium}) {
            .three_columns_wrapper {
              display: none;
            }
            .three_columns_wrapper_mobile {
              display: block;
              min-height: 400px;
              padding-top: 80px;
              overflow-x: hidden;
              overflow-y: visible;
            }
          }
          @media (max-width: ${breakPoints.small}) {
            .three_columns_wrapper_mobile {
              display: block;
            }
          }
        `}
      </style>
    </>
  );
};

export default ValuePropBlock;
