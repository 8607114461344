export const fGetLastBreadcrumb = (breadcrumbs) => {
  const breadcrumbsArray = breadcrumbs;
  const lastBreadcrumb = breadcrumbsArray
    ? breadcrumbsArray[breadcrumbsArray?.length - 1]
    : 0;
  return lastBreadcrumb;
};

export const fGetUniqueValue = (value, index, self) => {
  return self.indexOf(value) === index;
};

export const fIsValidJSON = (text) => {
  if (
    /^[\],:{}\s]*$/.test(
      text
        .replace(/\\["\\\/bfnrtu]/g, '@')
        .replace(
          /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
          ']',
        )
        .replace(/(?:^|:|,)(?:\s*\[)+/g, ''),
    )
  ) {
    //the json is ok
    return true;
  } else {
    //the json is not ok
    return false;
  }
};

export const fGetPlural = ({ number, plural, singular }) => {
  if (number > 1) {
    return number + ' ' + plural;
  } else {
    return number + ' ' + singular;
  }
};
