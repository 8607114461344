/* eslint-disable react/prop-types */
import React, { memo } from 'react';
import Router from 'next/router';
import slugify from 'slugify';
import FourthMobile from '../FourthMobile';
import ComponentsMobile from '../ComponentsMobile';

const TertiaryMobile = ({ items, closeMenu, componentItems }) => {
  Router.onRouteChangeStart = () => {
    closeMenu();
  };
  if (!items.length && !componentItems[0].items.length) {
    return null;
  }
  return (
    <>
      <div className="category_navigation_container">
        <div>
          {items &&
            items.map((item, index) => {
              const key = slugify(`tertiary-${index}`);
              return (
                <FourthMobile
                  key={key}
                  items={item}
                  closeMenu={closeMenu}
                />
              );
            })}
        </div>

        {items.length == 0 && <ComponentsMobile item={componentItems} />}
      </div>
    </>
  );
};

export default memo(TertiaryMobile);
