import PropTypes from 'prop-types';
import Link from 'next/link';
import breakPoints, { colors } from 'helpers/Styles';

const TwoImages = ({ block }) => {
  if (!block) return '';
  const {
    image1,
    link1,
    link_type1: linkType1,
    image2,
    link2,
    link_type2: linkType2,
  } = block.primary;

  return (
    <>
      <div className="two-images">
        <div className="first-image">
          {image1.url && (
            <Link legacyBehavior href={link1 || '#'}>
              <img draggable="false" src={image1.url} alt={image1.alt || 'Aftershock PC'} />
            </Link>
          )}
        </div>
        <div className="second-image">
          {image2.url && (
            <Link legacyBehavior href={link2 || '#'}>
              <img draggable="false" src={image2.url} alt={image2.alt || 'Aftershock PC'} />
            </Link>
          )}
        </div>
      </div>
      <style jsx>
        {`
          .two-images {
            display: flex;
            justify-content: space-between;
            margin: 10px 0;
            background-color: ${colors.n500};
          }
          img {
            max-width: 100%;
          }
          .first-image {
            flex: 1;
          }
          .second-image {
            flex: 1;
            text-align: right;
          }
          @media (max-width: ${breakPoints.small}) {
            .two-images {
              flex-direction: column;
            }
          }
        `}
      </style>
    </>
  );
};

TwoImages.propTypes = {
  block: PropTypes.shape({
    primary: PropTypes.shape({
      image1: PropTypes.shape({ url: PropTypes.string, alt: PropTypes.string }),
      link1: PropTypes.string,
      link_type1: PropTypes.string,
      image2: PropTypes.shape({ url: PropTypes.string, alt: PropTypes.string }),
      link2: PropTypes.string,
      link_type2: PropTypes.string,
    }),
  }),
};

TwoImages.defaultProps = {
  block: null,
};

export default TwoImages;
