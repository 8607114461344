/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unused-prop-types */
import React, { useState, memo, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import slugify from 'slugify';
import Image from 'next/image';
import Link from 'next/link';
import styles from './styles';
import ItemList from '../ItemList';
import Components from '../Components';
import ProductLists from '../ProductLists';
import ProductLinks from '../ProductLinks';
import ProductAccessories from '../ProductAccessories';
import { useRouter } from 'next/router';

const Secondary = ({
  items,
  mainMenuHoverIndex,
  parent,
  columns,
  mainMenuCurrentIndex,
  openNav,
  onClick,
  componentItems,
  secondMenu,
  thirdMenu,
  setOpenNav,
}) => {
  const parentTitle = `parent-${parent.title}`;
  let classNames = `category_navigation_container ${slugify(
    parentTitle,
  ).toLocaleLowerCase()}`;
  const [openMegaNav, setOpenMegaNav] = useState(true);
  if (mainMenuCurrentIndex === mainMenuHoverIndex && openNav && openMegaNav) {
    classNames = `${classNames} active`;
  }
  const [activeLinkIndex, setActiveLinkIndex] = useState(0);
  const [selectedMenu, setSelectedMenu] = useState(
    columns[0]?.mega_nav_column_handle || '',
  );
  const [selectedThirdMenu, setSelectedThirdMenu] = useState(null);
  // const [openThirdNav, setOpenThirdNav] = useState(false);
  const [menuItems, setMenuItems] = useState(items);
  const [thirdMenuType, setThirdMenuType] = useState(null);
  const [isHovered, setIsHovered] = useState(null);
  const thirdMenuItems = thirdMenu.filter((item) => item.primary.second_level_handle === selectedThirdMenu);
  const handleMoveOut = () => {
    setActiveLinkIndex(0);
    let menuItem = [];
    if (columns[0]) {
      menuItem = items.filter(
        (n) => n.primary.column_handle === columns[0]?.mega_nav_column_handle,
      );
      setMenuItems(menuItem);
    }
    setOpenMegaNav(true);
  };
  const handleMenuClick = (columnHandle, columnIndex) => {
    setSelectedMenu(columnHandle);
    if (selectedMenu.length > 0) {
      const selectedMenuItems = items.filter(
        (n) => n.primary.column_handle === columnHandle,
      );
      setMenuItems(selectedMenuItems);
    }
    setActiveLinkIndex(columnIndex);
  };
  const handleClickItem = () => {
    setOpenMegaNav(false);
    onClick();
  };

  const router = useRouter();
  const isWorkstations = mainMenuHoverIndex === 3;
  const handleRedirectQuotation = () => {
    router.push("/pages/corporate");
    setOpenMegaNav(false);
  };
  const handleRedirectQuiz = () => {
    router.push("/survey");
    setOpenMegaNav(false);
  };

  const checkMegaMenu = (index) => {
    const child = document.getElementById(
      'megaMenuItem-' + index + '-3rd_level',
    );
    if (child?.classList?.contains('content-center')) {
      child?.parentElement?.classList?.add('justify-content-center');
    }
  };

  useEffect(() => {
    let menuItem = [];
    if (columns[0]) {
      menuItem = items.filter(
        (n) => n.primary.column_handle === columns[0]?.mega_nav_column_handle,
      );
      setMenuItems(menuItem);
    }
  }, []);

  const handleClickNav = () => {
    setThirdMenuType(null);
    setOpenNav();
  }

  return (
    <>
      <div onMouseLeave={handleMoveOut} className={classNames}>
        <div className="category_navigation__secondary">
          {/* {columns.length > 0 && (
            <div className="category_navigation_secondary_column">
              {columns.map((column, index) => {
                const key = slugify(
                  `${parentTitle}-${column.mega_nav_column_handle}-${index}}`,
                );

                return (
                  <div className="second-level" key={key}>
                    {column.mega_nav_column_title && (
                      <div className="menu_group">
                        <div
                          onFocus={() => undefined}
                          aria-hidden="false"
                          type="button"
                          onClick={() =>
                            handleMenuClick(
                              column.mega_nav_column_handle,
                              index,
                            )
                          }
                          className={`second-level-title ${
                            activeLinkIndex === index
                              ? 'second-level-title-2'
                              : null
                          }`}
                        >
                          {column.mega_nav_column_title}
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          )} */}
          {/* {(ksps != undefined && ksps.length != 0) && (
            <div className="ksps">
              {ksps[0]?.items?.map((item, index) => {
                const key = slugify(`ksp-${index}`);
                return (
                  <div key={key}>
                    {item?.ksp_icon?.url && (
                      <div className="item_group">
                        <Image draggable={false} 
                          src={item.ksp_icon.url}
                          width={22}
                          height={22}
                          alt={item?.ksp_icon?.alt || 'ksp icon'}
                        />
                        {item.ksp_title}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          )} */}
          {secondMenu.map((item, index) => {
            const thirdMenuHandle = item.primary.third_level_handle;
            const thirdMenuType = item.primary.third_level_type;
            return (
              <Link legacyBehavior href={item.primary.link}>
                <a href={item.primary.link}>
                  <div className='second-menu-item'
                    onMouseOver={() => {
                      setSelectedThirdMenu(thirdMenuHandle);
                      setThirdMenuType(thirdMenuType);
                      setIsHovered(index);
                    }}
                    onMouseLeave={() => {
                      setThirdMenuType(null);
                      setIsHovered(null);
                    }}
                    onMouseEnter={() => setThirdMenuType(thirdMenuType)}
                    onClick={() => handleClickNav()}
                  >
                    <div className='second-menu-item-image'>
                      <img
                        className=''
                        src={item.primary.image.url}
                      />
                    </div>
                    <div 
                      className={`second-menu-item-title ${isHovered === index ? 'second-menu-item-title-hovered' : ''}`}
                    >
                      {item.primary.title}
                    </div>
                    <div  className='second-menu-item-subtitle'>{item.primary.subtitle}</div>
                  </div>
                </a>
              </Link>
            )
          })}
          {/* <div
            className="wrapper item-list"
            style={
              (classNames.includes('parent-ready-to-ship') || classNames.includes('parent-contact-us'))
                ? { justifyContent: 'center' }
                : {}
            }
          >
            {menuItems.map((item, index) => {
              const key = slugify(`menuItem-${index}`);
                return (
                  <div
                    id={`megaMenuItem-${index}-${
                      item?.slice_type === '3rd_level' && '3rd_level'
                    }`}
                    className={`item-list-item ${
                      item?.slice_type === '3rd_level' && 'content-center'
                    }`}
                    key={key}
                    style={classNames.includes('parent-workstations') ? {flex: '0 0 24%'} : {}}
                  >
                    <ItemList
                      onClick={handleClickItem}
                      index={index}
                      checkMegaMenu={checkMegaMenu}
                      item={item}
                    />
                  </div>
                );
            })}

            {componentItems.length > 0 &&
              <Components 
                item={componentItems}
              />
            }
          </div> */}
          {/* {quizItems && (
            <div className="quiz-wrapper">
              <div className="quiz">
                {quizItems?.[0]?.primary?.quiz_image?.url && (
                  <Image draggable={false} 
                    src={quizItems[0].primary.quiz_image.url}
                    width={
                      quizItems?.[0]?.primary?.quiz_image?.dimensions?.width
                    }
                    height={
                      quizItems?.[0]?.primary?.quiz_image?.dimensions?.height
                    }
                    alt={
                      quizItems?.[0]?.primary?.quiz_image?.alt || 'RIG FINDER'
                    }
                  />
                )}
                {quizItems?.[0]?.primary?.title && (
                  <div className="quiz_title">{quizItems[0].primary.title}</div>
                )}

                {quizItems?.[0]?.primary?.link && (
                  <Link type="button" href={quizItems[0].primary.link} passHref>
                    <div className="quiz_button">TAKE THE QUIZ</div>
                  </Link>
                )}
              </div>
            </div>
          )} */}
        </div>
        {thirdMenuItems.length > 0 && thirdMenuType === 'product lists' && (
          <div
          className='third_level_lists'
            onMouseEnter={() => setThirdMenuType(thirdMenuType)}
          >
            <div className='lists'>
              <ProductLists
                thirdMenuItems={thirdMenuItems}
                handleClickNav={() => handleClickNav()}
                parent={parent}
              />
            </div>
          </div>
        )}
        {thirdMenuItems.length > 0 && thirdMenuType === 'product links' && (
          <div
          className='third_level_links'
            onMouseEnter={() => setThirdMenuType(thirdMenuType)}
          >
           <div className='links'>
            <ProductLinks
              thirdMenuItems={thirdMenuItems}
              handleClickNav={() => handleClickNav()}
            />
           </div>
        </div>
        )}
        {thirdMenuItems.length > 0 && thirdMenuType === 'no 3d level' && (
          <div
            className='third_level_links'
            onMouseEnter={() => setThirdMenuType(thirdMenuType)}
          >
          <div className='links'>
            <ProductAccessories
              thirdMenuItems={thirdMenuItems}
              handleClickNav={() => handleClickNav()}
            />
          </div>
        </div>
        )}
        {isWorkstations && (
          <div className="cta-wrapper">
            <div className="cta">
              <div className='cta_image_container'>
                <img 
                  className='cta_image' 
                  src='https://cdn.shopify.com/s/files/1/0522/3320/7988/files/Get_a_Quotation_2_1.png?v=1714382725' 
                  alt='qutation' 
                  width={85} 
                  height={78}
                />
                <div className='cta_image_title'>GET A<br/>QUOTATION</div>
              </div>
              <div className="cta_title">
                Ready to elevate your workstation experience? Click now to request a personalized<br/>
                quote for your custom workstation build!
              </div>
              <div onClick={handleRedirectQuotation}>
                <div className="cta_button">GET A QUOTATION</div>
              </div>
            </div>
          </div>
        )}
        {!isWorkstations && (
          <div className="cta-wrapper">
            <div className="cta">
              <div className='cta_image_container'>
                <img 
                  style={{ marginRight: '18px' }}
                  className='cta_image' 
                  src='https://cdn.shopify.com/s/files/1/0522/3320/7988/files/Rectangle_454.png?v=1714382939' 
                  alt='qutation' 
                  width={50} 
                  height={50}
                />
                <div className='cta_image_title'>Aftershock<br/>Quiz</div>
              </div>
              <div className="cta_title">
                Unsure of which PC is the right one for you? Take our Aftershock Quiz to determine<br/>
                which PC is most Suited to your needs
              </div>
              <div onClick={handleRedirectQuiz}>
                <div className="cta_button">TAKE THE QUIZ</div>
              </div>
            </div>
          </div>
        )}
      </div>

      <style jsx>{`
          .category_navigation__secondary {
          }
        @keyframes appearAnimationLists {
          0% {
            max-height: 0;
          }
          60% {
            max-height: 497px;
          }
          100% {
            max-height: 480px;
          }
        }
        .third_level_lists {
          animation: appearAnimationLists 0.7s ease-in-out forwards;
        }
        @keyframes appearLists {
          0% {
            opacity: 0;
            transform: translateY(-100%);
          }
          25% {
            opacity: 0;
          }
          60% {
            opacity: 1;
            transform: translateY(3%);
          }
          100% {
            opacity: 1;
            transform: translateY(0%);
          }
        }
        .lists {
          opacity: 0;
          animation: appearLists 0.7s ease-in-out forwards;
        }

        @keyframes appearAnimationLinks {
          0% {
            max-height: 0;
          }
          60% {
            max-height: 275px;
          }
          100% {
            max-height: 260px;
          }
        }
        .third_level_links {
          animation: appearAnimationLinks 0.7s ease-in-out forwards;
        }
        @keyframes appearLinks {
          0% {
            opacity: 0;
            transform: translateY(-100%);
          }
          15% {
            opacity: 0;
          }
          60% {
            opacity: 1;
            transform: translateY(5%);
          }
          100% {
            opacity: 1;
            transform: translateY(0%);
          }
        }
        .links {
          opacity: 0;
          animation: appearLinks 0.7s ease-in-out forwards;
        }
      `}</style>
      <style jsx>{styles}</style>
    </>
  );
};

Secondary.propTypes = {
  items: PropTypes.array,
  mainMenuHoverIndex: PropTypes.number,
  mainMenuCurrentIndex: PropTypes.number,
  kspsItems: PropTypes.array,
  quizItems: PropTypes.array,
  openNav: PropTypes.bool,
  columns: PropTypes.any.isRequired,
  parent: PropTypes.any.isRequired,
  onClick: PropTypes.func,
};

Secondary.defaultProps = {
  items: [],
  mainMenuHoverIndex: null,
  mainMenuCurrentIndex: null,
  kspsItems: [],
  quizItems: [],
  openNav: false,
  onClick: undefined,
};

export default memo(Secondary);
