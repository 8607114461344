import ChatPingingIconButton from 'components/NavigationButtonIcons/ChatPingingIconButton';
import React from 'react';

function SidePanelSubButton({
  iconSrc,
  width,
  height,
  text,
  onClick,
  chevron,
  opacity,
  isChatPingingIcon,
}) {
  return (
    <>
      <button className="side_panel_sub_button" onClick={onClick}>
        {iconSrc && (
          <div className='side_panel_sub_image'>
            <img 
              src={iconSrc}
              alt={'icon side panel'}
            />
          </div>
        )}
        <div className="icon_text">
          <span className="button_text">{text}</span>
        </div>
      </button>

      <style jsx>{`
        .icon {
          width: 36px;
          height: 16px;
          text-align: center;
          margin-left: -6px;
          position: relative;
        }
        .icon_text {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 16px;
        }
        .no_icon {
          height: 16px;
          width: 16px;
        }
        .side_panel_sub_button {
          padding: 0;
          background: transparent;
          border: none;
          width: 100%;
          margin-bottom: 15px;
          text-align: left;
          display: flex;
          align-items: center;
          justify-content: flex-start
        }
        .side_panel_sub_image {
          height: 22px;
          width: 22px;
        }
        .side_panel_sub_image img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
        .button_text {
          font-family: 'Roboto-Medium';
          font-size: 15px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
          text-decoration: underline;
          text-align: left;
          color: #ffffff;
          text-transform: none;
          margin-left: 20px;
        }
        .button_text:hover {
          text-decoration: none;
        }
        .ping {
          animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
        }
        @keyframes ping {
          75%,
          100% {
            transform: scale(2);
            opacity: 0;
          }
        }
        .chat_badge {
          background: #7ed321;
          height: 8px;
          width: 8px;
          border-radius: 50%;
          position: absolute;
          top: -2px;
          right: 5px;
        }
      `}</style>
    </>
  );
}

export default SidePanelSubButton;
