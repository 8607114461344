/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unused-prop-types */
import React, { memo } from 'react';
import styles from './styles';
import Image from 'next/image';
import Link from 'next/link';

const ProductAccessories = ({
    thirdMenuItems,
    handleClickNav,
}) => {
  return (
    <>
        <div  className='third_menu_container'>
            <div className='third_menu_accessories'>
                <div className='third_menu_accessories_title'>
                    {thirdMenuItems[0].primary.topic_title}
                </div>
                <div className='third_menu_banner'>
                    <div className='third_menu_products'>
                        {thirdMenuItems[0].items.map((item) => (
                            <Link href={item.link}>
                                <a href={item.link}>
                                    <div className='third_menu_product'>
                                        <Image
                                            src={item.model_image.url}
                                            width={80} 
                                            height={80} 
                                        />
                                        <div className='third_menu_title'>{item.title}</div>
                                    </div>
                                </a>
                            </Link>
                        ))} 
                    </div>
                    <div className='third_menu_seeMore'>
                        <Link href={'/collections/accessories'}>
                            <a  href={'/collections/accessories'}>
                                <div className='third_menu_link'>
                                    See more{' >'}
                                </div>
                            </a>
                        </Link>
                    </div>
                </div>
            </div>
        </div>

      <style jsx>{styles}</style>
    </>
  );
};

export default memo(ProductAccessories);
