import css from "styled-jsx/css";

export default css`
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 50px;
  }
  .wrapper {
    max-width: 80%;
    position: relative;
  }
  .slider-btn {
    height: 45px;
    width: 46px;
    cursor: pointer;
    text-align: center;
    line-height: 46px;
    background: #343434;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    z-index: 9999;
    transform: translateY(-50%);
  }
  .left-arrow {
    left: 23px;
    display: none;
  }
  .right-arrow {
    right: 23px;
  }
  .wrapper .ig-slider {
    font-size: 0;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
  }
  .ig-slider.dragging {
    scroll-behavior: smooth;
  }
  .ig-slider img {
    height: 350px;
    object-fit: cover;
    margin-left: 14px;
    width: 350px;
  }
  .ig-slider img:first-child {
    margin-left: 0px;
  }
  @media screen and (max-width: 900px) {
    .ig-slider img {
      width: calc(100% / 2);
    }
  }
  @media screen and (max-width: 550px) {
    .wrapper {
      padding: 0
    }
    .ig-slider img {
      width: calc(100% / 2);
      height: 150px;
    }
    .right-arrow {
      width: 27px;
      height: 27px;
      right: -15px;
    }
    .left-arrow {
      width:  27px;
      height: 27px;
      left: -15px;
    }
  }
`;