/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';
import Link from 'next/link';
import styles from './styles';
import { fixDecimalPlaces } from 'helpers/smallFixes';

const LineItemProduct = ({
  item,
  removeLineItemInCart,
  updateLineItemInCart,
  delayed,
}) => {
  const [showCustomBuild, setShowCustomBuild] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const { id, quantity, variant, title, customAttributes } = item.node;
  const productType = variant?.product.productType;
  const productTags = variant?.product.tags;

  const customDesignUrlData = customAttributes?.filter((item) =>
  item.key.startsWith('Custom Design'),
  )

  const customDesignUrl = customDesignUrlData.length > 0 && customDesignUrlData[0].value;

  let customAttributesData;
  let customComponentsQuantity;

  if (productTags.includes('ready-to-ship')) {
    customAttributesData = customAttributes?.filter((item) =>
      item.key.startsWith('_lineitem'),
    );
    customAttributesData.shift()
    customComponentsQuantity = customAttributesData?.length;
  } else {
    customAttributesData = customAttributes?.filter((item) =>
    item.key.startsWith('_lineitem'),
    );
    customComponentsQuantity = customAttributesData?.length;
  }

  const decrementQuantity = (lineItemId) => {
    const updatedQuantity = quantity - 1;
    updateLineItemInCart(lineItemId, updatedQuantity);
  };

  const incrementQuantity = (lineItemId) => {
    const updatedQuantity = quantity + 1;
    updateLineItemInCart(lineItemId, updatedQuantity);
  };
  const decrementItem = () => {
    decrementQuantity(id);
  };
  const increaseItem = () => {
    incrementQuantity(id);
  };
  const removeItem = () => {
    console.log(id);
    removeLineItemInCart(id);
  };

  const handleOpenCustomBuild = () => {
    setShowCustomBuild(true);
  };
  const handleCloseCustomBuild = () => {
    setShowCustomBuild(false);
  };
  const handleToggleCustomBuild = () => {
    setShowCustomBuild(!showCustomBuild);
  };

  const customProductLink = item?.node?.customAttributes?.find(
    (att) => att.key === 'link',
  )?.value;

  const priceNumber = parseInt(variant?.price);
  const compareAtPriceNumber = parseInt(variant?.compareAtPrice);

  return (
    <div key={id}>
      <li
        className="Line-item"
        style={customDesignUrl ? { paddingBottom: 0, borderBottom: 0 } : {}}
      >
        <div className="Line-item__img">
          {variant.image ? (
            <Image draggable={false} 
              src={variant.image.src}
              alt="product image"
              width={110}
              height={110}
            />
          ) : customDesignUrl ? (
            <img draggable="false"
              src={customDesignUrl}
              alt="product image"
              style={{
                width: '85.68px',
                height: '85.68px',
                objectFit: 'cover',
              }}
            />
          ) : null}
          {delayed && (
            <div className="delay_icon">
              <Image draggable={false} 
                src="https://cdn.shopify.com/s/files/1/0522/3320/7988/files/icon-exclamation-blue.svg?v=1713944876"
                alt="Error"
                width={16}
                height={16}
              />
            </div>
          )}
        </div>
        <div className="Line-item__content">
          <div className="Line-item__content-row">
            <div className="Line-item__variant-title" />
            {productType === 'Custom' && !customProductLink && (
              <span>{title}</span>
            )}
            {productType === 'Custom' && customProductLink ? (
              <Link legacyBehavior href={customProductLink}>
                <a className="Line-item__title" href={customProductLink}>
                  {title}
                </a>
              </Link>
            ) : productType === 'Ready To Ship' ? (
              <Link legacyBehavior href={`/products/${variant.product.handle}`}>
                <a
                  className="Line-item__title"
                  href={`/products/${variant.product.handle}`}
                >
                  {title}
                </a>
              </Link>
            ) : (
              <Link legacyBehavior href={`#`} className="inactiveLink">
                <a
                  className="Line-item__title inactiveLink"
                  // href={`/products/${variant.product.handle}`}
                  href={`#`}
                >
                  {title}
                </a>
              </Link>
            )}
          </div>

          <div className="line_item_price">
            {variant?.price > 0 ? <span>${fixDecimalPlaces(variant?.price)}</span> : <span>$0.00</span>}
            {(variant?.compareAtPrice > 0 && compareAtPriceNumber > priceNumber) && (
              <div className="sale_price">
                <span className="saved_money">
                  You saved $
                  {fixDecimalPlaces(variant.compareAtPrice - variant.price)}
                </span>
                &nbsp;
                <span className="compare_at_price">
                  <del>${fixDecimalPlaces(variant.compareAtPrice)}</del>
                </span>
              </div>
            )}
            {variant?.compareAtPrice > 0 && (
              <div className="sale_price sale_price_mobile">
                <span className="compare_at_price">
                  <del>$ {fixDecimalPlaces(variant.compareAtPrice)}</del>
                </span>
                &nbsp;
                <span className="saved_money">
                  You saved $
                  {fixDecimalPlaces(variant.compareAtPrice - variant.price)}
                </span>
              </div>
            )}
          </div>

          {/* {productType === 'Accessories' || productTags.includes('custom') ? ( */}
          <div className="Line-item__quantity-container">
            <button
              type="button"
              className="Line-item__quantity-update"
              onClick={decrementItem}
            >
              -
            </button>
            <span className="Line-item__quantity">{quantity}</span>
            <button
              type="button"
              className="Line-item__quantity-update"
              onClick={increaseItem}
            >
              +
            </button>
          </div>
          {/* ) : null} */}

          {(productTags.includes('custom') ||
            productTags.includes('ready-to-ship')) && (
            <div className="custom-builds-components">
              <div className="components-title">
                <h4 onClick={handleToggleCustomBuild}>
                  Includes {customComponentsQuantity}{' '}
                  {productTags.includes('custom') ? 'custom' : ''} components
                </h4>

                {showCustomBuild === true ? (
                  <button
                    type="button"
                    className="show-information"
                    onClick={handleCloseCustomBuild}
                  >
                    <Image draggable={false} 
                      src="https://cdn.shopify.com/s/files/1/0522/3320/7988/files/icon_minus.png?v=1713891733"
                      alt="show components icon"
                      width={10}
                      height={2}
                    />
                  </button>
                ) : (
                  <button
                    type="button"
                    className="show-information"
                    onClick={handleOpenCustomBuild}
                  >
                    <Image draggable={false} 
                      src="https://cdn.shopify.com/s/files/1/0522/3320/7988/files/icon_plus.png?v=1713861860"
                      alt="close components icon"
                      width={10}
                      height={10}
                    />
                  </button>
                )}
              </div>
              {showCustomBuild === true &&
                customAttributesData.length !== 0 &&
                customAttributesData.map((item) => {
                  const componentTitle = JSON.parse(item?.value)?.title;
                  return (
                    <div className="components-list">
                      <p className="title">{componentTitle}</p>
                    </div>
                  );
                })}
            </div>
          )}
        </div>

        <div 
          onClick={removeItem} 
          className="Line-item__remove"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
            <Image
              src={isHovered ? 
                "https://cdn.shopify.com/s/files/1/0522/3320/7988/files/icon_trash_hover.png?v=1713950395" 
                : "https://cdn.shopify.com/s/files/1/0522/3320/7988/files/icon_trash.png?v=1713950348"}
              alt="cart icon"
              className="cart_icon"
              width={11.75}
              height={15}
            />
        </div>
      </li>
      {customDesignUrl && (
        <p className="Custom-url">
          link:{' '}
          <a
            target="_blank"
            href={customDesignUrl}
            style={{ color: '#ed000d' }}
          >
            {customDesignUrl}
          </a>
        </p>
      )}
      <style jsx>{styles}</style>
    </div>
  );
};

LineItemProduct.propTypes = {
  item: PropTypes.shape({
    __typename: PropTypes.string,
    node: {
      id: PropTypes.string,
      quantity: PropTypes.number,
      variant: PropTypes.object,
      title: PropTypes.string,
      customAttributes: PropTypes.array,
    },
  }).isRequired,
  removeLineItemInCart: PropTypes.func.isRequired,
  updateLineItemInCart: PropTypes.func.isRequired,
  customComponents: PropTypes.array,
  delayed: PropTypes.bool,
};

LineItemProduct.defaultProps = {
  customComponents: [],
  delayed: false,
};

export default LineItemProduct;