import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';

let pageYOffset = 0;

const Modal = ({
  isOpen = false,
  ariaHideApp = true,
  contentLabel,
  onAfterOpen,
  onRequestClose,
  modalClass,
  overlayClassName,
  children,
  customStyles,
  mountNode = 'body',
}) => {
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      ReactModal.setAppElement(mountNode);
    }
  }, [mountNode]);

  const afterOpen = () => {
    pageYOffset = window.pageYOffset;
    if (onAfterOpen) {
      onAfterOpen();
    }
  };

  const afterClose = () => {
    setIsClosing(false);
    document.body.classList.remove('react-modal-html-body--open');
  };

  const requestClose = () => {
    if (!isClosing) {
      setIsClosing(true);
      if (onRequestClose) {
        onRequestClose();
      }
    }
  };

  return (
    <>
      <ReactModal
        isOpen={isOpen}
        contentLabel={contentLabel}
        onRequestClose={requestClose}
        onAfterOpen={afterOpen}
        onAfterClose={afterClose}
        className={modalClass}
        overlayClassName={`${overlayClassName} ${
          isClosing
            ? 'ReactModal__Overlay--before-close'
            : 'ReactModal__Overlay--after-open'
        }`}
        ariaHideApp={ariaHideApp}
        shouldFocusAfterRender={false}
        style={customStyles}
        bodyOpenClassName="react-modal-html-body--open"
        closeTimeoutMS={600}
      >
        <>{children}</>
      </ReactModal>

      <style jsx global>
        {`
          .ReactModal__Overlay {
            transition: all 0.6s ease-in-out;
          }

          .ReactModal__Overlay--after-open {
            opacity: 1;
            transform: translateX(0);
          }

          .ReactModal__Overlay--before-close {
            opacity: 0;
            transform: translateX(100%);
          }

          .react-modal-html-body--open {
            ${isOpen && !isClosing ? "overflow: hidden;" : ""}
          }

          .mobile_menu_modal {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #000000;
            width: 100%;
            height: 100%;
            padding-bottom: 20px;
          }

          .mobile_menu_modal__header {
            background-color: black;
            height: 100%;
            overflow-x: hidden;
          }

          .mobile_menu_modal__overlay {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: transparent;
            z-index: 3;
          }
        `}
      </style>
    </>
  );
};

export default Modal;
