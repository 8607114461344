import { useEffect, useState } from 'react';
import breakPoints from 'helpers/Styles';
import Slider from 'react-slick';
import CarouselProgressPagination from './CarouselProgressPagination';
import Link from 'next/link';
import Image from 'next/image';

const ProductsCarousel = ({ block }) => {
	const [typeIndex, setTypeIndex] = useState(0);
	const [filteredProducts, setFilteredProducts] = useState(null);
	const [currentDot, setCurrentDot] = useState(1);
	const [pageCount, setPageCount] = useState(1);
	const [totalSlidesShowing, setTotalSlidesShowing] = useState(1);
	const slidesShowNumber = 5;
	const title = block?.primary?.slice_label;
	const items = block?.items;
	const distinct = (value, index, self) => {
		return self.indexOf(value) === index;
	};
	const populateCarousel = (productItems) => {
		let postArray = [];
		if (productItems?.length <= slidesShowNumber) {
			for (let i = 0; i <= slidesShowNumber; i++) {
				postArray.push(...productItems);
			}
			postArray = postArray.slice(0, slidesShowNumber * 2);
		} else {
			postArray = productItems;
		}
		return postArray;
	};
	useEffect(() => {
		const totalDots = Math.ceil(items?.length / slidesShowNumber);
		const slidesShowing = document.querySelectorAll(
			'#system_carousel  .slick-active',
		)?.length;
		setPageCount(slidesShowing === 1 ? items?.length : totalDots);
		setTotalSlidesShowing(slidesShowing);
		console.log({ nino: items });
	}, []);
	const [slideIndex, setSlideIndex] = useState(0);
	const settings = {
		beforeChange: (current, next) => setSlideIndex(next),
		dots: false,
		infinite: false,
		speed: 500,
		slidesToShow: slidesShowNumber,
		slidesToScroll: slidesShowNumber,
		variableWidth: true,
		nextArrow: (
			<CustomCarouselArrowNext
				src="https://cdn.shopify.com/s/files/1/0522/3320/7988/files/arrow-right-next.png?v=1713800470"
				alt="arrow-right"
			/>
		),
		prevArrow: (
			<CustomCarouselArrowPrev
				src="https://cdn.shopify.com/s/files/1/0522/3320/7988/files/arrow-left-prev.png?v=1713800296"
				alt="arrow-left"
			/>
		),
		responsive: [
			{
				breakpoint: 1440,
				settings: {
					slidesToShow: slidesShowNumber,
					slidesToScroll: slidesShowNumber,
					infinite: 3,
					dots: true,
				},
			},
			{
				breakpoint: 1300,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 4,
					infinite: 3,
					dots: true,
				},
			},
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					infinite: 3,
					dots: true,
				},
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
				},
			},
			{
				breakpoint: 640,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					centerMode: true,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					centerMode: true,
					className: 'center',
					arrows: false,
				},
			},
		],
	};
	const getMaxValue = (data) => {
		let max = 0;
		if (data != null) {
			return (max =
				(data.length / 5) * 5 - (5 - ((data.length / 5) * 5 - data.length)));
		} else {
			return max;
		}
	};
	return (
		<>
			<div className="container">
				<div className="title">{title}</div>
				<div id="system_carousel" className="carousel">
					<Slider {...settings}>
						{items?.map((item, idx) => {
							const result = {
								title: item.card_title,
								description: item.card_description,
								imageURL: item.thumbnail.url,
								slug: item.slug,
								buttonLink: item.button_link,
								buttonText: item.button_text,
							};
							return <CarouselItem item={result} key={idx} />;
						})}
					</Slider>
					<CarouselProgressPagination
						currentDot={currentDot}
						totalDots={pageCount}
					/>
				</div>
			</div>
			<style jsx>{`
        .container {
          max-width: 1440px;
          margin: 0 auto;
          padding: 40px 0;
        }
        .carousel {
          position: relative;
          margin: 0 auto;
          padding: 56px 48px;
          max-width: 1440px;
          width:100%:
        }
        .options {
          display: flex;
          justify-content: center;
          gap: 24px;
          margin-top: 24px;
        }
        .title {
          font-family: "big_noodle_titling";
          font-size: 39px;
          font-weight: 400;
          line-height: 48px;
          letter-spacing: 0em;
          text-align: center;
          padding: 0 16px;
        }
				@media (max-width: 640px) {
          .carousel {
            padding: 56px 16px;
          }
          .title{
            font-size:26px;
          }
        }
        .range-field-container {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 3%;
        }
      `}</style>
		</>
	);
};
function CarouselItem({ item }) {
	const buttonTextColour = item?.primary?.button_text_colour
		? item.primary.button_text_colour
		: '#ffffff';
	return (
		<>
			<div
				className="carousel_item"
				onClick={() => (window.location.href = item.slug)}
			>
				<div className="img">
					<Image draggable={false} 
						src={item?.imageURL}
						alt={item?.title}
						width="192px"
						height="192px"
						loading="lazy"
					/>
				</div>
				<a href={item.slug}>
					<div className="title text-ellipsis-2">{item.title}</div>
				</a>
				<div className="description text-ellipsis-2">{item.description}</div>
				{item.buttonLink ? (
					<Link legacyBehavior href={item.buttonLink}>
						<a href={item.buttonLink} className="button_container">
							<button type="button" className="two_column_button">
								{item.buttonText ? item.buttonText : 'SHOP'}
							</button>
							<div className="arrow_right">
								<img 
									draggable="false" 
									src="https://cdn.shopify.com/s/files/1/0522/3320/7988/files/icon_arrow.png?v=1713803419" 
									alt="arrow_right" 
								/>
							</div>
						</a>
					</Link>
				) : null}
			</div>
			<style jsx>{`
				.text-ellipsis-2 {
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
				}
				.container {
					max-width: 1440px;
					margin: 0 auto;
					padding: 40px 0;
				}
				.img {
					width: 192px;
					height: 192px;
					// background: url("${item.imageURL}");
					// background-size: cover;
					// background-position: center;
					// background-repeat: no-repeat;
					border-radius: 4px;
				}
				.title {
					font-family: 'Roboto-Medium';
					font-size: 16px;
					font-weight: 600;
					line-height: 24px;
					letter-spacing: 0em;
					text-align: center;
					color: #e4e4e4;
					margin-top: 16px;
					text-transform: uppercase;
				}
				.description {
					font-family: 'Roboto-Medium';
					font-size: 13px;
					font-weight: 600;
					line-height: 20px;
					letter-spacing: 0em;
					text-align: center;
					color: #949494;
					margin-top: 8px;
				}
				.carousel_item {
					cursor: pointer;
					height: 380px;
				}
				.button_container {
					display: flex;
					position: absolute;
					bottom: 0;
				}
				.button_container:hover > .two_column_button {
					background: #800006;
				}
				.button_container:hover > .arrow_right {
					background: #141414;
				}
				.two_column_button {
					height: 48px;
					min-width: 128px;
					color: ${buttonTextColour};
					background: #950810;
					font-family: 'Roboto-Medium';
					font-size: 16px;
					font-weight: bold;
					letter-spacing: 0;
					line-height: 24px;
					border-radius: 3px 0 0 3px;
					border: none;
					outline: 0;
					padding: 12px 24px;
					text-transform: uppercase;
				}
				.arrow_right {
					width: 48px;
					height: 48px;
					background: #242424;
					display: flex;
					justify-content: center;
					align-items: center;
					cursor: pointer;
					border-radius: 0px 3px 03px 0px;
				}
				.arrow_right img {
					width: 25px;
				}
				@media (max-width: ${breakPoints.medium}) {
					.button_container {
						display: flex;
						width: auto;
						margin: 0 auto;
						justify-content: center;
					}
				}
				@media (width<=480px) {
					.carousel_item {
						padding: 0 16px;
					}
				}
			`}</style>
		</>
	);
}

function CustomCarouselArrowNext({ src, alt, onClick }) {
	return (
		<>
			<button className="button" onClick={onClick}>
				<img draggable="false" src={src} alt={alt} />
			</button>
			<style jsx>{`
				.button {
					position: absolute;
					top: 80px;
					right: 0;
					background: none;
					border: none;
					padding: 0;
					margin: 0;
					line-height: 0;
					z-index: 5;
				}
			`}</style>
		</>
	);
}
function CustomCarouselArrowPrev({ src, alt, onClick }) {
	return (
		<>
			<button className="button" onClick={onClick}>
				<img draggable="false" src={src} alt={alt} />
			</button>
			<style jsx>{`
				.button {
					position: absolute;
					top: 80px;
					left: 0;
					background: none;
					border: none;
					padding: 0;
					margin: 0;
					line-height: 0;
					z-index: 5;
				}
			`}</style>
		</>
	);
}

export default ProductsCarousel;
