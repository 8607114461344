const breakPoints = {
  extraSmall: '360px',
  small: '767px',
  medium: '991px',
  large: '1440px',
  /* Chrome dev tool sizes */
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  mobileExtraLarge: '540px',
};

export const colors = {
  brandPrimary: '#131313',
  brandSecondary: '#940911',
  brandPrimaryLight: '#777777',
  brandPrimaryLighter: '#a9a9a9',
  n0: '#ffffff',
  n500: '#000000',
};

export const fonts = {
  dinpro: 'DINPro',
  dinproBold: 'DINPro-Bold',
  dinproLight: 'DINPro-Light',
  bigNoodleTitling: 'big_noodle_titling',
};

export default breakPoints;
