import { useRouter } from 'next/router';
import React, { useEffect } from 'react';

const ReviewsIoCarouselWidget = ({}) => {
  const router = useRouter();

    useEffect(() => {
      if (router.pathname === '/') {
        loadScript();
      }
    }, [router.pathname]);
  
    const loadScript = () => {
      const script = document.createElement('script');
      script.src =
        'https://widget.reviews.io/carousel-inline-iframeless/dist.js?_t=2024032209';
      script.async = true;
      document.body.appendChild(script);
  
      const carouselStylesheet = document.createElement('link');
      carouselStylesheet.rel = 'stylesheet';
      carouselStylesheet.href =
        'https://assets.reviews.io/css/widgets/carousel-widget.css?_t=2024032209';
      document.head.appendChild(carouselStylesheet);
  
      const iconStylesheet = document.createElement('link');
      iconStylesheet.rel = 'stylesheet';
      iconStylesheet.href =
        'https://assets.reviews.io/iconfont/reviewsio-icons/style.css?_t=2024032209';
      document.head.appendChild(iconStylesheet);
  
      script.onload = () => {
        initWidget();
      };
    };

    const initWidget = () => {
      new carouselInlineWidget('reviewsio-carousel-widget', {
        store: 'aftershockpc.com.au',
        sku: '',
        lang: 'en',
        carousel_type: 'topHeader',
        styles_carousel:
          'CarouselWidget--topHeader--withcards CarouselWidget--scrollButtons-top',
        options: {
          general: {
            review_type: 'product',
            min_reviews: '1',
            max_reviews: '40',
            address_format: 'CITY, COUNTRY',
            enable_auto_scroll: 10000,
          },
          header: {
            enable_overall_stars: true,
            rating_decimal_places: 2,
          },
          reviews: {
            enable_customer_name: true,
            enable_customer_location: true,
            enable_verified_badge: true,
            enable_subscriber_badge: true,
            enable_recommends_badge: true,
            enable_photos: true,
            enable_videos: true,
            enable_review_date: true,
            disable_same_customer: true,
            min_review_percent: 4,
            third_party_source: false,
            hide_empty_reviews: true,
            enable_product_name: true,
            tags: '',
            branch: '',
            enable_branch_name: false,
          },
          popups: {
            enable_review_popups: true,
            enable_helpful_buttons: true,
            enable_helpful_count: true,
            enable_share_buttons: true,
          },
        },
        translations: {
          verified_customer: 'Verified Customer',
        },
        styles: {
          '--base-font-family': 'Roboto-Medium',
          '--base-line-height': '20px',
          '--base-font-size': '15px',
          '--base-maxwidth': '100%',
          '--reviewsio-logo-style': 'var(--logo-inverted)',
          '--common-star-color': '#e4e4e4',
          '--common-star-disabled-color': 'rgba(1,1,1,0.25)',
          '--medium-star-size': '22px',
          '--small-star-size': '15px',
          '--x-small-star-size': '16px',
          '--x-small-star-display': 'inline-flex',
          '--header-heading-font-size': '25px',
          '--header-heading-font-family': 'Roboto-Medium',
          '--header-subheading-font-size': '22px',
          '--heading-text-font-weight': '600',
          '--heading-text-font-family': 'Roboto-Medium',
          '--heading-text-line-height': '1.4',
          '--heading-text-color': '#e4e4e4',
          '--heading-text-letter-spacing': '0',
          '--heading-text-transform': 'none',
          '--body-text-color': '#949494',
          '--body-text-font-weight': '400',
          '--body-text-font-family': 'inherit',
          '--body-text-line-height': '20px',
          '--body-text-letter-spacing': '0',
          '--body-text-transform': 'none',
          '--item-border-width': '1px',
          '--item-border-color': '#242424',
          '--item-background-start-color': 'black',
          '--item-background-end-color': 'black',
          '--scroll-button-icon-color': '#FFFFFF',
          '--scroll-button-icon-size': '24px',
          '--scroll-button-bg-color': '#1b1b1b',
          '--scroll-button-border-width': '0px',
          '--scroll-button-border-color': 'rgba(0,0,0,0.1)',
          '--scroll-button-border-radius': '60px',
          '--scroll-button-shadow-size': '0px',
          '--scroll-button-shadow-color': 'rgba(0,0,0,0.1)',
          '--scroll-button-horizontal-position': '0px',
          '--scroll-button-vertical-position': '-20px',
          '--badge-icon-color': '#0E1311',
          '--badge-icon-font-size': '15px',
          '--badge-text-color': '#0E1311',
          '--badge-text-font-size': 'inherit',
          '--badge-text-letter-spacing': 'inherit',
          '--badge-text-transform': 'inherit',
          '--author-font-size': 'inherit',
          '--author-font-weight': 'inherit',
          '--author-text-transform': 'inherit',
          '--photo-video-thumbnail-size': '60px',
          '--photo-video-thumbnail-border-radius': '0px',
          '--popup-backdrop-color': 'rgba(0,0,0,0.75)',
          '--popup-color': '#242424',
          '--popup-star-color': 'inherit',
          '--popup-disabled-star-color': 'inherit',
          '--popup-heading-text-color': 'inherit',
          '--popup-body-text-color': 'inherit',
          '--popup-badge-icon-color': 'inherit',
          '--popup-badge-icon-font-size': '14px',
          '--popup-badge-text-color': 'inherit',
          '--popup-badge-text-font-size': '14px',
          '--popup-border-width': '0px',
          '--popup-border-color': 'rgba(0,0,0,0.1)',
          '--popup-border-radius': '0px',
          '--popup-shadow-size': '0px',
          '--popup-shadow-color': 'rgba(0,0,0,0.1)',
          '--popup-icon-color': 'white',
          '--tooltip-bg-color': '#0E1311',
          '--tooltip-text-color': '#ffffff',
          '--author-font-size': '20px',
          '--badge-icon-color': '#00FF836E',
          '--badge-icon-font-size': '14px',
          '--badge-text-color': '#00FF836E',
          '--badge-text-font-size': '14px',
        },
      });
    };

  return <div id="reviewsio-carousel-widget"></div>;
};

export default ReviewsIoCarouselWidget;
