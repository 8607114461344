import { useEffect, useState } from 'react';
import styles from './styles';

const InstagramHeadline = ({ block }) => {
  if (!block) return '';
  const { primary } = block;
  const headline = primary.headline ? primary.headline[0].text : null;

  const [data, setFeedsData] = useState([]);
  const [isDragStart, setIsDragStart] = useState(false);
  const [prevPageX, setPrevPageX] = useState();
  const [prevScrollLeft, setPrevScrollLeft] = useState();

  useEffect(() => {
    const abortController = new AbortController();
    async function fetchInstagramPost() {
      await fetch(
        `https://graph.instagram.com/me/media?fields=id,media_type,media_url,permalink&limit=20&access_token=${process.env.NEXT_PUBLIC_INSTAGRAM_TOKEN}`,
        {
          method: 'GET',
        },
      )
        .then((response) => response.json())
        .then((res) => setFeedsData(res.data));
    }

    fetchInstagramPost();

    return () => {
      abortController.abort();
    };
  }, []);

  const dragging = (e) => {
    const slider = document.querySelector('.ig-slider');
    if (!isDragStart) return;
    e.preventDefault();
    slider.classList.add('dragging');
    let positionDiff;
    if (e.pageX == undefined) {
      positionDiff = e.changedTouches[0].pageX - prevPageX;
    } else {
      positionDiff = e.pageX - prevPageX;
    }
    slider.scrollLeft = prevScrollLeft - positionDiff;
    showHideIcons();
  };

  const dragStart = (e) => {
    setIsDragStart(true);
    if (e.pageX == undefined) {
      setPrevPageX(e.changedTouches[0].pageX);
    } else {
      setPrevPageX(e.pageX);
    }
    setPrevScrollLeft(document.querySelector('.ig-slider').scrollLeft);
  };

  const dragStop = () => {
    const slider = document.querySelector('.ig-slider');
    setIsDragStart(false);
    slider.classList.remove('dragging');
  };

  const showHideIcons = () => {
    const slider = document.querySelector('.ig-slider');
    const leftArrow = document.querySelector('.left-arrow');
    const rightArrow = document.querySelector('.right-arrow');

    leftArrow.style.display = slider.scrollLeft == 0 ? 'none' : 'block';
    rightArrow.style.display =
      Math.round(slider.scrollLeft) == slider.scrollWidth - slider.clientWidth
        ? 'none'
        : 'block';
  };

  const leftArrow = () => {
    const slider = document.querySelector('.ig-slider');
    slider.scrollBy({ left: -340, behavior: 'smooth' });
    setTimeout(() => showHideIcons(), 60);
  };

  const rightArrow = () => {
    const slider = document.querySelector('.ig-slider');
    slider.scrollBy({ left: 340, behavior: 'smooth' });
    setTimeout(() => showHideIcons(), 60);
  };

  const linkToInstagram = (link) => {
    window.open(link, '_blank').focus();
  };

  return (
    <>
      <div className="container">
        <h1
          style={{
            fontSize: 39,
            color: primary.fontcolor,
            marginTop: 0,
            fontFamily: 'big_noodle_titling',
          }}
        >
          {headline}
        </h1>
        <div className="wrapper">
          <img draggable="false"
            className="slider-btn left-arrow"
            src="https://cdn.shopify.com/s/files/1/0522/3320/7988/files/arrow-left-prev.png?v=1713800296"
            onClick={() => leftArrow()}
            alt="arrow_left"
          />
          <div
            className="ig-slider"
            onMouseMove={(e) => dragging(e)}
            onMouseDown={(e) => dragStart(e)}
            onMouseUp={(e) => dragStop(e)}
            onTouchMove={(e) => dragging(e)}
            onTouchStart={(e) => dragStart(e)}
            onTouchEnd={(e) => dragStop(e)}
          >
            {data?.map((img, index) => {
              if (img.media_type != 'VIDEO') {
                return (
                  <img draggable="false"
                    className="slide-img"
                    src={img.media_url}
                    key={index}
                    onClick={() => linkToInstagram(img.permalink)}
                    alt="slide_image"
                  />
                );
              }
            })}
          </div>
          <img draggable="false"
            className="slider-btn right-arrow"
            src="https://cdn.shopify.com/s/files/1/0522/3320/7988/files/arrow-right-next.png?v=1713800470"
            onClick={() => rightArrow()}
            alt="arrow_right"
          />
        </div>
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

export default InstagramHeadline;
