import css from 'styled-jsx/css';

export default css`
  .primary_menu {
    display: flex;
    margin: 0;
    padding: 0;
    font-size: 13px;
    line-height: 24px;
    align-items: center;
    text-wrap: nowrap;
  }
  .top-level-link {
    height: 66px;
    display: flex;
    color: #e4e4e4;
    letter-spacing: 0;
    list-style: none;
    opacity: 0.75;
    padding: 0 20px 0 20px;
    cursor: pointer;
  }
  .top-level-title {
    text-transform: uppercase;
    font-family: 'Roboto-Medium';
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: 'Roboto-Medium';
    border-bottom: 2px solid transparent;
    transition: border-bottom-color 0.3s ease;
  }
  .top-level a {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  li:first-child {
    margin-left: -38px;
  }
  .top-level:hover {
    opacity: 1;
  }
  .top-level-2 {
    border-bottom: 2px solid #fd0300;
  }
  .top-level-activated {
    border-bottom: 2px solid #fd0300;
  }
  .menu_icon_light,
  .menu_icon {
    margin-left: 8px;
  }
  .menu_icon_light {
    display: none;
  }
  .top-level:hover a > .menu_icon_light {
    display: block;
  }
  .top-level:hover a > .menu_icon {
    display: none;
  }
  .icon_show {
    display: block;
  }
  .icon_hidden {
    display: none;
  }
  @media (max-width: 1280px) {
    .top-level a {
      padding: 0 10px;
    }
    .top-level-link {
      height: 66px;
      display: flex;
      color: #e4e4e4;
      letter-spacing: 0;
      list-style: none;
      opacity: 0.75;
      padding: 0;
      cursor: pointer;
    }
    .top-level-title {
      font-size: 12px;
    }
  }
`;
