import css from 'styled-jsx/css';

export default css`
    @font-face {
        font-family: 'Eurostile';
        src: url('/fonts/EurostileBold.woff') format('woff');
        font-style: normal;
    }
    @font-face {
        font-family: 'Roboto';
        src: url('/fonts/Roboto-Bold.ttf') format('woff');
        font-weight: normal;
        font-style: normal;
    }
    .third_menu {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .third_menu_container {
        width: 100%;
        padding: 0 48px 24px 48px;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        margin: 0 auto;
        max-width: 1440px;
    }
    .third_menu_item {
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 0 0 25%;
        cursor: pointer;
    }
    .third_menu_image {
        width: 250px;
        height: 180px;
    }
    .third_menu_image img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    .third_menu_title {
        font-family: 'Roboto';
        color: #e4e4e4;
        font-size: 16px;
        line-height: 24px;
        cursor: pointer;
        font-weight: bold;
        margin-top: 15px;
        transition: color 0.3s ease;
    }
    .third_menu_title_hovered {
        color: #fd0300;
    }
    .third_menu_subtitle {
        font-family: 'Eurostile';
        font-weight: bold;
        color: #949494;
        font-size: 14px;
        margin-top: 6px;
        text-align: center;
    }
`;
