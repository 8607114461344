import React, { useContext } from 'react';
import { StorefrontContext } from 'providers/storefront';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import Image from 'next/image';
import Router, { useRouter } from 'next/router';
import Link from 'next/link';
import CountrySelector from 'components/CountrySelector';
import Cookies from 'js-cookie';
import CUSTOMER_QUERY from '../../Account/AccountWithGQL/graph';
import SocialItem from 'components/SocialItem';
import { kSocials } from 'utils/constants';
import RegionSwitchButton from 'components/NavigationButtonIcons/RegionSwitchButton';

const MobileBottom = ({ onClose }) => {
	const { isLoggedIn } = useContext(StorefrontContext);
	let customerName = null;
	if (isLoggedIn) {
		const userToken = Cookies.get('customerToken');
		const token = userToken ? JSON.parse(userToken) : null;
		const { data } = useQuery(CUSTOMER_QUERY, {
			variables: { customerAccessToken: token?.accessToken },
		});
		customerName = data?.customer?.firstName;
	}
	const router = useRouter();

  	const handleQuizClick = () => {
    	onClose();
    	router.push('/survey');
  	};
	return (
		<div>
			<div className="wrapper quiz">
				<div className="quiz-body">
					<div className='quiz-topic'>
						RIG FINDER
					</div>
					<div className="quiz-title">
						Unsure of which PC is the right one for you? Take our Aftershock Quiz to<br/>
						determine which PC is most Suited to your needs
					</div>
					<button type="button" onClick={handleQuizClick} className="quizz-button">
						<div className="quiz-button">TAKE THE QUIZ</div>
					</button>
				</div>
			</div>
			<div className="wrapper bottom_bar">
				{/* <div>
          <CountrySelector backgroundColor="#141414" isShowFullName mobileView />
        </div> */}
				<RegionSwitchButton />
				<div>
					<div className="social_links">
						{kSocials.map((socialItem, idx) => (
							<SocialItem
								key={idx}
								href={socialItem.href}
								iconSrc={socialItem.iconSrc}
								alt={socialItem.alt}
								aria-label={socialItem.alt}
							/>
						))}
					</div>
					{/* <div className="profile_button">
            <div className="profile_icon">
              <Link legacyBehavior href ="/me">
                <a href="/me">
                  <div className="profilex_icon_group">
                    <Image draggable={false} 
                      src="https://cdn.shopify.com/s/files/1/0522/3320/7988/files/icon-profile.svg?v=1713799500"
                      alt="cart icon"
                      className="cart_icon"
                      layout="fixed"
                      width={24}
                      height={24}
                    />
                    {isLoggedIn && customerName && <div className="profile_name">{`HI, ${customerName}`}</div>}
                  </div>
                </a>
              </Link>
            </div>
          </div> */}
				</div>
			</div>

			<style jsx>
				{`
					.profile_button {
						display: flex;
						align-items: center;
					}
					.profile_name {
						font-size: 13px;
						line-height: 20px;
						color: #e4e4e4;
						margin-left: 12px;
					}
					.profilex_icon_group {
						display: flex;
						opacity: 75%;
						color: #e4e4e4;
					}
					.profilex_icon_group:hover {
						opacity: 100%;
					}
					.quiz {
						display: flex;
						justify-content: center;
						flex-direction: column;
						margin-top: 24px;
						max-width: 343px;
						margin-bottom: 64px;
            			animation: fade-in 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
					}

					@keyframes fade-in {
						0% {
						opacity: 0;
						}
						100% {
						opacity: 1;
						}
					}

					.quiz-body {
						padding: 0 0 30px;
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						border-radius: 15px 15px 15px 15px;
						background-image: url(https://cdn.shopify.com/s/files/1/0522/3320/7988/files/SERVICE_CENTRE_1.png?v=1714484364);
						background-repeat: no-repeat;
						background-size: cover;
						background-position: center;
					}
					.quiz-topic {
						font-size: 15;
						font-style: normal;
						font-weight: 600;
						line-height: 18px;
						text-align: center;
						margin-top: 50px;
						margin-bottom: 10px;
					}
					.quiz-title {
						margin: 0;
						font-family: 'Roboto-Medium';
						font-size: 8px;
						color: #e4e4e4;
						text-align: center;
						margin-bottom: 10px;
					}
					.quizz-button {
						font-family: DINPro, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
						background-color: transparent;
						border: none;
						color: inherit;
						cursor: pointer;
						padding: 0;
						margin: 0;
					}
					.quiz-button {
						margin-top: 8px;
						cursor: pointer;
						font-size: 13px;
						line-height: 24px;
						padding: 4px 16px;
						background: #950810;
						border-radius: 4px;
					}
					.social_links {
						display: flex;
						justify-content: center;
						gap: 12px;
					}
					.bottom_menu {
						height: 110px;
						background: #242424;
						margin-top: 30px;
						border-radius: 3px;
						padding: 20px;
						margin-left: 10px;
						margin-right: 10px;
					}
					.bottom_menu div {
						margin-bottom: 5px;
					}
					.bottom_menu a {
						color: #949494;
						font-family: 'Roboto-Medium';
						font-size: 13px;
						letter-spacing: 0;
						line-height: 20px;
					}
					.bottom_bar {
						display: flex;
						height: 64px;
						align-items: center;
						justify-content: space-between;
						background: #141414;
					}
				`}
			</style>
		</div>
	);
};

MobileBottom.propTypes = {
	onClose: PropTypes.func,
};

MobileBottom.defaultProps = {
	onClose: null,
};

export default MobileBottom;
