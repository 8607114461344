import { useState, useEffect, memo } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import breakPoints from 'helpers/Styles';
import ColumnsCarouselMobile from '../ColumnsCarouselMobile';

const ColumnsCarousel = ({ block }) => {
  const [newArray, setNewArray] = useState([
    {
      image: '',
      column: '',
      name: '',
      content: '',
      other_content: '',
    },
  ]);
  const { items } = block;

  useEffect(() => {
    if (items.length === 1) {
      setNewArray([
        {
          image: items[0].image_one.url,
          column: items[0].column_one[0].text,
          name: items[0].name_one[0].text,
          content: items[0].content_one[0].text,
          other_content: items[0].other_content_one[0].text,
        },
        {
          image: items[0].image_two.url,
          column: items[0].column_two[0].text,
          name: items[0].name_two[0].text,
          content: items[0].content_two[0].text,
          other_content: items[0].other_content_two[0].text,
        },
        {
          image: items[0].image_three.url,
          column: items[0].column_one[0].text,
          name: items[0].name_one[0].text,
          content: items[0].content_one[0].text,
          other_content: items[0].other_content_three[0].text,
        },
      ]);
    } else if (items.length === 2) {
      setNewArray([
        {
          image: items[0].image_one.url,
          column: items[0].column_one[0].text,
          name: items[0].name_one[0].text,
          content: items[0].content_one[0].text,
          other_content: items[0].other_content_one[0].text,
        },
        {
          image: items[0].image_two.url,
          column: items[0].column_two[0].text,
          name: items[0].name_two[0].text,
          content: items[0].content_two[0].text,
          other_content: items[0].other_content_two[0].text,
        },
        {
          image: items[0].image_three.url,
          column: items[0].column_one[0].text,
          name: items[0].name_one[0].text,
          content: items[0].content_one[0].text,
          other_content: items[0].other_content_three[0].text,
        },
        {
          image: items[1].image_one.url,
          column: items[1].column_one[0].text,
          name: items[1].name_one[0].text,
          content: items[1].content_one[0].text,
          other_content: items[1].other_content_one[0].text,
        },
        {
          image: items[1].image_two.url,
          column: items[1].column_two[0].text,
          name: items[1].name_two[0].text,
          content: items[1].content_two[0].text,
          other_content: items[1].other_content_two[0].text,
        },
        {
          image: items[1].image_three.url,
          column: items[1].column_one[0].text,
          name: items[1].name_one[0].text,
          content: items[1].content_one[0].text,
          other_content: items[1].other_content_three[0].text,
        },
      ]);
    } else {
      null;
    }
  }, []);

  return (
    <>
      <div className="columns_carousel_slice wrapper">
        <Carousel
          showStatus={false}
          showThumbs={false}
          useKeyboardArrows
          infiniteLoop
          autoPlay
          interval={5000}
          showIndicators={items.length > 1 || false}
        >
          {items &&
            items.map((item, index) => (
              <div className="columns_carousel" key={index}>
                <div className="columns_carousel_container">
                  <div className="column_block">
                    <div className="icon">“</div>
                    <p className="column_contents">{item.column_one[0].text}</p>
                    <div className="image_block">
                      {item.image_one.url && (
                        <img draggable="false"
                          src={item.image_one.url}
                          alt="column"
                          className="column_image"
                        />
                      )}
                      <div className="column_information">
                        <p className="name">{item.name_one[0].text}</p>
                        <p className="content">{item.content_one[0].text}</p>
                        <p className="other_content">
                          {item.other_content_one[0].text}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="column_block">
                    <div className="icon">
                      <div className="icon">“</div>
                    </div>
                    <p>{item.column_two[0].text}</p>
                    <div className="image_block">
                      <img draggable="false"
                        src={item.image_two.url}
                        alt="column"
                        className="column_image"
                      />
                      <div className="column_information">
                        <p className="name">{item.name_two[0].text}</p>
                        <p className="content">{item.content_two[0].text}</p>
                        <p className="other_content">
                          {item.other_content_two[0].text}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="column_block">
                    <div className="icon">
                      <div className="icon">“</div>
                    </div>
                    <p>{item.column_three[0].text}</p>
                    <div className="image_block">
                      {item.image_three.url && (
                        <img draggable="false"
                          src={item.image_three.url}
                          alt="column"
                          className="column_image"
                        />
                      )}
                      <div className="column_information">
                        <p className="name">{item.name_three[0].text}</p>
                        <p className="content">{item.content_three[0].text}</p>
                        <p className="other_content">
                          {item.other_content_three[0].text}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </Carousel>
      </div>
      <ColumnsCarouselMobile items={newArray} />
      <style jsx>
        {`
          .columns_carousel_slice {
            min-height: 600px;
            padding: 60px 0px;
            background: black;
            z-index: important;
            overflow: hidden;
          }
          .columns_carousel {
            display: flex;
            width: 100%;
            justify-content: center;
          }
          .columns_carousel_container {
            display: flex;
            width: 100%;
            justify-content: center;
            padding-left: 2%;
          }
          .column_contents {
            color: #e4e4e4;
            font-family: 'Roboto-Normal';
            font-size: 16px;
            letter-spacing: 0;
            line-height: 24px;
            text-align: center;
          }
          .column_block {
            color: white;
            min-height: 110%;
            background: #141414;
            width: 30%;
            margin-right: 30px;
            text-algin: center;
            padding: 10px 20px;
            position: relative;
          }
          .column_block .icon {
            height: 100px;
          }
          .image_block {
            position: absolute;
            width: 100%;
            bottom: -90px;
            bottom: -100px;
            margin: 0px auto;
          }
          .column_image {
            height: 80px !important;
            width: 80px !important;
          }
          .column_information p {
            color: #949494;
            margin: 0;
          }
          .columns_carousel_slice .name {
            color: #949494;
            font-family: 'Roboto-Medium';
            font-size: 13px;
            letter-spacing: 0;
            line-height: 20px;
          }
          .columns_carousel_slice .content {
            color: #e4e4e4;
            font-family: 'Roboto-Medium';
            font-size: 13px;
            letter-spacing: 0;
            line-height: 20px;
          }
          .columns_carousel_slice .other_content {
            color: #949494;
            font-family: 'Roboto-Medium';
            font-size: 10px;
            letter-spacing: 0;
            line-height: 16px;
          }
          .icon {
            height: 87px;
            color: #fd0300;
            font-family: 'big_noodle_titling';
            font-size: 80px;
            letter-spacing: 0;
            margin-top: 10px;
          }
          @media (max-width: ${breakPoints.medium}) {
            .columns_carousel_slice {
              display: none;
            }
          }
        `}
      </style>
    </>
  );
};

export default memo(ColumnsCarousel);
