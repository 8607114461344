export const fixVariantPriceObjectModel = (productVariant) => {
    return {
      ...productVariant,
      price: productVariant.price.amount || 0,
      compareAtPrice: productVariant.compareAtPrice?.amount || 0,
    };
  };
  
  export const fixProductsPriceObjectModel = (products) => {
    return products.map((item) => {
      const newVariants = item.variants?.edges.map((variant) => ({
        node: fixVariantPriceObjectModel(variant.node),
      }));
      return { ...item, variants: { edges: newVariants } };
    });
  };
  
  export const fixLineItemsPriceObjectModel = (lineItems) => {
    return lineItems.map((item) => ({
      node: {
        ...item.node,
        variant: fixVariantPriceObjectModel(item.node.variant),
      },
    }));
  };
  
  export const fixProductDataMetafields = (productData) => {
    const productMetafields = {
      edges: productData.product.metafields
        .filter((item) => item !== null)
        .map((item) => ({
          node: item,
          __typename: 'MetafieldEdge',
        })),
    };
    const newVariants = productData.product.variants.edges.map((item) => ({
      node: fixVariantPriceObjectModel(item.node),
    }));
    const newProductData = {
      product: {
        ...productData.product,
        metafields: productMetafields,
        variants: { edges: newVariants },
      },
    };
    return newProductData;
  };
  
