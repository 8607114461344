import { memo } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import breakPoints from 'helpers/Styles';
import Image from 'next/image';
import Link from 'next/link';

const InstagramBlockMobile = ({ items, title, name }) => (
  <>
    <div className="instagram_mobile">
      <div className="title_container">
        <p className="title">{title}</p>
        <h1 className="name">{name}</h1>
      </div>
      <Carousel
        showStatus={false}
        showThumbs={false}
        useKeyboardArrows
        infiniteLoop
        autoPlay
        interval={5000}
        showIndicators={false}
        centerMode
        centerSlidePercentage={75}
      >
        {items &&
          items.map((item, index) => (
            <div className="image_container" key={index}>
              <Link legacyBehavior href={item.instagram_link.url}>
                <a href={item.instagram_link.url} className="instagram_link">
                  <Image draggable={false} 
                    src={item.image.url}
                    alt="instagram icon"
                    width={250}
                    height={250}
                  />
                </a>
              </Link>
            </div>
          ))}
      </Carousel>
    </div>
    <style jsx>
      {`
        .title {
          color: #949494;
          font-family: 'Roboto-Medium';
          font-size: 16px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 24px;
          text-align: center;
        }
        .name {
          color: #e4e4e4;
          font-family: 'big_noodle_titling';
          font-size: 39px;
          letter-spacing: 0;
          line-height: 48px;
          text-align: center;
        }
        .instagram_mobile {
          width: 100%;
          overflow: hidden;
          padding-bottom: 30px;
        }
        .instagram_link {
          display: block;
          width: 100%;
        }
      `}
    </style>
  </>
);

export default memo(InstagramBlockMobile);
