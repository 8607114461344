/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';
import Modal from 'components/Modal';
import { memo, useState, useEffect } from 'react';
import PrimaryMobile from './PrimaryMobile';
import MobileBottom from '../../MobileBottom';
import { useRouter } from 'next/router';
import SocialItem from 'components/SocialItem';
import RegionSwitchButton from 'components/NavigationButtonIcons/RegionSwitchButton';
import { kSocials } from 'utils/constants';

const NavigationMobile = ({ isOpen, header, closeMenu }) => {
  const [menuItems, setMenuItems] = useState([]);
  const [kspItems, setKspsItems] = useState([]);
  const [quizItems, setQuizItems] = useState();
  const getMegaMenuColumns = (megaMenuItems) => {
    if (!megaMenuItems) return [];
    const columns = [];
    let currentSecondLevelIndex = 0;
    megaMenuItems.forEach((childMenuItem, index) => {
      const sliceType = childMenuItem.slice_type;
      if (sliceType === '1st_level' && index !== 0) currentSecondLevelIndex++;
      if (!columns[currentSecondLevelIndex]) {
        columns[currentSecondLevelIndex] = [];
      }
      columns[currentSecondLevelIndex].push(childMenuItem);
    });
    return columns;
  };

  useEffect(() => {
    if (header && header.body) {
      setMenuItems(getMegaMenuColumns(header.body));
    }
  }, [header]);

  useEffect(() => {
    if (header && header.body) {
      const ksps = header.body.filter((n) => n.slice_type === 'ksps');
      const quiz = header.body.filter((n) => n.slice_type === 'quiz');
      setKspsItems(ksps);
      setQuizItems(quiz);
    }
  }, [header]);

  const componentItems = header?.body?.filter(
    (n) => n.slice_type === 'components',
  );
  return (
    <Modal
      isOpen={isOpen}
      modalClass="mobile_menu_modal"
      overlayClassName="mobile_menu_modal__overlay"
      onRequestClose={closeMenu}
    >
      <div className="mobile_menu_modal__header">
        <PrimaryMobile
          ksps={kspItems}
          data={menuItems}
          closeMenu={closeMenu}
          componentItems={componentItems}
        />
        <div>
          <div className="wrapper bottom_bar">
            {/* <div>
              <CountrySelector backgroundColor="#141414" isShowFullName mobileView />
            </div> */}
            <RegionSwitchButton />
            <div>
              <div className="social_links">
                {kSocials.map((socialItem, idx) => (
                  <SocialItem
                    key={idx}
                    href={socialItem.href}
                    iconSrc={socialItem.iconSrc}
                    alt={socialItem.alt}
                    aria-label={socialItem.alt}
                  />
                ))}
              </div>
              {/* <div className="profile_button">
                <div className="profile_icon">
                  <Link legacyBehavior href ="/me">
                    <a href="/me">
                      <div className="profilex_icon_group">
                        <Image draggable={false} 
                          src="/icons/icon-profile.svg"
                          alt="cart icon"
                          className="cart_icon"
                          layout="fixed"
                          width={24}
                          height={24}
                        />
                        {isLoggedIn && customerName && <div className="profile_name">{`HI, ${customerName}`}</div>}
                      </div>
                    </a>
                  </Link>
                </div>
              </div> */}
            </div>
          </div>

          <style jsx>
            {`
              .profile_button {
                display: flex;
                align-items: center;
              }
              .profile_name {
                font-size: 13px;
                line-height: 20px;
                color: #e4e4e4;
                margin-left: 12px;
              }
              .profilex_icon_group {
                display: flex;
                opacity: 75%;
                color: #e4e4e4;
              }
              .profilex_icon_group:hover {
                opacity: 100%;
              }

              @keyframes fade-in {
                0% {
                  opacity: 0;
                }
                100% {
                  opacity: 1;
                }
              }
              .social_links {
                display: flex;
                justify-content: center;
                gap: 12px;
              }
              .bottom_menu {
                height: 110px;
                background: #242424;
                margin-top: 30px;
                border-radius: 3px;
                padding: 20px;
                margin-left: 10px;
                margin-right: 10px;
              }
              .bottom_menu div {
                margin-bottom: 5px;
              }
              .bottom_menu a {
                color: #949494;
                font-family: 'Roboto-Medium';
                font-size: 13px;
                letter-spacing: 0;
                line-height: 20px;
              }
              .bottom_bar {
                display: flex;
                height: 64px;
                align-items: center;
                justify-content: space-between;
                background: #141414;
              }
            `}
          </style>
        </div>
      </div>
    </Modal>
  );
};

NavigationMobile.propTypes = {
  isOpen: PropTypes.bool,
  header: PropTypes.any,
  closeMenu: PropTypes.func.isRequired,
};

NavigationMobile.defaultProps = {
  isOpen: false,
  header: null,
};

export default memo(NavigationMobile);
