import PropTypes from 'prop-types';
import Link from 'next/link';
import breakPoints, { colors } from 'helpers/Styles';

const RangeGrid = ({ block }) => {
  if (!block) return '';
  const { title } = block.primary;
  const { items } = block;

  if (!items || !items.length) return '';

  return (
    <>
      <div className="range-grid">
        <h1 className="range-grid-title">{title}</h1>
        <div className="range-grid-container">
          {items.map((item) => (
            <div className="range-grid-card" key={item.title}>
              <Link legacyBehavior href={item.link || '#'}>
                <a href={item.link || '#'}>
                  <div className="range-grid-card-title">{item.title}</div>
                  <div className="range-grid-card-image">
                    {item.image.url && (
                      <img draggable="false"
                        src={item.image.url}
                        alt={(item.image && item.image.alt) || 'Aftershock PC'}
                      />
                    )}
                  </div>
                </a>
              </Link>
            </div>
          ))}
        </div>
      </div>
      <style jsx>
        {`
          .range-grid {
            padding: 20px 0;
            margin-bottom: 10px;
            background-color: ${colors.n500};
          }
          .range-grid-title {
            font-weight: 500;
            text-transform: uppercase;
            color: ${colors.n0};
            text-align: center;
            padding-bottom: 15px;
          }
          .range-grid-container {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            flex-wrap: wrap;
          }
          .range-grid-card {
            width: 300px;
            border: 1px solid ${colors.brandPrimaryLighter};
            text-align: center;
          }
          .range-grid-card:hover {
            opacity: 0.8;
          }
          .range-grid-card-title {
            font-size: 1.3rem;
            color: ${colors.n0};
            border-bottom: 1px solid ${colors.brandPrimaryLighter};
            font-weight: 400;
            text-transform: uppercase;
            padding: 10px;
            background: ${colors.brandPrimary};
          }
          .range-grid-card-image img {
            max-width: 280px;
          }
          @media (max-width: ${breakPoints.small}) {
            .range-grid-container {
              flex-direction: column;
            }
            .range-grid-card {
              max-width: 260px;
              margin-bottom: 20px;
            }
            .range-grid-card-title {
              font-size: 1.1rem;
            }
            .range-grid-card-image img {
              max-width: 240px;
            }
          }
        `}
      </style>
    </>
  );
};

RangeGrid.propTypes = {
  block: PropTypes.shape({
    primary: PropTypes.shape({
      title: PropTypes.string,
    }),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.shape({
          url: PropTypes.string,
          alt: PropTypes.string,
        }),
        title: PropTypes.string,
        link: PropTypes.string,
        link_type: PropTypes.string,
      }),
    ),
  }),
};

RangeGrid.defaultProps = {
  block: null,
};

export default RangeGrid;
