import React from 'react';
import SidePanelMainButton from './SidePanelMainButton';
import SidePanelSubButton from './SidePanelSubButton';
import breakPoints from 'helpers/Styles';

function SidePanelYourProfile({ onHandleLogout, gotoOrders, gotoProfile, openChat }) {
  return (
    <>
      <div className='your-profile'>YOUR PROFILE</div>
      <div>
        <SidePanelMainButton
          text={'Profile Overview'}
          iconSrc={'https://cdn.shopify.com/s/files/1/0522/3320/7988/files/user.svg?v=1713945863'}
          onClick={gotoProfile}
        />
        <SidePanelMainButton
          text={'Orders'}
          iconSrc={'https://cdn.shopify.com/s/files/1/0522/3320/7988/files/bag.svg?v=1713945909'}
          onClick={gotoOrders}
        />
        <SidePanelMainButton
          text={'Logout'}
          iconSrc={'https://cdn.shopify.com/s/files/1/0522/3320/7988/files/logout.svg?v=1713945910'}
          onClick={onHandleLogout}
        />
      </div>
      <div className="help-support-container">
        <h3 className="help-support">HELP & SUPPORT</h3>
        <SidePanelSubButton
          text={'Live chat'}
          isChatPingingIcon
          iconSrc={'https://cdn.shopify.com/s/files/1/0522/3320/7988/files/message.png?v=1713963733'}
          onClick={openChat}
        />
        <SidePanelSubButton
          text={'support@aftershockpc.com.au'}
          iconSrc={'https://cdn.shopify.com/s/files/1/0522/3320/7988/files/mail.png?v=1713877055'}
          onClick={() =>
            (window.location.href = 'mailto:support@aftershockpc.com.au')
          }
        />
        <SidePanelSubButton
          text={'+61 390 433 893'}
          iconSrc={'https://cdn.shopify.com/s/files/1/0522/3320/7988/files/phone.png?v=1713877023'}
          chevron={false}
          onClick={() => (window.location.href = 'tel:+61 390 433 893')}
        />
      </div>
      <style jsx>{`
        .help-support-container {
          font-family: 'Roboto-Medium';
        }
        .help-support {
          font-size: 14px;
        }
        .help-support-list {
          display: flex;
          flex-direction: initial;
          font-size: 14px;
          align-items: center;
          height: 35px;
        }
        .icon {
          width: 15px;
          height: 14px;
        }
        .icon-text {
          margin-left: 3%;
          color: #8a8a8a;
        }
        .your-profile {
          text-align: center;
          padding-bottom: 15px;
          font-size: 13px;
          background: #141414;
          border-bottom: 1px solid rgba(228,228,228,.2);
          display: none;
        }
        @media (max-width: ${breakPoints.small}) {
          .your-profile {
            display: block;
          
          }
        }
      `}</style>
    </>
  );
}

export default SidePanelYourProfile;
