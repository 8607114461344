import React from 'react';
import Script from 'next/script';

const loadChat = () => {
	window.FrontChat('init', {
		chatId: process.env.NEXT_PUBLIC_CHAT_ID,
		useDefaultLauncher: false,
	});
};
export default process.env.NEXT_PUBLIC_CHAT_ID ? (
	<Script
		src="https://chat-assets.frontapp.com/v1/chat.bundle.js"
		strategy="lazyOnload"
		onLoad={loadChat}
	/>
) : (
	''
);
