import React from 'react';
import SidePanelSubButton from './SidePanelSubButton';
import Image from 'next/image';
import { useRouter } from 'next/router';

function SidePanelHelpAndSupport({ openChat, onCloseSidePanel }) {
  const router = useRouter();

  return (
    <>
      <div 
        className='close_button'
        onClick={() => onCloseSidePanel()}
      >
        <Image
          src={'https://cdn.shopify.com/s/files/1/0522/3320/7988/files/icon-close.png?v=1714038756'}
          width={20}
          height={20} 
        />
      </div>
      <Image draggable={false} 
        src={'https://cdn.shopify.com/s/files/1/0522/3320/7988/files/help-and-support.png?v=1713968346'}
        alt={'help-support-guy'}
        width={368}
        height={200}
        objectFit="cover"
        layout="responsive"
      />
      <div className="help-support-container">
        <h3 className="help-support">Help & Support</h3>
        <SidePanelSubButton
          iconSrc={'https://cdn.shopify.com/s/files/1/0522/3320/7988/files/Vector_2.png?v=1715066752'}
          text={'Live chat'}
          onClick={openChat}
        />
        <SidePanelSubButton
          iconSrc={'https://cdn.shopify.com/s/files/1/0522/3320/7988/files/Vector_3.png?v=1715066809'}
          text={'Knowledge Hub'}
          onClick={() => {
            onCloseSidePanel();
            router.push('/knowledge-hub');
          }}
          opacity={0.6}
        />
        <SidePanelSubButton
          iconSrc={'https://cdn.shopify.com/s/files/1/0522/3320/7988/files/Icons_Chassis_White_1_1.png?v=1715066848'}
          text={'Flagship Showroom'}
          onClick={() => {
            onCloseSidePanel();
            router.push('/pages/flagship-showroom');
          }}
          opacity={0.6}
        />
        <SidePanelSubButton
          iconSrc={'https://cdn.shopify.com/s/files/1/0522/3320/7988/files/phone_1.png?v=1714376178'}
          text={'Contact Us'}
          onClick={() => {
            onCloseSidePanel();
            router.push('/pages/contactus');
          }}
          opacity={0.6}
        />
        <div className='contact-info'>
          <span
            className='contact-info-text'
            onClick={() => (window.location.href = 'mailto:support@aftershockpc.com.au')}
          >
            support@aftershockpc.com.au
          </span>
          <span
            className='contact-info-text'
            onClick={() => (window.location.href = 'tel:+61 390 433 893')}
          >
            +61 390 433 893
          </span>
        </div>
      </div>
      <style jsx>{`
        .help-support-container {
          display: flex;
          flex-direction: column;
          font-family: 'Roboto-Medium';
          padding: 12px 24px;
        }
        .help-support {
          font-size: 23px;
          margin: 0 0 20px 0;
        }
        .help-support-list {
          display: flex;
          flex-direction: initial;
          font-size: 14px;
          align-items: center;
          height: 35px;
        }
        .close_button {
          position: absolute;
          top: 10px;
          left: 10px;
          z-index: 6;
          cursor: pointer;
        }
        .icon-close {
          margin-top: 10px;
          margin-left: 10px;
          position: absolute;
          cursor: pointer;
          z-index: 10;
        }
        .contact-info {
          display: flex;
          flex-direction: column;
          gap: 15px;
          margin-left: 45px;
          border-left: solid 1px #9e9e9e;
        }
        .contact-info-text {
          margin-left: 10px;
          color: #9e9e9e;
          font-size: 13px;
          cursor: pointer;
        }
        .icon {
          width: 15px;
          height: 14px;
        }
        .icon-text {
          margin-left: 3%;
          color: #8a8a8a;
        }
      `}</style>
    </>
  );
}

export default SidePanelHelpAndSupport;
